import React, { useState, useEffect, useCallback } from "react";
import { DataGrid, GridOverlay, } from "@mui/x-data-grid";
import { DownloadOutlined } from "@mui/icons-material";
import { Box, Button, useTheme, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";

// Convertir une date de yyyy-MM-dd en dd-MM-yyyy
const formatDateToDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// Convertir une date de dd-MM-yyyy en yyyy-MM-dd
const convertToYYYYMMDD = (dateString) => {
  const [day, month, year] = dateString.split("-");
  return `${year}-${month}-${day}`;
};

// Récupérer le premier jour du mois précédent en dd-MM-yyyy
const getFirstDayOfMonth = () => {
  const currentDate = new Date();
  currentDate.setUTCMonth(currentDate.getUTCMonth() - 1); // Met au mois précédent en UTC
  currentDate.setUTCDate(1); // Met au 1er jour du mois précédent en UTC
  const firstDay = currentDate.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(firstDay); // Convertir en dd-MM-yyyy
};

// Récupérer le dernier jour du mois précédent en dd-MM-yyyy
const getLastDayOfMonth = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0)
  );
  const lastDayFormatted = lastDay.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(lastDayFormatted); // Convertir en dd-MM-yyyy
};

const SortieCaisseList = () => {
  const theme = useTheme();
  const [startDate, setStartDate] = useState(getFirstDayOfMonth()); // dd-MM-yyyy
  const [endDate, setEndDate] = useState(getLastDayOfMonth()); // dd-MM-yyyy
  const [sortieCaisse, setSortieCaisse] = useState([]);
  const [totalSortie, setTotalSortie] = useState(null);
  const [isFetchingSortie, setIsFetchingSortie] = useState(false);
  const [isDataReadySortie, setIsDataReadySortie] = useState(false);

  const fetchSortieCaisseData = useCallback(async () => {
    if (!startDate || !endDate) {
      toast.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetchingSortie(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/sortiecaisse",
        {
          params: { startDate: startDate, endDate: endDate },
        }
      );

      console.log("Données reçues de l'API:", response.data); // Debugging ici

      const dataWithIds = response.data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      setSortieCaisse(dataWithIds);

      // Calculer totalSortie
      const total = dataWithIds.reduce((acc, item) => {
        // S'assure que `total` est converti en nombre
        return acc + parseFloat(item.total || 0);
      }, 0);

      setTotalSortie(total);
      setIsDataReadySortie(true);
    } catch (error) {
      console.error("Échec de la récupération des données de sortie de caisse:", error);
      toast.error(
        "Échec de la récupération des données de sortie de caisse. Veuillez réessayer."
      );
      setIsDataReadySortie(false);
    }
    setIsFetchingSortie(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchSortieCaisseData();
  }, [startDate, endDate, fetchSortieCaisseData]);

  const transformData = (data) => {
    if (data.length === 0) return [];

    const aggregatedData = {};

    data.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (key !== "id" && key !== "selectedDate" && key !== "total") {
          aggregatedData[key] = (aggregatedData[key] || 0) + parseFloat(row[key]);
        }
      });
    });

    return Object.keys(aggregatedData).map((key, index) => ({
      id: index + 1,
      categorie: key,
      montant: aggregatedData[key].toFixed(2), // Formatage à deux décimales
    }));
  };

  const transformedData = transformData(sortieCaisse);

  console.log("Données transformées pour le DataGrid:", transformedData); // Debugging ici

  const handlePrint = async () => {
    if (!isDataReadySortie) {
      toast.error(
        "Les données ne sont pas prêtes. Veuillez sélectionner une plage de dates et réessayer."
      );
      return;
    }

    try {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
        <head>
          <title>Rapport Gestion</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 60px; }
            .header { display: flex; justify-content: space-between; align-items: center; }
            .header p { margin: 0; }
            h1 { text-align: center; }
            h2 { text-align: left; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            th, td { border: 1px solid #000; padding: 8px; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
      `);

      printWindow.document.write(`
        <div class="header">
          <div><p>COPIFOR DE DAROU NGARAF</p></div>
          <div><p>Période du ${startDate} au ${endDate}</p></div>
        </div>
      `);

      printWindow.document.write(
        "<h1>Rapport Mensuel de Sortie de Caisse</h1>"
      );
      printWindow.document.write(
        "<h2>Tableau de sortie de caisse du mois</h2>"
      );
      printWindow.document.write("<table border='1'>");
      printWindow.document.write(`
        <tr>
          <th>Catégorie</th>
          <th>Montant</th>
        </tr>
      `);

      if (transformedData.length > 0) {
        transformedData.forEach((item) => {
          printWindow.document.write(`
            <tr>
              <td>${item.categorie}</td>
              <td>${item.montant}</td>
            </tr>
          `);
        });
        printWindow.document.write(`
          <tr>
            <td><strong>Total</strong></td>
            <td>${totalSortie} FCFA</td>
          </tr>
        `);
      } else {
        printWindow.document.write(`
          <tr><td colspan="2">Aucune donnée disponible</td></tr>
        `);
      }

      printWindow.document.write("</table>");
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      toast.success("Rapport de sortie de caisse imprimé avec succès.");
    } catch (error) {
      console.error("Échec de l'impression du rapport de sortie de caisse:", error);
      toast.error(
        "Échec de l'impression du rapport de sortie de caisse. Veuillez réessayer."
      );
    }
  };

  const columnsSortieCaisse = [
    { field: "categorie", headerName: "Catégorie", flex: 1 },
    { field: "montant", headerName: "Montant", flex: 1 },
  ];

    const CustomNoRowsOverlay = () => (
	  <GridOverlay>
		<Typography>Aucune donnée disponible</Typography>
	  </GridOverlay>
	);

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Liste des Sorties de Caisse
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="secondary"
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginTop: "1px",
              marginRight: "5px",
            }}
            onClick={handlePrint}
            disabled={isFetchingSortie || !isDataReadySortie}
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Télécharger le rapport de Sortie de Caisse
          </Button>
          <TextField
            id="start-date"
            label="Période de début"
            type="date"
            value={convertToYYYYMMDD(startDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setStartDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="end-date"
            label="Période de fin"
            type="date"
            value={convertToYYYYMMDD(endDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setEndDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </Box>
      <Box
        mt="10px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={transformedData}
          columns={columnsSortieCaisse}
          getRowId={(row) => row.id}
          loading={isFetchingSortie}
          components={{
            Footer: () => (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                padding="10px"
                bgcolor={theme.palette.background.alt}
              >
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  {isDataReadySortie ? (
                    <>
                      Total: <strong>{totalSortie || 0} FCFA</strong>
                    </>
                  ) : (
                    "Calcul du total en cours..."
                  )}
                </Typography>
              </Box>
            ),
			NoRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </Box>
    </Box>
  );
};

export default SortieCaisseList;

