import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Link,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../state/index";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.global.mode);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        // Redirect based on the user's role
        if (user.role === "Administrateur") {
          navigate("/dashboard");
        } else if (user.role === "Utilisateur") {
          navigate("/facturation");
        }
      }
    }
  }, [navigate]);

  const handleLogin = async () => {
    try {
      const response = await axios.post("https://gestionforage.terangapp.com/api/login", {
        username,
        password,
      });

      if (response.status === 200) {
        const { token, userData } = response.data;

        toast.success("Connexion réussie");
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(userData));

        dispatch(setUser(userData));
        
        // Redirect based on the user's role
        if (userData.role === "Administrateur") {
          navigate("/dashboard");
        } else if (userData.role === "Utilisateur") {
          navigate("/facturation");
        }
      } else {
        console.error("Login failed:", response.data);
        toast.error("Login failed. Please check your credentials.");
      }
    } catch (error) {
      console.error("Login failed:", error);
      if (error.response && error.response.data) {
        toast.error(`Login failed: ${error.response.data.error}`);
      } else {
        toast.error("Échec de la connexion. Veuillez vérifier vos identifiants.");
      }
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        bgcolor: mode === "dark" ? "#141937" : "#fff",
        color: mode === "dark" ? "#d3d4de" : "#21295c",
        padding: 4,
      }}
    >
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleLogin();
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 300,
          padding: 2,
          border: `1px solid ${mode === "dark" ? "#141937" : "#ccc"}`,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          Connexion
        </Typography>
        <TextField
          label="Nom utilisateur"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          variant="outlined"
          margin="normal"
          color={mode === "dark" ? "secondary" : "primary"}
        />
        <TextField
          label="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? "text" : "password"}
          variant="outlined"
          margin="normal"
          color={mode === "dark" ? "secondary" : "primary"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color={mode === "dark" ? "secondary" : "primary"}
          size="large"
          sx={{ marginTop: 2 }}
        >
          Se connecter
        </Button>
        <Typography variant="body2" sx={{ marginTop: 1 }}>
          Vous n'avez pas de compte?&nbsp;
          <Link
            color={mode === "dark" ? "secondary" : "primary"}
            style={{ cursor: "default", textDecoration: "none" }} 
          >
            Contactez l'administrateur
          </Link>
        </Typography>
        &nbsp;
        <Typography variant="body2">
          <Link
            color={mode === "dark" ? "secondary" : "primary"}
            onClick={() => console.log("Forgot password?")}
            style={{ cursor: "pointer" }}
          >
            Forgot Password? Contactez l'administrateur.
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
