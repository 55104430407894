import axios from "axios";

const API_BASE_URL = process.env.API_BASE_URL || "https://gestionforage.terangapp.com/api";

export const fetchTotalConsommation = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/consommations/total`);
    return response.data.totalConsommation;
  } catch (error) {
    console.error("Error fetching total consommation:", error);
    throw error;
  }
};

export const fetchSummedDonnees = async (startDate, endDate) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/donnees/summed`, {
      params: { startDate, endDate },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching summed donnees:", error);
    throw error;
  }
};
