import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Typography,
  IconButton,
  useTheme,
  Box,
  InputBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DownloadOutlined,
  Visibility,
  VisibilityOff,
  Search,
  Edit,
  Delete,
} from "@mui/icons-material";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

const DetteForm = () => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);

  const [formData, setFormData] = useState({
    id: null,
    selectedDate: getCurrentDate(),
    provenanceDette: "Fonctionnement",
    natureDette: "Caisse",
    valeurDette: 0,
    totauxDettes: 0,
  });

  const [detteList, setDetteList] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDettes, setFilteredDettes] = useState([]);
  const [showDettes, setShowDettes] = useState(true);

  useEffect(() => {
    fetchDettes();
  }, []);

  useEffect(() => {
    if (Array.isArray(detteList)) {
      setFilteredDettes(
        detteList.filter((dette) =>
          dette.provenanceDette
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setFilteredDettes([]);
    }
  }, [searchQuery, detteList]);

  const fetchDettes = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://gestionforage.terangapp.com/api/dettes");
      setDetteList(response.data || []); // Ensure response.data is an array or initialize as empty array
    } catch (error) {
      console.error("Error fetching dettes!", error);
      toast.error("Error fetching dettes. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "valeurDette" ? parseFloat(value) : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      !formData.provenanceDette ||
      !formData.natureDette ||
      formData.valeurDette <= 0
    ) {
      toast.error("Please fill in all required fields with valid values.");
      return;
    }

    setLoading(true);
    try {
      let url = "https://gestionforage.terangapp.com/api/dettes";
      let method = "POST";

      if (formData.id) {
        url += `/${formData.id}`;
        method = "PUT";
      }

      const response = await axios({
        url,
        method,
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      });

      toast.success(
        formData.id
          ? "Dette mise à jour avec succès."
          : "Dette ajoutée avec succès."
      );

      setFormData({
        id: null,
        selectedDate: getCurrentDate(),
        provenanceDette: "Fonctionnement",
        natureDette: "Caisse",
        valeurDette: 0,
        totauxDettes: 0,
      });

      // Update local state with new data
      const updatedDettes = response.data;
      setDetteList(updatedDettes);
      setFilteredDettes(updatedDettes);

      fetchDettes(); // Refresh the list of dettes
    } catch (error) {
      console.error("Error submitting the form!", error);
      toast.error(
        error.message || "Error submitting the form. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`https://gestionforage.terangapp.com/api/dettes/${id}`);
      toast.success("Dette supprimée avec succès.");
      fetchDettes(); // Refresh the list of dettes
    } catch (error) {
      console.error("Error deleting the dette!", error);
      toast.error(
        error.message || "Error deleting the dette. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = (dette) => {
    setFormData({
      id: dette.id,
      selectedDate: new Date(dette.selectedDate).toISOString().split("T")[0],
      provenanceDette: dette.provenanceDette,
      natureDette: dette.natureDette,
      valeurDette: dette.valeurDette,
      totauxDettes: dette.totauxDettes,
    });
  };

  const openConfirmationDialog = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const closeConfirmationDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteId(null);
  };

  const confirmDelete = () => {
    handleDelete(deleteId);
    closeConfirmationDialog();
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePrint = async () => {
    try {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
      <html lang="fr">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Rapport des Dettes</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 60px;
          }
          .header {
            text-align: center;
            margin-bottom: 20px; /* Added margin to separate header from content */
          }
          .header p {
            margin: 0;
          }
          h1 {
            margin-bottom: 10px;
          }
          h2 {
            text-align: left;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
          }
          th, td {
            border: 1px solid #000;
            padding: 8px;
          }
          th {
            background-color: #f2f2f2;
          }
          .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            margin-top: 20px;
          }
          .footer p {
            margin: 0;
          }
          .footer div {
            display: flex;
            flex-direction: column;
            text-align: left;
          }
        </style>
      </head>
      <body>
        <div class="header">
          <h1>Rapport des Dettes</h1>
        </div>
        <h2>Tableau des Dettes</h2>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Provenance de la dette</th>
              <th>Nature de la dette</th>
              <th>Valeur de la dette</th>
              <th>Totaux dettes</th>
            </tr>
          </thead>
          <tbody>
            ${filteredDettes
              .map(
                (dette) => `
                <tr>
                  <td>${dette.id}</td>
                  <td>${dette.selectedDate}</td>
                  <td>${dette.provenanceDette}</td>
                  <td>${dette.natureDette}</td>
                  <td>${dette.valeurDette}</td>
                  <td>${dette.totauxDettes}</td>
                </tr>
              `
              )
              .join("")}
          </tbody>
        </table>
        <div class="footer">
          <div>
            <p>ASUFOR DAROU NGARAF</p>
            <p>Commune de Bandègne Ouolof, Louga (Louga)</p>
            <p>Téléphone : (+221) 77 123 45 67</p>
          </div>
          <h2>Fait à Darou Ngaraf, le ${new Date().toLocaleDateString()}</h2>
        </div>
      </body>
      </html>
      `);
      printWindow.document.close();
      printWindow.print();
    } catch (error) {
      console.error("Erreur lors de l'impression des données :", error);
      toast.error(
        "Erreur lors de l'impression des données. Veuillez réessayer."
      );
    }
  };

  const columnsDettes = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "selectedDate", headerName: "Date", width: 150 },
    { field: "provenanceDette", headerName: "Provenance", width: 150 },
    { field: "natureDette", headerName: "Nature", width: 150 },
    { field: "valeurDette", headerName: "Valeur", width: 130 },
    { field: "totauxDettes", headerName: "Total Dettes", width: 130 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <strong>
          <IconButton
            color="secondary"
            size="small"
            onClick={() => handleUpdate(params.row)}
          >
            <Edit />
          </IconButton>
          <IconButton
            color="secondary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => openConfirmationDialog(params.row.id)}
          >
            <Delete />
          </IconButton>
        </strong>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title={formData.id ? "Modifier une dette" : "Ajouter une dette"}
          subtitle={
            formData.id
              ? "Modifiez les informations de la dette"
              : "Remplissez les informations de la dette"
          }
          sx={{ mb: 2 }}
        />
        <Box display="flex" alignItems="center" mb="20px">
          <InputBase
            placeholder="Rechercher…"
            value={searchQuery}
            onChange={handleSearch}
            sx={{ ml: 1, flex: 1 }}
            inputProps={{ "aria-label": "search" }}
          />
          <IconButton type="submit" sx={{ p: 1 }} aria-label="search">
            <Search />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Button
          variant="contained"
          color="secondary"
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            marginTop: "4px",
            marginRight: "5px",
          }}
          onClick={handlePrint}
        >
          <DownloadOutlined sx={{ mr: "10px" }} />
          Télécharger le rapport des dettes
        </Button>
      </Box>
      <Grid container spacing={3} sx={{ mt: 4 }}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  label="Provenance de la Dette"
                  name="provenanceDette"
                  value={formData.provenanceDette}
                  onChange={handleChange}
                  required
                  fullWidth
                >
                  <MenuItem value="Fonctionnement">Fonctionnement</MenuItem>
                  <MenuItem value="Investissement">Investissement</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  label="Nature de la Dette"
                  name="natureDette"
                  value={formData.natureDette}
                  onChange={handleChange}
                  required
                  fullWidth
                >
                  <MenuItem value="Caisse">Caisse</MenuItem>
                  <MenuItem value="Autres">Autres</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Valeur de la Dette"
                  type="number"
                  name="valeurDette"
                  value={formData.valeurDette}
                  onChange={handleChange}
                  required
                  fullWidth
                />

                <TextField
                  label="Total de la Dette"
                  type="number"
                  name="totauxDettes"
                  value={formData.totauxDettes}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Date"
                  type="date"
                  name="selectedDate"
                  value={formData.selectedDate}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color={mode === "dark" ? "secondary" : "primary"}
                  size="large"
                  sx={{
                    width: "100%",
                  }}
                >
                  {formData.id ? "Mettre à jour" : "Ajouter"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Dettes
            <IconButton
              onClick={() => setShowDettes(!showDettes)}
              sx={{ ml: 1 }}
            >
              {showDettes ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Typography>
          {showDettes && (
            <Box
              mt="40px"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: theme.palette.background.alt,
                  color: theme.palette.secondary[100],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: theme.palette.primary.light,
                },
                "& .MuiDataGrid-footerContainer": {
                  backgroundColor: theme.palette.background.alt,
                  color: theme.palette.secondary[100],
                  borderTop: "none",
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${theme.palette.secondary[200]} !important`,
                },
              }}
            >
              <DataGrid
                loading={loading}
                rows={
                  Array.isArray(filteredDettes)
                    ? [...filteredDettes].sort((a, b) => {
                        if (a.selectedDate < b.selectedDate) return 1;
                        if (a.selectedDate > b.selectedDate) return -1;
                        return b.id - a.id;
                      })
                    : []
                }
                columns={columnsDettes}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Dialog open={openDeleteDialog} onClose={closeConfirmationDialog}>
        <DialogTitle>Confirmation de suppression</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer cette dette ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmationDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={confirmDelete} color="secondary" autoFocus>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DetteForm;
