import React, { useState, useEffect } from "react";
import axios from "axios";
import { useGetCompteursQuery } from "state/api";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import { DataGrid } from "@mui/x-data-grid";
import { DownloadOutlined } from "@mui/icons-material";
import { Box, Button, useTheme } from "@mui/material";

const Compteurs = () => {
  const theme = useTheme();
  const { isLoading } = useGetCompteursQuery();
  const [compteurs, setCompteurs] = useState([]);

  useEffect(() => {
    const fetchCompteurs = async () => {
      try {
        const response = await axios.get("https://gestionforage.terangapp.com/api/compteurs"); // Replace this with your API endpoint
        setCompteurs(response.data);
      } catch (error) {
        console.error("Failed to fetch compteurs:", error);
      }
    };

    fetchCompteurs();
  }, []);

  const columns = [
    {
      field: "_id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "product",
      headerName: "Produit",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "usdPrice",
      headerName: "Prix USD",
      flex: 1,
    },
    {
      field: "euroPrice",
      headerName: "Prix EURO",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantité",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => params.value.length,
    },
    {
      field: "price",
      headerName: "Prix",
      flex: 1,
      renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    },
    {
      field: "taxes",
      headerName: "Taxes",
      flex: 1,
      renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    },
    {
      field: "profit",
      headerName: "Gain",
      flex: 1,
      renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    },
    {
      field: "netProfit",
      headerName: "Gain Net",
      flex: 1,
      renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    },
    {
      field: "sellingPrice",
      headerName: "Prix Vente",
      flex: 1,
      renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header
          title="TABLEAU DE BORD"
          subtitle="Bienvenue sur votre tableau de bord"
        />

        <Box>
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Télécharger les rapports
          </Button>
        </Box>
      </FlexBetween>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !compteurs}
          getRowId={(row) => row._id}
          rows={compteurs}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default Compteurs;
