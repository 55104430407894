import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

const CategoriePointEeau = () => {
  const mode = useSelector((state) => state.global.mode);

  const [selectedDate, setSelectedDate] = useState(getCurrentDate());
  const [abonnesDomestiques, setAbonnesDomestiques] = useState(0);
  const [bornesFontaines, setBornesFontaines] = useState(0);
  const [activitesCommerciales, setActivitesCommerciales] = useState(0);
  const [activitesMaraicheres, setActivitesMaraicheres] = useState(0);
  const [abreuvoirs, setAbreuvoirs] = useState(0);
  const [stationsAcharettes, setStationsAcharettes] = useState(0);
  const [autres, setAutres] = useState(0);
  const [fonctionnel, setFonctionnel] = useState(0);
  const [nonFonctionnel, setNonFonctionnel] = useState(0);
  const [total, setTotal] = useState(0);
  const [observations, setObservations] = useState("");

  useEffect(() => {
    setTotal(
      abonnesDomestiques +
        bornesFontaines +
        activitesCommerciales +
        activitesMaraicheres +
        abreuvoirs +
        stationsAcharettes +
        autres
    );
  }, [
    abonnesDomestiques,
    bornesFontaines,
    activitesCommerciales,
    activitesMaraicheres,
    abreuvoirs,
    stationsAcharettes,
    autres,
  ]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const categoryData = {
      selectedDate,
      abonnesDomestiques,
      bornesFontaines,
      activitesCommerciales,
      activitesMaraicheres,
      abreuvoirs,
      stationsAcharettes,
      autres,
      fonctionnel,
      nonFonctionnel,
      total,
      observations,
    };

    try {
      const response = await fetch("https://gestionforage.terangapp.com/api/categories", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(categoryData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Form submitted:", data);

      // Reset form fields
      setSelectedDate(getCurrentDate());
      setAbonnesDomestiques(0);
      setBornesFontaines(0);
      setActivitesCommerciales(0);
      setActivitesMaraicheres(0);
      setAbreuvoirs(0);
      setStationsAcharettes(0);
      setAutres(0);
      setFonctionnel(0);
      setNonFonctionnel(0);
      setTotal(0);
      setObservations("");
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 3 }}>
      <Typography variant="h4" gutterBottom   sx={{ mb: 4 }}>
        Ajouter une catégorie
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Date"
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              required
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Abonnés Domestiques"
              type="number"
              value={abonnesDomestiques}
              onChange={(e) => setAbonnesDomestiques(Number(e.target.value))}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Bornes Fontaines"
              type="number"
              value={bornesFontaines}
              onChange={(e) => setBornesFontaines(Number(e.target.value))}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Activités Commerciales"
              type="number"
              value={activitesCommerciales}
              onChange={(e) => setActivitesCommerciales(Number(e.target.value))}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Activités Maraîchères"
              type="number"
              value={activitesMaraicheres}
              onChange={(e) => setActivitesMaraicheres(Number(e.target.value))}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Abreuvoirs"
              type="number"
              value={abreuvoirs}
              onChange={(e) => setAbreuvoirs(Number(e.target.value))}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Stations Acharettes"
              type="number"
              value={stationsAcharettes}
              onChange={(e) => setStationsAcharettes(Number(e.target.value))}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Autres"
              type="number"
              value={autres}
              onChange={(e) => setAutres(Number(e.target.value))}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Fonctionnel"
              type="number"
              value={fonctionnel}
              onChange={(e) => setFonctionnel(Number(e.target.value))}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Non Fonctionnel"
              type="number"
              value={nonFonctionnel}
              onChange={(e) => setNonFonctionnel(Number(e.target.value))}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Total"
              type="number"
              value={total}
              InputProps={{
                readOnly: true,
              }}
              required
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Observations"
              value={observations}
              onChange={(e) => setObservations(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color={mode === "dark" ? "secondary" : "primary"}
              size="large"
              sx={{
                width: "100%",
              }}
            >
              Ajouter
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default CategoriePointEeau;
