// Consommation/Consommation.jsx
import React from "react";
import AjouterConsommation from "../consommation/AjouterConsommation";
import { Box } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";

const Consommation = () => {
  return (
    <Box m="1rem 2rem">
      <FlexBetween sx={{ mt: -2, mb: 1 }}>
        <Header title="Ajouter Consommation" />
      </FlexBetween>
      <AjouterConsommation />
    </Box>
  );
};

export default Consommation;
