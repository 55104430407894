import React, { useState, useEffect, useCallback } from "react";
import { DataGrid, GridOverlay, } from "@mui/x-data-grid";
import { DownloadOutlined, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  useTheme,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// Fonction pour convertir les dates en format dd-MM-yyyy
const formatDateToDDMMYYYY = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`;
};

// Fonction pour convertir les dates en format yyyy-MM-dd
const convertToYYYYMMDD = (dateString) => {
  const [day, month, year] = dateString.split('-');
  return `${year}-${month}-${day}`;
};

// Récupérer le premier jour du mois précédent en dd-MM-yyyy
const getFirstDayOfMonth = () => {
  const currentDate = new Date();
  currentDate.setUTCMonth(currentDate.getUTCMonth() - 1); // Met au mois précédent en UTC
  currentDate.setUTCDate(1); // Met au 1er jour du mois précédent en UTC
  const firstDay = currentDate.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(firstDay); // Convertir en dd-MM-yyyy
};

// Récupérer le dernier jour du mois précédent en dd-MM-yyyy
const getLastDayOfMonth = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0)
  );
  const lastDayFormatted = lastDay.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(lastDayFormatted); // Convertir en dd-MM-yyyy
};

// Récupérer le premier jour du mois précédent en dd-MM-yyyy
const getFirstDayOfPreviousMonth = () => {
  const currentDate = new Date();
  currentDate.setUTCMonth(currentDate.getUTCMonth() - 2); // Met au mois précédent en UTC
  currentDate.setUTCDate(1); // Met au 1er jour du mois précédent en UTC
  const firstDay = currentDate.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(firstDay); // Convertir en dd-MM-yyyy
};

// Récupérer le dernier jour du mois précédent en dd-MM-yyyy
const getLastDayOfPreviousMonth = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth()-1, 0)
  );
  const lastDayFormatted = lastDay.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(lastDayFormatted); // Convertir en dd-MM-yyyy
};

// Log the current month, previous month, first day of previous month, and last day of previous month
console.log(`First day of previous month: ${getFirstDayOfPreviousMonth()}`);
console.log(`Last day of previous month: ${getLastDayOfPreviousMonth()}`);

const SituationCaisse = () => {
  const theme = useTheme();
  const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  const [endDate, setEndDate] = useState(getLastDayOfMonth());
  const startDateTreso = getFirstDayOfPreviousMonth();
  const endDateTreso = getLastDayOfPreviousMonth();

  const [rows, setRows] = useState([]);
  const [isFetchingSituationCaisse, setIsFetchingSituationCaisse] =
    useState(false);
  const [isDataReadySituationCaisse, setIsDataReadySituationCaisse] =
    useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [editRow, setEditRow] = useState(null);
  const [editValue, setEditValue] = useState("");

  const fetchTotalPaidAmount = useCallback(async () => {
    setIsFetchingSituationCaisse(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/consommations/tresoreriedebutmois",
        {
          params: { startDate, endDate },
        }
      );
      const totalPaidAmount = response.data.rows.reduce(
        (total, row) => total + (parseFloat(row.paidAmount) || 0),
        0
      );
      return totalPaidAmount;
    } catch (error) {
      console.error("Error FetchingSituationCaisse total paid amount:", error);
      toast.error("Erreur lors de la récupération du montant total payé.");
      return 0;
    } finally {
      setIsFetchingSituationCaisse(false);
    }
  }, [startDate, endDate]);

  const fetchTotalTreso = useCallback(async () => {
    setIsFetchingSituationCaisse(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/tresorerieglobale",
        {
          params: {
            startDate: startDateTreso, endDate: endDateTreso
          },
        }
      );

      const { records } = response.data;
      const totalTresoDispo = parseFloat(records.totalTresoDispo) || 0;
      return totalTresoDispo;
    } catch (error) {
      console.error("Error FetchingSituationCaisse total treso:", error);
      toast.error("Erreur lors de la récupération du montant total Treso.");
      return 0;
    } finally {
      setIsFetchingSituationCaisse(false);
    }
  }, [startDate, endDate]);

  const fetchTotalSortieCaisseFm = useCallback(async () => {
    setIsFetchingSituationCaisse(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/sortiecaisse",
        {
          params: { startDate, endDate },
        }
      );

      const totalSortie = response.data.reduce(
        (acc, curr) => acc + (parseFloat(curr.total) || 0),
        0
      );

      return totalSortie;
    } catch (error) {
      console.error(
        "Error FetchingSituationCaisse total sortie caisse:",
        error
      );
      toast.error("Erreur lors de la récupération des données.");
      setIsFetchingSituationCaisse(false);
      return 0;
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const totalPaidAmount = await fetchTotalPaidAmount();
        const totalTreso = await fetchTotalTreso();
        const totalTresoDispo = totalPaidAmount + totalTreso;

        const totalSortie = await fetchTotalSortieCaisseFm();
        const totalSoldeCaisseFm = totalTresoDispo - totalSortie;
        const soldeEffCaisseFm = totalTresoDispo - totalSortie;

        if (typeof totalSortie !== "undefined") {
          setRows([
            {
              id: 1,
              totalCaisseFm: totalTresoDispo.toFixed(2),
              totalSortieCaisseFm: totalSortie.toFixed(2),
              totalSoldeCaisseFm: (totalTresoDispo - totalSortie).toFixed(2),
              soldeEffCaisseFm: (totalTresoDispo - totalSortie).toFixed(2),
              differenceAJustifier: (
                totalSoldeCaisseFm - soldeEffCaisseFm
              ).toFixed(2),
              totalTreso: totalTreso.toFixed(2),
            },
          ]);
        } else {
          console.error("Total Sortie is undefined.");
        }

        setIsDataReadySituationCaisse(true);
      } catch (error) {
        console.error("Error FetchingSituationCaisse data:", error);
        setIsDataReadySituationCaisse(false);
      }
    };

    fetchData();
  }, [fetchTotalPaidAmount, fetchTotalTreso, fetchTotalSortieCaisseFm]);

  const handleSave = async () => {
    try {
		  console.log("Dates sent to backend:", startDate, endDate);
      const response = await axios.post(
        "https://gestionforage.terangapp.com/api/situationcaisse",
        {
          startDate,
          endDate,
          rows,
        }
      );

      if (response.status === 201) {
        toast.success("Data saved successfully");
      } else {
        toast.error("Error saving data");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast.error(error.response.data.error);
      } else {
        console.error("Error saving data:", error);
        toast.error("Error saving data");
      }
    }
  };

  const handleUpdate = async (id, updatedSoldeEffCaisseFm) => {
    try {
      const response = await axios.put(
        `https://gestionforage.terangapp.com/api/situationcaisse/${id}`,
        {
          soldeEffCaisseFm: updatedSoldeEffCaisseFm,
        }
      );

      const updatedRow = response.data;
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === id ? updatedRow : row))
      );
      toast.success("Data updated successfully");
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error("Veuillez enregistrer la situation caisse d'abord.");
      } else {
        console.error("Error updating data:", error);
        toast.error("Error updating data");
      }
    }
  };

  const handlePrint = () => {
    if (!isDataReadySituationCaisse) {
      toast.error(
        "Les données ne sont pas prêtes. Veuillez sélectionner une plage de dates et réessayer."
      );
      return;
    }
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
      <head>
        <title>Situation de Caisse</title>
        <style>
          body { font-family: Arial, sans-serif; margin: 60px; }
          .header { display: flex; justify-content: space-between; align-items: center; }
          .header p { margin: 0; }
          h1 { text-align: center; }
          table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
          th, td { border: 1px solid #000; padding: 8px; }
          th { background-color: #f2f2f2; }
        </style>
      </head>
      <body>
    `);

    printWindow.document.write(`
      <div class="header">
        <div><p>COPIFOR DE DAROU NGARAF</p></div>
        <div><p>Période du ${startDate} au ${endDate}</p></div>
      </div>
    `);

    printWindow.document.write("<h1>Situation de Caisse</h1>");
    printWindow.document.write("<table border='1'>");
    printWindow.document.write(`
      <tr>
        <th>Total Caisse Fin du Mois</th>
        <th>Total Sortie Caisse</th>
        <th>Total Solde Caisse</th>
        <th>Solde Effectif Caisse</th>
        <th>Différence à Justifier</th>
        <th>Total Trésorerie</th>
      </tr>
    `);

    rows.forEach((row) => {
      printWindow.document.write("<tr>");
      printWindow.document.write(`<td>${row.totalCaisseFm}</td>`);
      printWindow.document.write(`<td>${row.totalSortieCaisseFm}</td>`);
      printWindow.document.write(`<td>${row.totalSoldeCaisseFm}</td>`);
      printWindow.document.write(`<td>${row.soldeEffCaisseFm}</td>`);
      printWindow.document.write(`<td>${row.differenceAJustifier}</td>`);
      printWindow.document.write(`<td>${row.totalTreso}</td>`);
      printWindow.document.write("</tr>");
    });

    printWindow.document.write("</table>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const handleEditClick = (row) => {
    setEditRow(row);
    setEditValue(row.soldeEffCaisseFm);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setEditRow(null);
    setEditValue("");
  };

  const handleDialogSave = () => {
    handleUpdate(editRow.id, editValue);
    handleDialogClose();
  };

  const columnsSituationCaisse = [
    { field: "totalCaisseFm", headerName: "Total Caisse Fin du Mois", flex: 1 },
    {
      field: "totalSortieCaisseFm",
      headerName: "Total Sortie Caisse",
      flex: 1,
    },
    { field: "totalSoldeCaisseFm", headerName: "Total Solde Caisse", flex: 1 },
    { field: "soldeEffCaisseFm", headerName: "Solde Effectif Caisse", flex: 1 },
    {
      field: "differenceAJustifier",
      headerName: "Différence à Justifier",
      flex: 1,
    },
    { field: "totalTreso", headerName: "Total Trésorerie", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleEditClick(params.row)}>
          <Edit />
        </IconButton>
      ),
    },
  ];
  
    const CustomNoRowsOverlay = () => (
	  <GridOverlay>
		<Typography>Aucune donnée disponible</Typography>
	  </GridOverlay>
	);

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box>
        <TextField
            id="start-date"
            label="Période de début"
            type="date"
            value={convertToYYYYMMDD(startDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setStartDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="end-date"
            label="Période de fin"
            type="date"
            value={convertToYYYYMMDD(endDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setEndDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            startIcon={<DownloadOutlined />}
          >
            Enregistrer
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handlePrint}
            startIcon={<DownloadOutlined />}
            style={{ marginLeft: 16 }}
          >
            Imprimer
          </Button>
        </Box>
      </Box>
      <DataGrid rows={rows} columns={columnsSituationCaisse} autoHeight 
		components={{
			NoRowsOverlay: CustomNoRowsOverlay,
		}}
	  />
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Edit Solde Effectif Caisse</DialogTitle>
        <DialogContent>
          <TextField
            label="Solde Effectif Caisse"
            type="number"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Annuler
          </Button>
          <Button onClick={handleDialogSave} color="primary">
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SituationCaisse;
