import React, { useState, useEffect } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Print, Edit, FilterList } from "@mui/icons-material";
import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";


const CategoriesList = () => {
  const theme = useTheme();
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [filters, setFilters] = useState({
    selectedDate: "",
    observations: "",
  });

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("https://gestionforage.terangapp.com/api/categories");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCategories(data);
        setFilteredCategories(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handlePrint = (row) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(
      "<html><head><title>Mini rapport</title></head><body>"
    );
    printWindow.document.write("<h1>Catégories</h1>");
    printWindow.document.write(`<p>Date: ${row.selectedDate}</p>`);
    printWindow.document.write(
      `<p>Abonnés Domestiques: ${row.abonnesDomestiques}</p>`
    );
    printWindow.document.write(
      `<p>Bornes Fontaines: ${row.bornesFontaines}</p>`
    );
    printWindow.document.write(
      `<p>Activités Commerciales: ${row.activitesCommerciales}</p>`
    );
    printWindow.document.write(
      `<p>Activités Maraîchères: ${row.activitesMaraicheres}</p>`
    );
    printWindow.document.write(`<p>Abreuvoirs: ${row.abreuvoirs}</p>`);
    printWindow.document.write(
      `<p>Stations Acharettes: ${row.stationsAcharettes}</p>`
    );
    printWindow.document.write(`<p>Autres: ${row.autres}</p>`);
    printWindow.document.write(`<p>Fonctionnel: ${row.fonctionnel}</p>`);
    printWindow.document.write(`<p>Non Fonctionnel: ${row.nonFonctionnel}</p>`);
    printWindow.document.write(`<p>Total: ${row.total}</p>`);
    printWindow.document.write(`<p>Observations: ${row.observations}</p>`);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const handleEdit = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setSelectedRow((prev) => ({ ...prev, [name]: value }));
  };

const handleEditSave = async () => {
  try {
    const response = await fetch(
      `https://gestionforage.terangapp.com/api/categories/${selectedRow.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          abonnesDomestiques: selectedRow.abonnesDomestiques,
          bornesFontaines: selectedRow.bornesFontaines,
          activitesCommerciales: selectedRow.activitesCommerciales,
          activitesMaraicheres: selectedRow.activitesMaraicheres,
          abreuvoirs: selectedRow.abreuvoirs,
          stationsAcharettes: selectedRow.stationsAcharettes,
          autres: selectedRow.autres,
          fonctionnel: selectedRow.fonctionnel,
          nonFonctionnel: selectedRow.nonFonctionnel,
          observations: selectedRow.observations,
          // `selectedDate` is not included in the request body
          // `total` is computed on the server-side, so it is not included here either
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update data");
    }

    // Fetch the updated categories to refresh the DataGrid
    const fetchCategories = async () => {
      try {
        const response = await fetch("https://gestionforage.terangapp.com/api/categories");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setCategories(data);
        setFilteredCategories(data);  // Reset filtered data to the fresh fetch
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };

    await fetchCategories();  // Fetch data after saving changes

    setOpenEditDialog(false);
  } catch (error) {
    console.error("Failed to update data:", error);
  }
};

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const applyFilters = () => {
    const filtered = categories.filter((category) =>
      Object.keys(filters).every((key) =>
        category[key]
          .toString()
          .toLowerCase()
          .includes(filters[key].toLowerCase())
      )
    );
    setFilteredCategories(filtered);
  };

  const columns = [
    { field: "selectedDate", headerName: "Date", flex: 1 },
    { field: "abonnesDomestiques", headerName: "Abonnés Domestiques", flex: 1 },
    { field: "bornesFontaines", headerName: "Bornes Fontaines", flex: 1 },
    {
      field: "activitesCommerciales",
      headerName: "Activités Commerciales",
      flex: 1,
    },
    {
      field: "activitesMaraicheres",
      headerName: "Activités Maraîchères",
      flex: 1,
    },
    { field: "abreuvoirs", headerName: "Abreuvoirs", flex: 1 },
    { field: "stationsAcharettes", headerName: "Stations Acharettes", flex: 1 },
    { field: "autres", headerName: "Autres", flex: 1 },
    { field: "fonctionnel", headerName: "Fonctionnel", flex: 1 },
    { field: "nonFonctionnel", headerName: "Non Fonctionnel", flex: 1 },
    { field: "total", headerName: "Total", flex: 1 },
    { field: "observations", headerName: "Observations", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Print />}
          label="Print"
          onClick={() => handlePrint(params.row)}
        />,
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleEdit(params.row)}
        />,
      ],
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" component="h2">
          Liste des Catégories
        </Typography>
        <Button
          onClick={applyFilters}
          sx={{
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.background.alt,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          <FilterList sx={{ mr: "10px" }} />
          Appliquer les filtres
        </Button>
      </Box>
      <Box mt="20px" mb="20px">
        <Box display="flex" justifyContent="flex-start" mb="10px">
          <TextField
            label="Date"
            name="selectedDate"
            value={filters.selectedDate}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            sx={{ mr: 2 }}
          />
          <TextField
            label="Observations"
            name="observations"
            value={filters.observations}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
          />
        </Box>
      </Box>
      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          rows={filteredCategories}
          columns={columns}
        />
      </Box>

      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Modifier la Catégorie</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Modifiez les champs nécessaires et sauvegardez.
          </DialogContentText>
          {selectedRow && (
            <>
              <TextField
                margin="dense"
                name="abonnesDomestiques"
                label="Abonnés Domestiques"
                type="number"
                fullWidth
                value={selectedRow.abonnesDomestiques || 0}  // Ensure it’s never undefined
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                name="bornesFontaines"
                label="Bornes Fontaines"
                type="number"
                fullWidth
                value={selectedRow.bornesFontaines || 0}  // Ensure it’s never undefined
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                name="activitesCommerciales"
                label="Activités Commerciales"
                type="number"
                fullWidth
                value={selectedRow.activitesCommerciales || 0}  // Ensure it’s never undefined
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                name="activitesMaraicheres"
                label="Activités Maraîchères"
                type="number"
                fullWidth
                value={selectedRow.activitesMaraicheres || 0}  // Ensure it’s never undefined
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                name="abreuvoirs"
                label="Abreuvoirs"
                type="number"
                fullWidth
                value={selectedRow.abreuvoirs || 0}  // Ensure it’s never undefined
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                name="stationsAcharettes"
                label="Stations Acharettes"
                type="number"
                fullWidth
                value={selectedRow.stationsAcharettes || 0}  // Ensure it’s never undefined
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                name="autres"
                label="Autres"
                type="number"
                fullWidth
                value={selectedRow.autres || 0}  // Ensure it’s never undefined
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                name="fonctionnel"
                label="Fonctionnel"
                type="number"
                fullWidth
                value={selectedRow.fonctionnel || 0}  // Ensure it’s never undefined
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                name="nonFonctionnel"
                label="Non Fonctionnel"
                type="number"
                fullWidth
                value={selectedRow.nonFonctionnel || 0}  // Ensure it’s never undefined
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                name="total"
                label="Total"
                type="number"
                fullWidth
                value={(parseFloat(selectedRow.total) || 0).toFixed(2)}  // Ensure it’s a fixed-point number
                onChange={handleEditChange}
                disabled  // Disable the field as it is computed
              />
              <TextField
                margin="dense"
                name="observations"
                label="Observations"
                type="text"
                fullWidth
                value={selectedRow.observations || ''}  // Ensure it’s never undefined
                onChange={handleEditChange}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)}>Annuler</Button>
          <Button onClick={handleEditSave}>Sauvegarder</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CategoriesList;
