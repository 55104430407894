import React, { useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import Header from "components/Header";
import { ResponsiveLine } from "@nivo/line";
import axios from "axios";

import { jsPDF } from "jspdf";
import "jspdf-autotable";

const ConsommationSuperieure = () => {
  const [formattedData, setFormattedData] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://gestionforage.terangapp.com/api/consommation-superieure`
        );
        const data = response.data;
        console.log("Fetched data:", data);
        // Process and format the data as needed
        const formattedData = processData(data);
        console.log("Formatted data:", formattedData);
        setFormattedData(formattedData);
      } catch (error) {
        console.error("Error fetching consommation data:", error);
      }
    };

    fetchData();
  }, []); // Run once on component mount

  const processData = (data) => {
    // Process and format data here
    return data.map((item) => ({
      x: item.waterBillRefNumber,
      z: item.firstName,
      a: item.lastName,
      c: item.previousIndex,
      d: item.currentIndex,
      y: item.consommation,
      b: item.phoneNumber,
      e: item.paidAmount,
      f: item.paymentStatus,
    }));
  };

  const generatePDF = () => {
    const doc = new jsPDF();

    // Calculate top margin for the title
    const topMargin = 20;

    // Add a title with adjusted top margin
    doc.setFontSize(18);
    const title = "Graphique de la consommation d'eau supérieure à la moyenne";
    const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = (pageWidth - titleWidth) / 2;
    doc.text(title, margin, topMargin);

    // Add margin between title and table
    let yPos = topMargin + 15; // Initial Y position after title
    yPos += 10; // Add extra margin

    // Your existing autoTable code
    doc.autoTable({
      head: [
        [
          "Réf. Facture Eau",
          "Nom",
          "Prénom",
          "Index précédent",
          "Index actuel",
          "Consommation",
          "Téléphone",
          "Montant payé",
          "Statut de paiement",
        ],
      ],
      body: formattedData.map((item) => [
        item.x,
        item.z,
        item.a,
        item.c,
        item.d,
        item.y,
        item.b,
        item.e,
        item.f,
      ]),
      startY: yPos, // Start table after the title and margin
    });

    // Add a footer (e.g., page number)
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text(`Page ${i} de ${pageCount}`, 190, 290); // Adjust position as needed
    }

    doc.save("consommations.pdf");
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="CONSOMMATION SUPÉRIEURE"
        subtitle="Graphique de la consommation d'eau supérieure à la moyenne"
      />
      <Box display="flex" justifyContent="flex-end" mb="1rem">
        {formattedData.length > 0 && (
          <Button variant="contained" color="primary" onClick={generatePDF}>
            Télécharger PDF
          </Button>
        )}
      </Box>
      <Box height="75vh" id="chartContainer">
        {formattedData.length > 0 ? (
          <ResponsiveLine
            data={[
              {
                id: "consommationSuperieure",
                data: formattedData,
              },
            ]}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: theme.palette.secondary[200],
                  },
                },
                legend: {
                  text: {
                    fill: theme.palette.secondary[200],
                  },
                },
                ticks: {
                  line: {
                    stroke: theme.palette.secondary[200],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: theme.palette.secondary[200],
                  },
                },
              },
              grid: {
                line: {
                  stroke: theme.palette.grey[200],
                },
              },
              legends: {
                text: {
                  fill: theme.palette.secondary[200],
                },
              },
              tooltip: {
                container: {
                  background: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                },
              },
            }}
            margin={{ top: 50, right: 50, bottom: 70, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: 0,
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 90,
              legend: "Numéro de référence de la facture d'eau",
              legendOffset: 60,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Consommation",
              legendOffset: -50,
              legendPosition: "middle",
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "top-right",
                direction: "column",
                justify: false,
                translateX: 50,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        ) : (
          <>Pas encore de données en ce jour.</>
        )}
      </Box>
    </Box>
  );
};

export default ConsommationSuperieure;
