import React, { useState } from "react";
import { Grid, TextField, Button, Container, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import Header from "components/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import tresoImage from "../assets/tresoImage.png";

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

const BanqueForm = () => {
  const mode = useSelector((state) => state.global.mode);

  const [formData, setFormData] = useState({
    selectedDate: getCurrentDate(),
    numeroCompteBancaire: "",
    natureCompte: "",
    versement: 0,
    retrait: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "numeroCompteBancaire" || name === "natureCompte"
          ? value
          : parseFloat(value),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch("https://gestionforage.terangapp.com/api/banque", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || `HTTP error! status: ${response.status}`
        );
      }

      const data = await response.json();
      console.log("Form submitted:", data);

      toast.success("Banque record added successfully.");

      setFormData({
        selectedDate: getCurrentDate(),
        numeroCompteBancaire: "",
        natureCompte: "",
        versement: 0,
        retrait: 0,
      });
    } catch (error) {
      console.error("Error submitting the form!", error);
      toast.error(
        error.message || "Error submitting the form. Please try again."
      );
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, ml: 3 }}>
      <Header
        title="Ajouter une donnée bancaire"
        subtitle="Les valeurs 0 sont remplies par défaut"
        sx={{ mb: 5 }}
      />
      <Grid container spacing={3} sx={{ mt: 4 }}>
        <Grid item xs={8}>
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Date"
                  type="date"
                  name="selectedDate"
                  value={formData.selectedDate}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Numéro de Compte Bancaire"
                  type="text"
                  name="numeroCompteBancaire"
                  value={formData.numeroCompteBancaire}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  label="Nature du Compte"
                  name="natureCompte"
                  value={formData.natureCompte}
                  onChange={handleChange}
                  required
                  fullWidth
                >
                  <MenuItem value="Epargne">Epargne</MenuItem>
                  <MenuItem value="Courant">Courant</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Versement"
                  type="number"
                  name="versement"
                  value={formData.versement}
                  onChange={handleChange}
                  required
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Retrait"
                  type="number"
                  name="retrait"
                  value={formData.retrait}
                  onChange={handleChange}
                  required
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color={mode === "dark" ? "secondary" : "primary"}
                  size="large"
                  sx={{
                    width: "100%",
                  }}
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={4} container alignItems="center" justifyContent="center">
          <img
            src={tresoImage}
            alt="Forage"
            style={{
              width: "100%",
              maxWidth: "200px",
              display: "block",
              margin: "0 auto",
              borderRadius: 10,
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default BanqueForm;
