import React, { useState } from "react";
import { Grid, TextField, Button, Container } from "@mui/material";
import { useSelector } from "react-redux";
import Header from "components/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Infos = () => {
  const mode = useSelector((state) => state.global.mode);

  const [formData, setFormData] = useState({
    title_left: "Titre 1",
    title_right: "Signature",
    input_field: "",
    bottom_title: "Titre 2",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch("https://gestionforage.terangapp.com/api/infos", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP! statut: ${response.status}`);
      }

      const data = await response.json();
      console.log("Form submitted:", data);

      toast.success("Formulaire soumis avec succès.");

      setFormData({
        title_left: "Titre 1",
        title_right: "Signature",
        input_field: "",
        bottom_title: "Titre 2",
      });
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire!", error);
      toast.error(
        "Erreur lors de la soumission du formulaire. Veuillez réessayer."
      );
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 2, ml: 3 }}>
      <Header
        title="Ajouter autre information"
        subtitle="Les champs sont obligatoires sauf le champs commentaire"
        sx={{ mb: 5 }}
      />
      <Grid container spacing={2} sx={{ mt: 4 }} justifyContent="center">
        <Grid item xs={8}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {[
                { name: "title_left", label: "Title Left" },
                { name: "title_right", label: "Title Right" },
                { name: "input_field", label: "Input Field" },
                { name: "bottom_title", label: "Bottom Title" },
              ].map((field) => (
                <Grid item xs={6} key={field.name}>
                  <TextField
                    label={field.label}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleChange}
                    required={field.name !== "input_field"}
                    fullWidth
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color={mode === "dark" ? "secondary" : "primary"}
                  size="large"
                  sx={{
                    width: "100%",
                  }}
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Infos;
