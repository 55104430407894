import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, TextField, Button, Typography, MenuItem } from "@mui/material";
import Header from "components/Header";

// Convertir une date de yyyy-MM-dd en dd-MM-yyyy
const formatDateToDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// Convertir une date de dd-MM-yyyy en yyyy-MM-dd
const convertToYYYYMMDD = (dateString) => {
  const [day, month, year] = dateString.split("-");
  return `${year}-${month}-${day}`;
};

// Récupérer le premier jour du mois précédent en dd-MM-yyyy
const getFirstDayOfMonth = () => {
  const currentDate = new Date();
  currentDate.setUTCMonth(currentDate.getUTCMonth() - 1); // Met au mois précédent en UTC
  currentDate.setUTCDate(1); // Met au 1er jour du mois précédent en UTC
  const firstDay = currentDate.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(firstDay); // Convertir en dd-MM-yyyy
};

// Récupérer le dernier jour du mois précédent en dd-MM-yyyy
const getLastDayOfMonth = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0)
  );
  const lastDayFormatted = lastDay.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(lastDayFormatted); // Convertir en dd-MM-yyyy
};

const formatDateForInput = (dateStr) => {
  if (!dateStr) return ""; // Handle case where dateStr is undefined or null

  const [day, month, year] = dateStr.split("-");
  if (!day || !month || !year) return ""; // Handle unexpected date format

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};


const ConsommationZero = () => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  const [endDate, setEndDate] = useState(getLastDayOfMonth());
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentStatuses, setPaymentStatuses] = useState([]);

  useEffect(() => {
    const fetchPaymentStatuses = async () => {
      try {
        const response = await axios.get("https://gestionforage.terangapp.com/api/payment-statuses");
        setPaymentStatuses(response.data);
        console.log("Payment Statuses:", response.data);
      } catch (error) {
        console.error("Error fetching payment statuses:", error);
      }
    };

    fetchPaymentStatuses();
  }, []);

  const fetchData = async () => {
    try {
      console.log("Fetching data with parameters:", { startDate, endDate, paymentStatus });

      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/consommation-zero",
        {
          params: {
            startDate: formatDateForInput(startDate),
            endDate: formatDateForInput(endDate),
            paymentStatus,
          },
        }
      );
      setData(response.data);
      console.log("Data fetched:", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="CONSOMMATION NULLE"
        subtitle="Graphique de la consommation d'eau dont index actuel égal index précédent"
      />
     <Box mb={2} mt={5}>
	  <TextField
		id="start-date"
		label="Période de début"
		type="date"
		value={convertToYYYYMMDD(startDate)}
		onChange={(e) => setStartDate(formatDateToDDMMYYYY(e.target.value))}
		InputLabelProps={{
		  shrink: true,
		}}
		style={{ marginRight: '1rem' }} // Adjust margin-right as needed
	  />
	  <TextField
		id="end-date"
		label="Période de fin"
		type="date"
		value={convertToYYYYMMDD(endDate)}
		onChange={(e) => setEndDate(formatDateToDDMMYYYY(e.target.value))}
		InputLabelProps={{
		  shrink: true,
		}}
	  />
	</Box>

      <Box mb={2}>
        <TextField
          label="Choisir un statut de Paiement"
          select
          value={paymentStatus}
          onChange={(e) => setPaymentStatus(e.target.value)}
          style={{ width: '310px' }}
        >
          <MenuItem value="">
            <em>Aucun</em>
          </MenuItem>
          {paymentStatuses.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Button variant="contained" onClick={fetchData}>
        Collecter Données
      </Button>
      <Box mt={2}>
        {data.length > 0 ? (
          <ul>
            {data.map((item) => (
              <li key={item.waterBillRefNumber}>
                Numéro de référence de la facture d'eau: {item.waterBillRefNumber}, Consommation:{" "}
                {item.consommation}
              </li>
            ))}
          </ul>
        ) : (
          <Typography variant="body1">Pas de données disponibles.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default ConsommationZero;
