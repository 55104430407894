import React, { useState, useEffect, useCallback } from "react";
import { DataGrid, GridOverlay, } from "@mui/x-data-grid";
import { DownloadOutlined } from "@mui/icons-material";
import { Box, Button, useTheme, TextField, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//if (!isDataReadyTresorerieGlobale || !isDataReadyTreso) {
      //toast.error(
        //"Les données ne sont pas prêtes. Veuillez sélectionner une plage de dates et réessayer."
      //);
      //return;
    //}

// Fonction pour convertir les dates en format dd-MM-yyyy
const formatDateToDDMMYYYY = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`;
};

// Fonction pour convertir les dates en format yyyy-MM-dd
const convertToYYYYMMDD = (dateString) => {
  const [day, month, year] = dateString.split('-');
  return `${year}-${month}-${day}`;
};

// Récupérer le premier jour du mois précédent en dd-MM-yyyy
const getFirstDayOfMonth = () => {
  const currentDate = new Date();
  currentDate.setUTCMonth(currentDate.getUTCMonth() - 1); // Met au mois précédent en UTC
  currentDate.setUTCDate(1); // Met au 1er jour du mois précédent en UTC
  const firstDay = currentDate.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(firstDay); // Convertir en dd-MM-yyyy
};

// Récupérer le dernier jour du mois précédent en dd-MM-yyyy
const getLastDayOfMonth = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0)
  );
  const lastDayFormatted = lastDay.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(lastDayFormatted); // Convertir en dd-MM-yyyy
};

// Récupérer le premier jour du mois précédent en dd-MM-yyyy
const getFirstDayOfPreviousMonth = () => {
  const currentDate = new Date();
  currentDate.setUTCMonth(currentDate.getUTCMonth() - 2); // Met au mois précédent en UTC
  currentDate.setUTCDate(1); // Met au 1er jour du mois précédent en UTC
  const firstDay = currentDate.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(firstDay); // Convertir en dd-MM-yyyy
};

// Récupérer le dernier jour du mois précédent en dd-MM-yyyy
const getLastDayOfPreviousMonth = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth() -1, 0)
  );
  const lastDayFormatted = lastDay.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(lastDayFormatted); // Convertir en dd-MM-yyyy
};

const useFetchAggregatedDataTresorerieGlobale = (startDate, endDate) => {
  const [
    aggregatedConsommationsTresorerieGlobale,
    setAggregatedConsommationsTresorerieGlobale,
  ] = useState([]);
  const [totalTresorerieGlobale, setTotalTresorerieGlobale] = useState(null);
  const [isFetchingTresorerieGlobale, setIsFetchingTresorerieGlobale] =
    useState(false);
  const [isDataReadyTresorerieGlobale, setIsDataReadyTresorerieGlobale] =
    useState(false);

const fetchAggregatedData = useCallback(async () => {
  if (!startDate || !endDate) {
    toast.error("Veuillez sélectionner une plage de dates.");
    return;
  }

  console.log("Fetching data with the following dates:");
  console.log("Start Date:", startDate);
  console.log("End Date:", endDate);

  setIsFetchingTresorerieGlobale(true);

  try {
    const response = await axios.get(
      "https://gestionforage.terangapp.com/api/consommations/tresoreriedebutmois",
      {
        params: { startDate: startDate, endDate: endDate },
      }
    );

    console.log("API Response:", response);

    if (response.data && response.data.rows && response.data.total) {
      const dataWithIds = response.data.rows.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      console.log("Formatted Data with IDs:", dataWithIds);

      setAggregatedConsommationsTresorerieGlobale(dataWithIds);
      setTotalTresorerieGlobale(response.data.total);
      setIsDataReadyTresorerieGlobale(true);
    } else {
      console.error("Format de réponse API invalide");
      toast.error("Format de réponse API invalide. Veuillez réessayer.");
    }
  } catch (error) {
    console.error("Échec de la récupération des données agrégées:", error);
    toast.error(
      "Échec de la récupération des données agrégées. Veuillez réessayer."
    );
    setIsDataReadyTresorerieGlobale(false);
  }

  setIsFetchingTresorerieGlobale(false);
}, [startDate, endDate]);

  useEffect(() => {
    fetchAggregatedData();
  }, [startDate, endDate, fetchAggregatedData]);

  return {
    aggregatedConsommationsTresorerieGlobale,
    totalTresorerieGlobale,
    isFetchingTresorerieGlobale,
    isDataReadyTresorerieGlobale,
  };
};

const useFetchAggregatedDataTreso = () => {
  const [aggregatedTreso, setAggregatedTreso] = useState([]);
  const [totalTreso, setTotalTreso] = useState(null);
  const [isFetchingTreso, setIsFetchingTreso] = useState(false);
  const [isDataReadyTreso, setIsDataReadyTreso] = useState(false);

  const startDateTreso = getFirstDayOfPreviousMonth();
  const endDateTreso = getLastDayOfPreviousMonth();

  const fetchAggregatedDataTreso = useCallback(async () => {
  console.log("Fetching aggregated data Treso...");
  console.log("Start Date Treso:", startDateTreso);
  console.log("End Date Treso:", endDateTreso);

  setIsFetchingTreso(true);

  try {
    const response = await axios.get(
      "https://gestionforage.terangapp.com/api/tresorerieglobale",
      {
        params: { startDate: startDateTreso, endDate: endDateTreso },
      }
    );

    console.log("API Response for Treso:", response);

    const { data } = response;

    // Check if data and required fields exist
    if (data && data.records) {
      // Fallback value if totalTresoDispo is missing
      const totalTresoDispo = data.records.totalTresoDispo || "N/A";

      // Ensure data.records is an array or set a fallback empty array
      const records = Array.isArray(data.records) ? data.records : [];

      // Add ID to each record if needed
      const dataWithIds = records.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      console.log("Formatted Treso Data with IDs:", dataWithIds);

      setAggregatedTreso(dataWithIds);
      setTotalTreso(totalTresoDispo);
      setIsDataReadyTreso(true);
    } else {
      console.error("Format de réponse API invalide: 'data.records' is missing");
      toast.error("Format de réponse API invalide. Veuillez réessayer.");
      setAggregatedTreso([]);
      setTotalTreso("N/A");
      setIsDataReadyTreso(false);
    }
  } catch (error) {
    console.error("Échec de la récupération des données agrégées Treso:", error);
    toast.error(
      "Échec de la récupération des données agrégées. Veuillez réessayer."
    );
    setAggregatedTreso([]);
    setTotalTreso("N/A");
    setIsDataReadyTreso(false);
  }

  setIsFetchingTreso(false);
}, [startDateTreso, endDateTreso]);

  useEffect(() => {
    fetchAggregatedDataTreso();
  }, [fetchAggregatedDataTreso]);

  return { aggregatedTreso, totalTreso, isFetchingTreso, isDataReadyTreso };
};

const TresorerieGlobaleList = () => {
  const theme = useTheme();
  const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  const [endDate, setEndDate] = useState(getLastDayOfMonth());
  const {
    aggregatedConsommationsTresorerieGlobale,
    totalTresorerieGlobale,
    isFetchingTresorerieGlobale,
    isDataReadyTresorerieGlobale,
  } = useFetchAggregatedDataTresorerieGlobale(startDate, endDate);
  const { aggregatedTreso, totalTreso, isFetchingTreso, isDataReadyTreso } =
    useFetchAggregatedDataTreso(startDate, endDate);

  useEffect(() => {
    console.log(
      "Aggregated ConsommationsTresorerieGlobale:",
      aggregatedConsommationsTresorerieGlobale
    );
    console.log("Total:", totalTresorerieGlobale);
    console.log("Is Data Ready:", isDataReadyTresorerieGlobale);
  }, [
    aggregatedConsommationsTresorerieGlobale,
    totalTresorerieGlobale,
    isDataReadyTresorerieGlobale,
  ]);

  useEffect(() => {
    console.log("Aggregated Treso:", aggregatedTreso);
    console.log("Total Treso:", totalTreso);
    console.log("Is Data Ready Treso:", isDataReadyTreso);
  }, [aggregatedTreso, totalTreso, isDataReadyTreso]);

  const handlePrint = async () => {
    

    try {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
        <head>
          <title>Rapport Gestion</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 60px; }
            .header { display: flex; justify-content: space-between; align-items: center; }
            .header p { margin: 0; }
            h1 { text-align: center; }
            h2 { text-align: left; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            th, td { border: 1px solid #000; padding: 8px; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
      `);

      printWindow.document.write(`
        <div class="header">
          <div><p>COPIFOR DE DAROU NGARAF</p></div>
          <div><p>Période du ${startDate} au ${endDate}</p></div>
        </div>
      `);

      printWindow.document.write("<h1>Rapport Mensuel de Trésorerie</h1>");
      printWindow.document.write("<h2>Tableau de trésorerie du mois</h2>");
      printWindow.document.write("<table border='1'>");
      printWindow.document.write(`
      <tr>
        <td colspan="3"><strong>Total Caisse Début du Mois</strong></td>
        <td>${totalTreso}</td>
      </tr>
    `);
      printWindow.document.write(`
        <tr>
          <th>Catégorie de points de distribution d'eau</th>
          <th>Nombre de points de distribution</th>
          <th>Volume d'eau distribué</th>
          <th>Recettes perçues</th>
        </tr>
      `);

      if (aggregatedConsommationsTresorerieGlobale.length > 0) {
        aggregatedConsommationsTresorerieGlobale.forEach((item) => {
          printWindow.document.write(`
            <tr>
              <td>${item.typeBranchement}</td>
              <td>${item.nombres}</td>
              <td>${item.nombreMd}</td>
              <td>${item.paidAmount}</td>
            </tr>
          `);
        });
        printWindow.document.write(`
          <tr>
            <td><strong>Total</strong></td>
            <td>${totalTresorerieGlobale.nombres}</td>
            <td>${totalTresorerieGlobale.nombreMd}</td>
            <td>${totalTresorerieGlobale.paidAmount}</td>
          </tr>
        `);
        printWindow.document.write(`
          <tr>
            <td colspan="3"><strong>Total des entrées caisse du mois</strong></td>
            <td>${totalTresorerieGlobale.paidAmount}</td>
          </tr>
        `);
        printWindow.document.write(`
        <tr>
          <td colspan="3"><strong>Total Caisse fin du mois</strong></td>
          <td>${
            parseFloat(totalTresorerieGlobale.paidAmount) +
            parseFloat(totalTreso)
          }</td>
        </tr>
      `);
      } else {
        printWindow.document.write(`
          <tr><td colspan="4">Aucune donnée disponible</td></tr>
        `);
      }

      printWindow.document.write("</table>");
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      toast.success("Rapport trésorerie imprimé avec succès.");
    } catch (error) {
      console.error("Failed to print:", error);
      toast.error(
        "Échec de l'impression du rapport de trésorerie. Veuillez réessayer."
      );
    }
  };

  const columnsTresorerieGlobale = [
    {
      field: "typeBranchement",
      headerName: "Catégorie de points de distribution d'eau",
      flex: 1,
    },
    {
      field: "nombres",
      headerName: "Nombre de points de distribution",
      flex: 1,
    },
    { field: "nombreMd", headerName: "Volume d'eau distribué", flex: 1 },
    { field: "paidAmount", headerName: "Recettes perçues", flex: 1 },
  ];

  const columnsTotalTreso = [
    {
      field: "totalTresoDispo",
      headerName: "Trésorerie Début de Mois",
      flex: 1,
    },
  ];

    const CustomNoRowsOverlay = () => (
	  <GridOverlay>
		<Typography>Aucune donnée disponible</Typography>
	  </GridOverlay>
	);

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Liste des Données de Trésorerie
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="secondary"
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginTop: "1px",
              marginRight: "5px",
            }}
            onClick={handlePrint}
            disabled={
              isFetchingTresorerieGlobale || !isDataReadyTresorerieGlobale
            }
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Télécharger le rapport de Trésorerie
          </Button>
          <TextField
            id="start-date"
            label="Période de début"
            type="date"
            value={convertToYYYYMMDD(startDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setStartDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="end-date"
            label="Période de fin"
            type="date"
            value={convertToYYYYMMDD(endDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setEndDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </Box>
      <Box
        mt="10px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={aggregatedConsommationsTresorerieGlobale}
          columns={columnsTresorerieGlobale}
          getRowId={(row) => row.id}
          loading={isFetchingTresorerieGlobale}
          components={{
			  Footer: () => (
				<Box display="flex" justifyContent="flex-start" alignItems="center" padding="10px">
				  {isFetchingTreso && <Typography>Chargement...</Typography>}
				  {!isFetchingTreso && aggregatedTreso.length === 0 && (
					<Typography>Aucune donnée disponible</Typography>
				  )}
				  <Typography variant="subtitle1" component="div" sx={{ flex: 3 }}>
					<strong>Trésorerie Début de Mois</strong>
				  </Typography>
				  <Typography variant="subtitle1" component="div" sx={{ flex: 1 }}>
					{totalTreso || "0"}
				  </Typography>
				</Box>
			  ),
			  NoRowsOverlay: CustomNoRowsOverlay,
			}}
		/>
      </Box>
      <DataGrid
        rows={aggregatedTreso}
        columns={columnsTotalTreso}
        getRowId={(row) => row.id}
        loading={isFetchingTreso}
        components={{
          Footer: () => (
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              padding="10px"
              bgcolor={theme.palette.background.alt}
            >
              {isFetchingTreso && <p>Chargement...</p>}
              {!isFetchingTreso && aggregatedTreso.length === 0 && (
                <p>Aucune donnée disponible</p>
              )}
              <Typography variant="subtitle1" component="div" sx={{ flex: 3 }}>
                <strong>Trésorerie Début de Mois</strong>
              </Typography>
              <Typography variant="subtitle1" component="div" sx={{ flex: 1 }}>
                {totalTreso || "0"}
              </Typography>
            </Box>
          ),
        }}
      />
    </Box>
  );
};

export default TresorerieGlobaleList;

