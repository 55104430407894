import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import Header from "components/Header";
import { ResponsiveLine } from "@nivo/line";
import axios from "axios"; // Import Axios for making HTTP requests

const Monthly = () => {
  const [formattedData, setFormattedData] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
        const response = await axios.get(
          `https://gestionforage.terangapp.com/api/top-consommations-monthly?currentDate=${today}` // Send currentDate
        );
        const data = response.data;
        console.log("Fetched data:", data);
        // Process and format the data as needed
        const formattedData = processData(data);
        console.log("Formatted data:", formattedData);
        setFormattedData(formattedData);
      } catch (error) {
        console.error("Error fetching consommation data:", error);
      }
    };

    fetchData();
  }, []); // Run once on component mount

  const processData = (data) => {
    // Process and format data here
    return data.map((item) => ({
      x: item.waterBillRefNumber,
      y: item.totalConsommation,
    }));
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="TOP CONSOMMATION"
        subtitle="Graphique de la consommation d'eau la plus élevée"
      />
      <Box height="75vh">
        {formattedData.length > 0 ? (
          <ResponsiveLine
            data={[
              {
                id: "topConsommation",
                data: formattedData,
              },
            ]}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: theme.palette.secondary[200],
                  },
                },
                legend: {
                  text: {
                    fill: theme.palette.secondary[200],
                  },
                },
                ticks: {
                  line: {
                    stroke: theme.palette.secondary[200],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: theme.palette.secondary[200],
                  },
                },
              },
              grid: {
                line: {
                  stroke: theme.palette.grey[200],
                },
              },
              legends: {
                text: {
                  fill: theme.palette.secondary[200],
                },
              },
              tooltip: {
                container: {
                  background: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                },
              },
            }}
            margin={{ top: 50, right: 50, bottom: 70, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: 0,
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            xFormat=" >-.2f"
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 90,
              legend: "Water Bill Ref Number",
              legendOffset: 60,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Total Consommation",
              legendOffset: -50,
              legendPosition: "middle",
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "middle",
                direction: "column",
                justify: false,
                translateX: 50,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        ) : (
          <>Pas encore de données en ce jour.</>
        )}
      </Box>
    </Box>
  );
};

export default Monthly;
