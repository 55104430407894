import React, { useState, useEffect, useCallback } from "react";
import { DataGrid, GridOverlay, } from "@mui/x-data-grid";
import { DownloadOutlined } from "@mui/icons-material";
import { Box, Button, useTheme, TextField, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Convertir une date de yyyy-MM-dd en dd-MM-yyyy
const formatDateToDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// Convertir une date de dd-MM-yyyy en yyyy-MM-dd
const convertToYYYYMMDD = (dateString) => {
  const [day, month, year] = dateString.split("-");
  return `${year}-${month}-${day}`;
};

// Récupérer le premier jour du mois précédent en dd-MM-yyyy
const getFirstDayOfMonth = () => {
  const currentDate = new Date();
  currentDate.setUTCMonth(currentDate.getUTCMonth() - 1); // Met au mois précédent en UTC
  currentDate.setUTCDate(1); // Met au 1er jour du mois précédent en UTC
  const firstDay = currentDate.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(firstDay); // Convertir en dd-MM-yyyy
};

// Récupérer le dernier jour du mois précédent en dd-MM-yyyy
const getLastDayOfMonth = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0)
  );
  const lastDayFormatted = lastDay.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(lastDayFormatted); // Convertir en dd-MM-yyyy
};

const RelevesIndicateursList = () => {
  const theme = useTheme();
  const [aggregatedRelevesIndicateurs, setAggregatedRelevesIndicateurs] =
    useState([]);
  const [startDate, setStartDate] = useState(getFirstDayOfMonth()); // dd-MM-yyyy
  const [endDate, setEndDate] = useState(getLastDayOfMonth()); // dd-MM-yyyy
  const [isFetchingRelevesInd, setIsFetchingRelevesInd] = useState(false);
  const [isDataReadyRelevesInd, setIsDataReadyRelevesInd] = useState(false);

  const fetchAggregatedDataRelevesInd = useCallback(async () => {
    if (!startDate || !endDate) {
      console.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetchingRelevesInd(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/relevesindicateurs",
        {
          params: { startDate: startDate, endDate: endDate }, 
        }
      );
      const dataWithIds = response.data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setAggregatedRelevesIndicateurs(dataWithIds);
      setIsDataReadyRelevesInd(true);
    } catch (error) {
      console.error("Échec de la récupération des relevés indicateurs agrégés:", error);
      toast.error("Échec de la récupération des relevés indicateurs. Veuillez réessayer.");
      setIsDataReadyRelevesInd(false);
    }
    setIsFetchingRelevesInd(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchAggregatedDataRelevesInd();
  }, [startDate, endDate, fetchAggregatedDataRelevesInd]);

  const handlePrint = async () => {
    if (!isDataReadyRelevesInd) {
      toast.error("Les données ne sont pas prêtes. Veuillez sélectionner une plage de dates et réessayer.");
      return;
    }

    try {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
        <head>
          <title>Rapport de Relevés Indicateurs</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 60px; }
            .header { display: flex; justify-content: space-between; align-items: center; }
            .header p { margin: 0; }
            h1 { text-align: center; }
            h2 { text-align: left; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            th, td { border: 1px solid #000; padding: 8px; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
      `);

      printWindow.document.write(`
        <div class="header">
          <div><p>COPIFOR DE DAROU NGARAF</p></div>
          <div><p>Période du ${startDate} au ${endDate}</p></div>
        </div>
      `);

      printWindow.document.write(
        "<h1>Rapport Mensuel de Relevés Indicateurs</h1>"
      );
      printWindow.document.write(
        "<h2>Tableau de Relevés Indicateurs du mois</h2>"
      );
      printWindow.document.write("<table border='1'>");
      printWindow.document.write(`
        <tr>
          <th>Designation</th>
          <th>Unités</th>
          <th>Valeur</th>
        </tr>
      `);

      if (aggregatedRelevesIndicateurs.length > 0) {
        aggregatedRelevesIndicateurs.forEach((item) => {
          printWindow.document.write(`
            <tr>
              <td>Index Compteur Horaire Pompe</td>
              <td>${item.indexCompteurHorairePompeUnit}</td>
              <td>${item.indexCompteurHorairePompe}</td>
            </tr>
            <tr>
              <td>Index Compteur Eau Pompe</td>
              <td>${item.indexCompteurEauPompeUnit}</td>
              <td>${item.indexCompteurEauPompe}</td>
            </tr>
            <tr>
              <td>Index Compteur Électricité</td>
              <td>${item.indexCompteurElectriciteUnit}</td>
              <td>${item.indexCompteurElectricite}</td>
            </tr>
            <tr>
              <td>Solde Compte Epargne Asufor</td>
              <td>${item.soldeCompteEpargneAsuforUnit}</td>
              <td>${item.soldeCompteEpargneAsufor}</td>
            </tr>
            <tr>
              <td>Niveau Statique Avant Pompage</td>
              <td>${item.niveauStatiqueAvPompageUnit}</td>
              <td>${item.niveauStatiqueAvPompage}</td>
            </tr>
          `);
        });
      } else {
        printWindow.document.write(`
          <tr><td colspan="3">Aucune donnée disponible</td></tr>
        `);
      }

      printWindow.document.write("</table>");
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      toast.success("Rapport de Relevés Indicateurs imprimé avec succès.");
    } catch (error) {
      console.error("Échec de l'impression:", error);
      toast.error("Échec de l'impression du rapport de relevés indicateurs. Veuillez réessayer.");
    }
  };

  const columnsRelevesInd = [
    {
      field: "indexCompteurHorairePompe",
      headerName: "Index Compteur Horaire Pompe",
      flex: 1,
    },
    {
      field: "indexCompteurEauPompe",
      headerName: "Index Compteur Eau Pompe",
      flex: 1,
    },
    {
      field: "indexCompteurElectricite",
      headerName: "Index Compteur Électricité",
      flex: 1,
    },
    {
      field: "soldeCompteEpargneAsufor",
      headerName: "Solde Compte Epargne Asufor",
      flex: 1,
    },
    {
      field: "niveauStatiqueAvPompage",
      headerName: "Niveau Statique Avant Pompage",
      flex: 1,
    },
  ];
  
    const CustomNoRowsOverlay = () => (
	  <GridOverlay>
		<Typography>Aucune donnée disponible</Typography>
	  </GridOverlay>
	);

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Liste Relevés Indicateurs
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="secondary"
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginTop: "1px",
              marginRight: "5px",
            }}
            onClick={handlePrint}
            disabled={isFetchingRelevesInd || !isDataReadyRelevesInd}
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Télécharger le rapport de relevés indicateurs
          </Button>
          <TextField
            id="start-date"
            label="Période de début"
            type="date"
            value={convertToYYYYMMDD(startDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setStartDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="end-date"
            label="Période de fin"
            type="date"
            value={convertToYYYYMMDD(endDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setEndDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </Box>
      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={aggregatedRelevesIndicateurs}
          columns={columnsRelevesInd}
          getRowId={(row) => row.id}
          loading={isFetchingRelevesInd}
		  components={{
			NoRowsOverlay: CustomNoRowsOverlay,
		  }}		  
        />
      </Box>
    </Box>
  );
};

export default RelevesIndicateursList;


// import React, { useState, useEffect, useCallback } from "react";
// import { DataGrid } from "@mui/x-data-grid";
// import { DownloadOutlined } from "@mui/icons-material";
// import { Box, Button, useTheme, TextField, Typography } from "@mui/material";
// import axios from "axios";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const getFirstDayOfMonth = () => {
//   const currentDate = new Date();
//   currentDate.setUTCDate(1); // Set to the 1st day of the current month in UTC
//   const firstDay = currentDate.toISOString().split("T")[0];
//   return firstDay;
// };

// const getLastDayOfMonth = () => {
//   const currentDate = new Date();
//   const lastDay = new Date(
//     Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth() + 1, 0)
//   );
//   const lastDayFormatted = lastDay.toISOString().split("T")[0];
//   return lastDayFormatted;
// };

// const RelevesIndicateursList = () => {
//   const theme = useTheme();
//   const [aggregatedRelevesIndicateurs, setAggregatedRelevesIndicateurs] =
//     useState([]);
//   const [startDate, setStartDate] = useState(getFirstDayOfMonth());
//   const [endDate, setEndDate] = useState(getLastDayOfMonth());
//   const [isFetchingRelevesInd, setIsFetchingRelevesInd] = useState(false);
//   const [isDataReadyRelevesInd, setIsDataReadyRelevesInd] = useState(false);

//   const fetchAggregatedDataRelevesInd = useCallback(async () => {
//     if (!startDate || !endDate) {
//       console.error("Veuillez sélectionner une plage de dates.");
//       return;
//     }
//     setIsFetchingRelevesInd(true);
//     try {
//       const response = await axios.get(
//         "https://gestionforage.terangapp.com/api/relevesindicateurs",
//         {
//           params: { startDate, endDate },
//         }
//       );
//       const dataWithIds = response.data.map((item, index) => ({
//         ...item,
//         id: index + 1,
//       }));
//       setAggregatedRelevesIndicateurs(dataWithIds);
//       setIsDataReadyRelevesInd(true);
//     } catch (error) {
//       console.error("Failed to fetch aggregated releves indicateurs:", error);
//       toast.error(
//         "Failed to fetch aggregated releves indicateurs. Please try again."
//       );
//       setIsDataReadyRelevesInd(false);
//     }
//     setIsFetchingRelevesInd(false);
//   }, [startDate, endDate]);

//   useEffect(() => {
//     fetchAggregatedDataRelevesInd();
//   }, [startDate, endDate, fetchAggregatedDataRelevesInd]);

//   const handlePrint = async () => {
//     if (!isDataReadyRelevesInd) {
//       toast.error(
//         "Les données ne sont pas prêtes. Veuillez sélectionner une plage de dates et réessayer."
//       );
//       return;
//     }

//     try {
//       const printWindow = window.open("", "_blank");
//       printWindow.document.write(`
//         <html>
//         <head>
//           <title>Rapport de Relevés Indicateurs</title>
//           <style>
//             body { font-family: Arial, sans-serif; margin: 60px; }
//             .header { display: flex; justify-content: space-between; align-items: center; }
//             .header p { margin: 0; }
//             h1 { text-align: center; }
//             h2 { text-align: left; }
//             table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
//             th, td { border: 1px solid #000; padding: 8px; }
//             th { background-color: #f2f2f2; }
//           </style>
//         </head>
//         <body>
//       `);

//       printWindow.document.write(`
//         <div class="header">
//           <div><p>COPIFOR DE DAROU NGARAF</p></div>
//           <div><p>Période du ${startDate} au ${endDate}</p></div>
//         </div>
//       `);

//       printWindow.document.write(
//         "<h1>Rapport Mensuel de relevés indicateurs</h1>"
//       );
//       printWindow.document.write(
//         "<h2>Tableau de relevés indicateurs du mois</h2>"
//       );
//       printWindow.document.write("<table border='1'>");
//       printWindow.document.write(`
//         <tr>
//           <th>Designation</th>
//           <th>Unités</th>
//           <th>Valeur</th>
//         </tr>
//       `);

//       if (aggregatedRelevesIndicateurs.length > 0) {
//         aggregatedRelevesIndicateurs.forEach((item) => {
//           printWindow.document.write(`
//             <tr>
//               <td>Index Compteur Horaire Pompe</td>
//               <td>${item.indexCompteurHorairePompeUnit}</td>
//               <td>${item.indexCompteurHorairePompe}</td>
//             </tr>
//             <tr>
//               <td>Index Compteur Eau Pompe</td>
//               <td>${item.indexCompteurEauPompeUnit}</td>
//               <td>${item.indexCompteurEauPompe}</td>
//             </tr>
//             <tr>
//               <td>Index Compteur Électricité</td>
//               <td>${item.indexCompteurElectriciteUnit}</td>
//               <td>${item.indexCompteurElectricite}</td>
//             </tr>
//             <tr>
//               <td>Solde Compte Epargne Asufor</td>
//               <td>${item.soldeCompteEpargneAsuforUnit}</td>
//               <td>${item.soldeCompteEpargneAsufor}</td>
//             </tr>
//             <tr>
//               <td>Niveau Statique Avant Pompage</td>
//               <td>${item.niveauStatiqueAvPompageUnit}</td>
//               <td>${item.niveauStatiqueAvPompage}</td>
//             </tr>
//           `);
//         });
//       } else {
//         printWindow.document.write(`
//           <tr><td colspan="3">Aucune donnée disponible</td></tr>
//         `);
//       }

//       printWindow.document.write("</table>");
//       printWindow.document.write("</body></html>");
//       printWindow.document.close();
//       printWindow.print();
//       toast.success("Rapport de Relevés Indicateurs imprimé avec succès.");
//     } catch (error) {
//       console.error("Failed to print:", error);
//       toast.error(
//         "Échec de l'impression du rapport de relevés indicateurs. Veuillez réessayer."
//       );
//     }
//   };

//   const columnsRelevesInd = [
//     {
//       field: "indexCompteurHorairePompe",
//       headerName: "Index Compteur Horaire Pompe",
//       flex: 1,
//     },
//     {
//       field: "indexCompteurEauPompe",
//       headerName: "Index Compteur Eau Pompe",
//       flex: 1,
//     },
//     {
//       field: "indexCompteurElectricite",
//       headerName: "Index Compteur Électricité",
//       flex: 1,
//     },
//     {
//       field: "soldeCompteEpargneAsufor",
//       headerName: "Solde Compte Epargne Asufor",
//       flex: 1,
//     },
//     {
//       field: "niveauStatiqueAvPompage",
//       headerName: "Niveau Statique Avant Pompage",
//       flex: 1,
//     },
//   ];

//   return (
//     <Box m="20px">
//       <Box
//         display="flex"
//         justifyContent="space-between"
//         alignItems="center"
//         mb={2}
//       >
//         <Box display="flex" justifyContent="space-between" alignItems="center">
//           <Typography variant="h4" component="h1" gutterBottom>
//             Liste Relevés Indicateurs
//           </Typography>
//         </Box>
//         <Box display="flex" alignItems="center">
//           <Button
//             variant="contained"
//             color="secondary"
//             style={{
//               fontSize: "14px",
//               fontWeight: "bold",
//               padding: "10px 20px",
//               marginTop: "1px",
//               marginRight: "5px",
//             }}
//             onClick={handlePrint}
//             disabled={isFetchingRelevesInd || !isDataReadyRelevesInd}
//           >
//             <DownloadOutlined sx={{ mr: "10px" }} />
//             Télécharger le rapport de relevés indicateurs
//           </Button>
//           <TextField
//             id="start-date"
//             label="Période de début"
//             type="date"
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//           />
//           <TextField
//             id="end-date"
//             label="Période de fin"
//             type="date"
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//           />
//         </Box>
//       </Box>
//       <Box
//         mt="20px"
//         height="75vh"
//         sx={{
//           "& .MuiDataGrid-root": {
//             border: "none",
//           },
//           "& .MuiDataGrid-cell": {
//             borderBottom: "none",
//           },
//           "& .MuiDataGrid-virtualScroller": {
//             backgroundColor: theme.palette.primary.light,
//           },
//           "& .MuiDataGrid-footerContainer": {
//             borderTop: "none",
//             backgroundColor: theme.palette.background.alt,
//             color: theme.palette.secondary[100],
//           },
//           "& .MuiCheckbox-root": {
//             color: `${theme.palette.secondary[200]} !important`,
//           },
//         }}
//       >
//         <DataGrid
//           rows={aggregatedRelevesIndicateurs}
//           columns={columnsRelevesInd}
//           getRowId={(row) => row.id}
//           loading={isFetchingRelevesInd}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default RelevesIndicateursList;
