import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Container,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';

const AjouterConsommation = () => {
  const getDuePaymentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    return new Date(year, month, 10).toISOString().split("T")[0];
  };

  const mode = useSelector((state) => state.global.mode);
  const [clients, setClients] = useState([]);
  const [villages, setVillages] = useState([]);
  const [selectedVillage, setSelectedVillage] = useState("");
  const [waterBillRefNumber, setWaterBillRefNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [region, setRegion] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [clientId, setClientId] = useState(null);
  const [currentIndex, setCurrentIndex] = useState("");
  const [previousIndex, setPreviousIndex] = useState(0);
  const [price, setPrice] = useState(200);
  const [paidAmount, setPaidAmount] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState("impayé");
  const [finePrice, setFinePrice] = useState(200);
  const [duePaymentDate, setDuePaymentDate] = useState(getDuePaymentDate());
  const [paidDate, setPaidDate] = useState(null);
  const [typeBranchement, setTypeBranchement] = useState("");
  const [isAudioSearchEnabled, setIsAudioSearchEnabled] = useState(false);
  const [audioText, setAudioText] = useState("");

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const [selectedDate, setSelectedDate] = useState(getCurrentDate());

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get("https://gestionforage.terangapp.com/api/clients");
        setClients(response.data);
        // Extract unique villages
        const uniqueVillages = [...new Set(response.data.map(client => client.village))];
        setVillages(uniqueVillages);
      } catch (error) {
        console.error("Échec de la récupération des clients :", error);
      }
    };

    fetchClients();
  }, []);

  useEffect(() => {
    if (selectedVillage) {
      // Filter clients based on selected village
      const filteredClients = clients.filter(client => client.village === selectedVillage);
      // Update waterBillRefNumber options
      // ...
    }
  }, [selectedVillage, clients]);

  const handleVillageChange = (e) => {
    setSelectedVillage(e.target.value);
  };

  const handleWaterBillRefNumberChange = async (e) => {
    const selectedRefNumber = e.target.value;
    setWaterBillRefNumber(selectedRefNumber);
    const client = clients.find(
      (c) => c.waterBillRefNumber === selectedRefNumber
    );
    if (client) {
      setFirstName(client.firstName);
      setLastName(client.lastName);
      setCountry(client.country);
      setState(client.state);
      setRegion(client.region);
      setPhoneNumber(client.phoneNumber);
      setClientId(client.id);
      setTypeBranchement(client.type);

      try {
        const response = await axios.get(
          `https://gestionforage.terangapp.com/api/consommations/last/${selectedRefNumber}`
        );
        const lastConsommation = response.data;
        if (lastConsommation) {
          setPreviousIndex(lastConsommation.currentIndex);
        } else {
          setPreviousIndex(0);
        }
      } catch (error) {
        console.error(
          "Échec de la récupération de la dernière consommation :",
          error
        );
        setPreviousIndex(0);
      }
    }
  };
  
  const handleAudioSearch = () => {
    if (!selectedVillage) {
      toast.info("Veuillez sélectionner un village avant d'effectuer une recherche vocale.");
      return;
    }

    if (!isAudioSearchEnabled) {
      const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognition.lang = 'fr-FR'; // Adjust language as needed
      recognition.start();

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        console.log("Raw Transcript:", transcript); // Log raw transcript
        setAudioText(transcript);

        // Normalize transcript to handle numbers and common errors
        const normalizedTranscript = transcript
          .replace(/un|une|un(e)?/gi, '1')
          .replace(/deux|deuxièm(e)?/gi, '2')
          .replace(/trois|troisième/gi, '3')
          .replace(/quatre|quatrième/gi, '4')
          .replace(/cinq|cinquième/gi, '5')
          .replace(/six|sixième/gi, '6')
          .replace(/sept|septième/gi, '7')
          .replace(/huit|huitième/gi, '8')
          .replace(/neuf|neuvième/gi, '9')
          .replace(/dix|dixième/gi, '10')
          .replace(/[^\d]/g, '') // Remove all non-numeric characters
          .trim();

        console.log("Normalized Transcript:", normalizedTranscript); // Log normalized transcript
        searchClientById(normalizedTranscript);
      };

      recognition.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
        toast.error("Erreur lors de la reconnaissance vocale.");
      };
    } else {
      // Handle stopping of speech recognition if needed
      // For now, it just toggles the state
    }
    setIsAudioSearchEnabled(!isAudioSearchEnabled);
  };

  const searchClientById = async (id) => {
    try {
      const response = await axios.get(`https://gestionforage.terangapp.com/api/clients/${id}`);
      const client = response.data;

      if (client) {
        setWaterBillRefNumber(client.waterBillRefNumber);
        setFirstName(client.firstName);
        setLastName(client.lastName);
        setCountry(client.country);
        setState(client.state);
        setRegion(client.region);
        setPhoneNumber(client.phoneNumber);
        setClientId(client.id);
        setTypeBranchement(client.type);

        // Fetch last consumption
        try {
          const lastConsommationResponse = await axios.get(
            `https://gestionforage.terangapp.com/api/consommations/last/${client.waterBillRefNumber}`
          );
          const lastConsommation = lastConsommationResponse.data;
          setPreviousIndex(lastConsommation ? lastConsommation.currentIndex : 0);
        } catch (error) {
          console.error("Failed to fetch last consumption:", error);
          setPreviousIndex(0); // Default value in case of error
        }
      } else {
        toast.info("Aucun client trouvé avec l'identifiant donné.");
      }
    } catch (error) {
      console.error("Erreur lors de la recherche du client:", error);
      toast.error("Erreur lors de la recherche du client.");
    }
  };

  const calculateConsommation = (currentIndex, previousIndex) => {
    return currentIndex - previousIndex;
  };

  const calculateDuePayment = (consommation, price) => {
    return consommation * price;
  };

  const calculateLatePayment = (paidDate, duePaymentDate) => {
    if (!paidDate || !duePaymentDate) return 0;
    const paidDateObj = new Date(paidDate);
    const duePaymentDateObj = new Date(duePaymentDate);
    const differenceInTime = paidDateObj - duePaymentDateObj;
    return Math.floor(differenceInTime / (1000 * 3600 * 24));
  };

  const calculateFine = (latePayment, finePrice) => {
    return latePayment > 0 ? latePayment * finePrice : 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!waterBillRefNumber || !selectedDate || !currentIndex) {
      toast.error("Veuillez remplir tous les champs obligatoires.");
      return;
    }

    const consommation = calculateConsommation(currentIndex, previousIndex);
    const duePayment = calculateDuePayment(consommation, price);
    const latePayment = calculateLatePayment(paidDate, duePaymentDate);
    const fine = calculateFine(latePayment, finePrice);

    const consommationData = {
      waterBillRefNumber,
      selectedDate,
      currentIndex: parseFloat(currentIndex),
      previousIndex: parseFloat(previousIndex),
      consommation,
      price: parseFloat(price),
      duePayment,
      paidAmount: parseFloat(paidAmount),
      paymentStatus,
      finePrice: parseFloat(finePrice),
      fine,
      duePaymentDate,
      latePayment,
      paidDate,
      firstName,
      lastName,
      country,
      state,
      region,
      phoneNumber,
      clientId,
      typeBranchement,
    };

    try {
      const response = await fetch("https://gestionforage.terangapp.com/api/consommations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(consommationData),
      });
      const data = await response.json();
      console.log("Response:", data);

      setWaterBillRefNumber("");
      setFirstName("");
      setLastName("");
      setCountry("");
      setState("");
      setRegion("");
      setPhoneNumber("");
      setCurrentIndex("");
      setPreviousIndex(0);
      setPrice(200);
      setPaidAmount(0);
      setPaymentStatus("impayé");
      setFinePrice(200);
      setDuePaymentDate(getDuePaymentDate());
      setPaidDate(null);
      setClientId(null);
      setTypeBranchement("");
      setSelectedDate(getCurrentDate());

      toast.success("Formulaire soumis avec succès !");
    } catch (error) {
      console.error(
        "Il y a eu une erreur lors de la soumission du formulaire !",
        error
      );
    }
  };

  const priceOptions = [0, 50, 100, 150, 175, 200, 250, 300, 400, 500];
  const finePriceOptions = [0, 50, 100, 150, 175, 200, 250, 300, 400, 500];

  const isFormComplete = 
    waterBillRefNumber && 
    selectedDate && 
    currentIndex && 
    firstName && 
    lastName && 
    country && 
    state && 
    region && 
    phoneNumber;

  return (
    <Container maxWidth="md" sx={{ mt: 0 }}>
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth required>
              <InputLabel>Village</InputLabel>
              <Select value={selectedVillage} onChange={handleVillageChange}>
                {villages.map((village, index) => (
                  <MenuItem key={index} value={village}>{village}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              label="Numéro de facture"
              fullWidth
              required
              value={waterBillRefNumber}
              onChange={handleWaterBillRefNumberChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={isAudioSearchEnabled ? "Désactiver la recherche vocale" : "Activer la recherche vocale"}>
                      <IconButton 
                        onClick={handleAudioSearch}
                        sx={{ color: isAudioSearchEnabled ? 'green' : 'inherit' }}
                        disabled={isFormComplete}
                      >
                        {isAudioSearchEnabled ? <MicOffIcon /> : <MicIcon />}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            >
              {clients
                .filter(client => client.village === selectedVillage)
                .map((client, index) => (
                  <MenuItem key={index} value={client.waterBillRefNumber}>
                    {client.waterBillRefNumber}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
           <Grid item xs={12} sm={4}>
            <TextField
              label="Prénom"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Nom"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Pays"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Région"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              required
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Commune"
              value={state}
              onChange={(e) => setState(e.target.value)}
              required
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Type Branchement"
              value={typeBranchement}
              onChange={(e) => setState(e.target.value)}
              required
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Numéro de téléphone"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Index actuel"
              type="number"
              value={currentIndex}
              onChange={(e) => setCurrentIndex(e.target.value)}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Index précédent"
              type="number"
              value={previousIndex}
              onChange={(e) => setPreviousIndex(e.target.value)}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Consommation"
              type="number"
              value={calculateConsommation(currentIndex, previousIndex)}
              required
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth required>
              <InputLabel>Prix</InputLabel>
              <Select value={price} onChange={(e) => setPrice(e.target.value)}>
                {priceOptions.map((priceOption) => (
                  <MenuItem key={priceOption} value={priceOption}>
                    {priceOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Montant dû"
              type="number"
              value={calculateDuePayment(
                calculateConsommation(currentIndex, previousIndex),
                price
              )}
              required
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Montant payé"
              type="number"
              value={paidAmount}
              onChange={(e) => setPaidAmount(e.target.value)}
              required
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Statut de paiement</InputLabel>
              <Select
                value={paymentStatus}
                onChange={(e) => setPaymentStatus(e.target.value)}
                required
                disabled
              >
                <MenuItem value="payé">Payé</MenuItem>
                <MenuItem value="impayé">Impayé</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Date"
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              required
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Date d'échéance de paiement"
              type="date"
              value={duePaymentDate}
              onChange={(e) => setDuePaymentDate(e.target.value)}
              required
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth required>
              <InputLabel>Prix de l'amende</InputLabel>
              <Select
                value={finePrice}
                onChange={(e) => setFinePrice(e.target.value)}
              >
                {finePriceOptions.map((fineOption) => (
                  <MenuItem key={fineOption} value={fineOption}>
                    {fineOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Date de paiement"
              type="date"
              value={paidDate}
              onChange={(e) => setPaidDate(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
			  disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Jours de retard"
              type="number"
              value={calculateLatePayment(paidDate, duePaymentDate)}
              required
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Amende"
              type="number"
              value={calculateFine(
                calculateLatePayment(paidDate, duePaymentDate),
                finePrice
              )}
              required
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color={mode === "dark" ? "secondary" : "primary"}
              size="large"
              sx={{
                width: "100%",
              }}
            >
              Ajouter
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AjouterConsommation;
