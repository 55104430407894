import React, { useEffect, useState } from "react";
import axios from "axios";
import { ResponsivePie } from "@nivo/pie";
import { Box, Typography, useTheme } from "@mui/material";

const BreakdownChart = ({ isDashboard = false }) => {
  const [data, setData] = useState([]);
  const [yearlySalesTotal, setYearlySalesTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://localhost:3001/api/consommations"
        );
        const consommations = response.data;

        // Process data to fit the pie chart format
        const salesByWaterBillRefNumber = consommations.reduce((acc, item) => {
          const { waterBillRefNumber, paidAmount } = item;
          if (!acc[waterBillRefNumber]) acc[waterBillRefNumber] = 0;
          acc[waterBillRefNumber] += Number(paidAmount); // Ensure paidAmount is treated as a number
          return acc;
        }, {});

        const colors = [
          theme.palette.secondary[500],
          theme.palette.secondary[300],
          theme.palette.secondary[300],
          theme.palette.secondary[500],
        ];

        const formattedData = Object.entries(salesByWaterBillRefNumber).map(
          ([waterBillRefNumber, sales], i) => ({
            id: waterBillRefNumber,
            label: waterBillRefNumber,
            value: Number(sales), // Ensure the value is a number
            color: colors[i % colors.length],
          })
        );

        // Calculate total sales
        const totalSales = consommations.reduce(
          (acc, item) => acc + Number(item.paidAmount), // Ensure paidAmount is treated as a number
          0
        );

        setData(formattedData);
        setYearlySalesTotal(totalSales);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [theme.palette.secondary]);

  if (isLoading) return "Pas de données disponible.";

  return (
    <Box
      height={isDashboard ? "400px" : "100%"}
      width="100%"
      minHeight={isDashboard ? "325px" : undefined}
      minWidth={isDashboard ? "325px" : undefined}
      position="relative"
    >
      <ResponsivePie
        data={data}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: theme.palette.secondary[200],
              },
            },
            legend: {
              text: {
                fill: theme.palette.secondary[200],
              },
            },
            ticks: {
              line: {
                stroke: theme.palette.secondary[200],
                strokeWidth: 1,
              },
              text: {
                fill: theme.palette.secondary[200],
              },
            },
          },
          legends: {
            text: {
              fill: theme.palette.secondary[200],
            },
          },
          tooltip: {
            container: {
              color: theme.palette.primary.main,
            },
          },
        }}
        colors={{ datum: "data.color" }}
        margin={
          isDashboard
            ? { top: 40, right: 80, bottom: 100, left: 50 }
            : { top: 40, right: 80, bottom: 80, left: 80 }
        }
        sortByValue={true}
        innerRadius={0.45}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        enableArcLinkLabels={!isDashboard}
        arcLinkLabelsTextColor={theme.palette.secondary[200]}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: isDashboard ? 20 : 0,
            translateY: isDashboard ? 50 : 56,
            itemsSpacing: 0,
            itemWidth: 85,
            itemHeight: 18,
            itemTextColor: "#999",
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: theme.palette.primary[500],
                },
              },
            ],
          },
        ]}
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        color={theme.palette.secondary[400]}
        textAlign="center"
        pointerEvents="none"
        sx={{
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography variant="h6">
          {!isDashboard && "Total:"} $
          {typeof yearlySalesTotal === "number"
            ? yearlySalesTotal.toFixed(2)
            : 0}
        </Typography>
      </Box>
    </Box>
  );
};

export default BreakdownChart;
