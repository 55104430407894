// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   mode: 'dark',
//   userId: null,
//   username: null,
// };

// export const globalSlice = createSlice({
//   name: "global",
//   initialState,
//   reducers: {
//     setMode: (state) => {
//       state.mode = state.mode === 'light' ? "dark" : 'light';
//     },
//     setUserId: (state, action) => {
//       state.userId = action.payload;
//     },
//     clearUserId: (state) => {
//       state.userId = null;
//     },
//     setUsername: (state, action) => {
//       state.username = action.payload;
//     },
//   },
// });

// export const { setMode, setUserId, setUsername, clearUserId } = globalSlice.actions;

// export default globalSlice.reducer;

// state/index.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "dark",
  user: null,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setUser: (state, action) => {
      state.user = action.payload;
      console.log("User data set in Redux:", state.user); // Log user data
    },
    clearUser: (state) => {
      state.user = null;
      console.log("User data cleared from Redux"); // Log when user data is cleared
    },
  },
});

export const { setMode, setUser, clearUser } = globalSlice.actions;

export default globalSlice.reducer;
