import React, { useState, useEffect, useCallback } from "react";
import { DataGrid, GridActionsCellItem, GridOverlay, } from "@mui/x-data-grid";
import { DownloadOutlined, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Convertir une date de yyyy-MM-dd en dd-MM-yyyy
const formatDateToDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// Convertir une date de dd-MM-yyyy en yyyy-MM-dd
const convertToYYYYMMDD = (dateString) => {
  const [day, month, year] = dateString.split("-");
  return `${year}-${month}-${day}`;
};

// Récupérer le premier jour du mois précédent en dd-MM-yyyy
const getFirstDayOfMonth = () => {
  const currentDate = new Date();
  currentDate.setUTCMonth(currentDate.getUTCMonth() - 1); // Met au mois précédent en UTC
  currentDate.setUTCDate(1); // Met au 1er jour du mois précédent en UTC
  const firstDay = currentDate.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(firstDay); // Convertir en dd-MM-yyyy
};

// Récupérer le dernier jour du mois précédent en dd-MM-yyyy
const getLastDayOfMonth = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0)
  );
  const lastDayFormatted = lastDay.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(lastDayFormatted); // Convertir en dd-MM-yyyy
};

const DonneesTechniquesList = () => {
  const theme = useTheme();
  const [donnees, setDonnees] = useState([]);
  const [aggregatedConsommations, setAggregatedConsommations] = useState([]);
  const [summedDonnees, setSummedDonnees] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [startDate, setStartDate] = useState(getFirstDayOfMonth()); // dd-MM-yyyy
  const [endDate, setEndDate] = useState(getLastDayOfMonth()); // dd-MM-yyyy
  const [isFetching, setIsFetching] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);

  const fetchDonnees = useCallback(async () => {
    if (!startDate || !endDate) {
      toast.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetching(true);
    try {
      const response = await axios.get("https://gestionforage.terangapp.com/api/donnees", {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
      });
      setDonnees(response.data);
      setIsLoading(false);
      toast.success("Données récupérées avec succès.");
    } catch (error) {
      console.error("Échec de la récupération des données:", error);
      setIsLoading(false);
      toast.error("Échec de la récupération des données. Veuillez réessayer.");
    }
  }, [startDate, endDate]);

  const fetchAggregatedData = useCallback(async () => {
    if (!startDate || !endDate) {
      toast.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetching(true);
    try {
      const [aggConsResponse, summedDonneesResponse] = await Promise.all([
        axios.get("https://gestionforage.terangapp.com/api/consommations/aggregated", {
          params: {
            startDate: startDate,
            endDate: endDate,
          },
        }),
        axios.get("https://gestionforage.terangapp.com/api/donnees/summed", {
          params: {
            startDate: startDate,
            endDate: endDate,
          },
        }),
      ]);
      setAggregatedConsommations(aggConsResponse.data);
      setSummedDonnees(summedDonneesResponse.data);
      setIsDataReady(true);
    } catch (error) {
      console.error("Échec de la récupération des données agrégées:", error);
      toast.error("Échec de la récupération des données agrégées. Veuillez réessayer.");
      setIsDataReady(false);
    }
    setIsFetching(false);
  }, [startDate, endDate]); // Ajoutez startDate et endDate comme dépendances

  useEffect(() => {
    fetchDonnees();
    fetchAggregatedData();
  }, [fetchDonnees, fetchAggregatedData, startDate, endDate]); // Ajoutez startDate et endDate comme dépendances

  const handlePrint = async () => {
    if (!isDataReady) {
      toast.error("Les données ne sont pas prêtes. Veuillez sélectionner une plage de dates et réessayer.");
      return;
    }

    try {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
        <head>
          <title>Rapport Technique</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 60px; }
            .header { display: flex; justify-content: space-between; align-items: center; }
            .header p { margin: 0; }
            h1 { text-align: center; }
            h2 { text-align: left; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            th, td { border: 1px solid #000; padding: 8px; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
      `);

      printWindow.document.write(`
        <div class="header">
          <div><p>COPIFOR DE DAROU</p></div>
          <div><p>Période du ${startDate} au ${endDate}</p></div>
        </div>
      `);

      printWindow.document.write("<h1>Rapport Technique</h1>");
      printWindow.document.write("<h2>Tableau de consommations</h2>");
      printWindow.document.write("<table border='1'>");
      printWindow.document.write(`
        <tr>
          <th>Type Branchement</th>
          <th>Nombre</th>
          <th>Nombre m3 distribué</th>
          <th>Nombre m3 payé</th>
          <th>Nombre m3 impayé </th>
        </tr>
      `);

	let totalNombres = 0;
	let totalNombreMd = 0;
	let totalNombresMp = 0;
	let totalNombresMi = 0;

	if (aggregatedConsommations.length > 0) {
	  aggregatedConsommations.forEach((item) => {
		totalNombres += parseFloat(item.nombres) || 0;
		totalNombreMd += parseFloat(item.nombreMd) || 0;
		totalNombresMp += parseFloat(item.nombresMp) || 0;
		totalNombresMi += parseFloat(item.nombresMi) || 0;

		printWindow.document.write(`
		  <tr>
			<td class="text-left">${item.typeBranchement}</td>
			<td>${item.nombres}</td>
			<td>${item.nombreMd}</td>
			<td>${item.nombresMp}</td>
			<td>${item.nombresMi}</td>
		  </tr>
		`);
	  });

	  // Add the totals row
	  printWindow.document.write(`
		<tr>
		  <td class="text-left"><strong>Total</strong></td>
		  <td><strong>${totalNombres.toLocaleString()}</strong></td>
		  <td><strong>${totalNombreMd.toLocaleString()}</strong></td>
		  <td><strong>${totalNombresMp.toLocaleString()}</strong></td>
		  <td><strong>${totalNombresMi.toLocaleString()}</strong></td>
		</tr>
	  `);
      } else {
        printWindow.document.write(`
          <tr><td colspan="5">Aucune donnée disponible</td></tr>
        `);
      }

      printWindow.document.write("</table>");
      printWindow.document.write("<h2>Données Techniques</h2>");
      printWindow.document.write("<table border='1'>");
      printWindow.document.write(`
        <tr>
          <th>Index SP Début mois</th>
          <th>Index SP Fin mois</th>
          <th>Nombre m3 pompé</th>
          <th>Nombre m3 distribué</th>
          <th>Stock Disponible</th>
          <th>Perte sur le Réseau</th>
        </tr>
      `);

      if (summedDonnees) {
        printWindow.document.write(`
          <tr>
            <td>${String(summedDonnees.indexSpdm)}</td>
            <td>${String(summedDonnees.indexSpfm)}</td>
            <td>${String(summedDonnees.nombreMp)}</td>
            <td>${String(summedDonnees.nombreMd)}</td>
            <td>${String(summedDonnees.stockDisponible)}</td>
            <td>${String(summedDonnees.perteReseau)}</td>
          </tr>
        `);
      } else {
        printWindow.document.write(`
          <tr><td colspan="6">Aucune donnée disponible</td></tr>
        `);
      }

      printWindow.document.write("</table>");
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      toast.success("Rapport technique imprimé avec succès.");
    } catch (error) {
      console.error("Échec de l'impression:", error);
      toast.error("Échec de l'impression du rapport technique. Veuillez réessayer.");
    }
  };


  const handleEdit = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setSelectedRow((prev) => ({ ...prev, [name]: value }));
  };

  const handleEditSave = async () => {
    try {
      const response = await axios.put(
        `https://gestionforage.terangapp.com/api/donnees/${selectedRow.id}`,
        selectedRow
      );

      if (response.status !== 200) {
        throw new Error("Échec de la mise à jour des données");
      }

      setDonnees((prev) =>
        prev.map((item) => (item.id === selectedRow.id ? selectedRow : item))
      );
      setOpenEditDialog(false);
    } catch (error) {
      console.error("Échec de la mise à jour des données:", error);
    }
  };

  const columns = [
    { field: "selectedDate", headerName: "Date", flex: 1 },
    { field: "indexSpdm", headerName: "Index SP Début mois", flex: 1 },
    { field: "indexSpfm", headerName: "Index SP Fin mois", flex: 1 },
    { field: "nombreMp", headerName: "Nombre m3 pompé", flex: 1 },
    { field: "nombreMd", headerName: "Nombre m3 distribué", flex: 1 },
    { field: "stockDisponible", headerName: "Stock Disponible", flex: 1 },
    { field: "perteReseau", headerName: "Perte sur le Réseau", flex: 1 },
    { field: "observations", headerName: "Observations", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleEdit(params.row)}
        />,
      ],
    },
  ];
  
  const CustomNoRowsOverlay = () => (
	  <GridOverlay>
		<Typography>Aucune donnée disponible</Typography>
	  </GridOverlay>
	);


  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Liste des Données Techniques
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="secondary"
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginTop: "1px",
              marginRight: "5px",
            }}
            onClick={handlePrint}
            disabled={isFetching || !isDataReady}
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Télécharger le rapport technique
          </Button>
          <TextField
            id="start-date"
            label="Période de début"
            type="date"
            value={convertToYYYYMMDD(startDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setStartDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="end-date"
            label="Période de fin"
            type="date"
            value={convertToYYYYMMDD(endDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setEndDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </Box>
      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
		<DataGrid
		  loading={isLoading}
		  rows={donnees}
		  columns={columns}
		  components={{
			NoRowsOverlay: CustomNoRowsOverlay,
		  }}
		/>

      </Box>
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Modifier la Catégorie</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Modifiez les champs nécessaires et sauvegardez.
          </DialogContentText>
          {selectedRow && (
            <>
              <TextField
                margin="dense"
                name="indexSpdm"
                label="Index SP Début mois"
                type="number"
                fullWidth
                value={selectedRow.indexSpdm}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                name="indexSpfm"
                label="Index SP Fin mois"
                type="number"
                fullWidth
                value={selectedRow.indexSpfm}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                name="nombreMp"
                label="Nombre m3 pompé"
                type="number"
                fullWidth
                value={selectedRow.nombreMp}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                name="nombreMd"
                label="Nombre m3 distribué"
                type="number"
                fullWidth
                value={selectedRow.nombreMd}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                name="stockDisponible"
                label="Stock Disponible"
                type="number"
                fullWidth
                value={selectedRow.stockDisponible}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                name="perteReseau"
                label="Perte sur le Réseau"
                type="number"
                fullWidth
                value={selectedRow.perteReseau}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                name="observations"
                label="Observations"
                type="text"
                fullWidth
                value={selectedRow.observations}
                onChange={handleEditChange}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)}>Annuler</Button>
          <Button onClick={handleEditSave} color="primary">
            Sauvegarder
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DonneesTechniquesList;
