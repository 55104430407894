// import React, { useState, useEffect } from "react";
// import { Box, ButtonGroup, IconButton, useTheme } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { DataGrid } from "@mui/x-data-grid";
// import Header from "components/Header";
// import CustomColumnMenu from "components/DataGridCustomColumnMenu";
// import axios from "axios";

// const Admin = () => {
//   const theme = useTheme();
//   const [admins, setAdmins] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   const handleEdit = (userId) => {
//     // Logic to navigate to edit user page or open a modal for editing
//     console.log("Edit user with ID:", userId);
//   };

//   const handleDelete = async (id) => {
//     try {
//       await axios.delete(`https://gestionforage.terangapp.com/api/users/${id}`);
//       // Update the admins state by filtering out the deleted user
//       setAdmins(admins.filter((admin) => admin.id !== id));
//     } catch (error) {
//       console.error("Error deleting user:", error);
//     }
//   };

//   const columns = [
//     {
//       field: "id",
//       headerName: "ID",
//       flex: 0.5,
//     },
//     {
//       field: "username",
//       headerName: "Nom",
//       flex: 0.5,
//     },
//     {
//       field: "email",
//       headerName: "E-mail",
//       flex: 1,
//     },
//     {
//       field: "phoneNumber",
//       headerName: "Numéro de téléphone",
//       flex: 0.7,
//       renderCell: (params) => {
//         return params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
//       },
//     },
//     {
//       field: "country",
//       headerName: "Pays",
//       flex: 0.4,
//     },
//     {
//       field: "occupation",
//       headerName: "Occupation",
//       flex: 0.7,
//     },
//     {
//       field: "role",
//       headerName: "Rôle",
//       flex: 0.5,
//     },
//     {
//       field: "status",
//       headerName: "Statut",
//       flex: 0.5,
//       renderCell: (params) => (
//         <ButtonGroup>
//           <IconButton
//             onClick={() => handleEdit(params.row.id)}
//             aria-label="Modifier"
//           >
//             <EditIcon />
//           </IconButton>
//           <IconButton
//             onClick={() => handleDelete(params.row.id)}
//             aria-label="Supprimer"
//           >
//             <DeleteIcon />
//           </IconButton>
//         </ButtonGroup>
//       ),
//     },
//   ];

//   useEffect(() => {
//     const fetchAdmins = async () => {
//       try {
//         const response = await axios.get("https://gestionforage.terangapp.com/api/users");
//         setAdmins(response.data.users);
//         setIsLoading(false);
//       } catch (error) {
//         console.error(
//           "Erreur lors de la récupération des administrateurs :",
//           error
//         );
//         setIsLoading(false);
//       }
//     };

//     fetchAdmins();
//   }, []);

//   return (
//     <Box m="1.5rem 2.5rem">
//       <Header
//         title="ADMINISTRATEURS"
//         subtitle="Gestion des administrateurs et liste des administrateurs"
//       />
//       <Box
//         mt="40px"
//         height="75vh"
//         sx={{
//           "& .MuiDataGrid-root": {
//             border: "none",
//           },
//           "& .MuiDataGrid-cell": {
//             borderBottom: "none",
//           },
//           "& .MuiDataGrid-columnHeaders": {
//             backgroundColor: theme.palette.background.alt,
//             color: theme.palette.secondary[100],
//             borderBottom: "none",
//           },
//           "& .MuiDataGrid-virtualScroller": {
//             backgroundColor: theme.palette.primary.light,
//           },
//           "& .MuiDataGrid-footerContainer": {
//             backgroundColor: theme.palette.background.alt,
//             color: theme.palette.secondary[100],
//             borderTop: "none",
//           },
//           "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//             color: `${theme.palette.secondary[200]} !important`,
//           },
//         }}
//       >
//         <DataGrid
//           loading={isLoading || !admins}
//           rows={admins || []}
//           columns={columns}
//           components={{
//             ColumnMenu: CustomColumnMenu,
//           }}
//         />
//         <DataGrid
//           loading={isLoading || !admins}
//           rows={admins || []}
//           columns={columns}
//           components={{
//             ColumnMenu: CustomColumnMenu,
//           }}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default Admin;

// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   ButtonGroup,
//   IconButton,
//   useTheme,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
// } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { DataGrid } from "@mui/x-data-grid";
// import Header from "components/Header";
// import CustomColumnMenu from "components/DataGridCustomColumnMenu";
// import axios from "axios";

// const Admin = () => {
//   const theme = useTheme();
//   const [admins, setAdmins] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [selectedAdmin, setSelectedAdmin] = useState(null);
//   const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);

//   const handleEdit = (userId) => {
//     // Logic to navigate to edit user page or open a modal for editing
//     console.log("Edit user with ID:", userId);
//     const selected = admins.find((admin) => admin.id === userId);
//     setSelectedAdmin(selected);
//   };

//   const handleDelete = async (id) => {
//     try {
//       await axios.delete(`https://gestionforage.terangapp.com/api/users/${id}`);
//       // Update the admins state by filtering out the deleted user
//       setAdmins(admins.filter((admin) => admin.id !== id));
//       setConfirmDeleteDialog(false);
//     } catch (error) {
//       console.error("Error deleting user:", error);
//     }
//   };

//   const handleCloseDialog = () => {
//     setSelectedAdmin(null);
//     setConfirmDeleteDialog(false);
//   };

//   const columns = [
//     {
//       field: "id",
//       headerName: "ID",
//       flex: 0.5,
//     },
//     {
//       field: "username",
//       headerName: "Nom",
//       flex: 0.5,
//     },
//     {
//       field: "email",
//       headerName: "E-mail",
//       flex: 1,
//     },
//     {
//       field: "phoneNumber",
//       headerName: "Numéro de téléphone",
//       flex: 0.7,
//       renderCell: (params) => {
//         return params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
//       },
//     },
//     {
//       field: "country",
//       headerName: "Pays",
//       flex: 0.4,
//     },
//     {
//       field: "occupation",
//       headerName: "Occupation",
//       flex: 0.7,
//     },
//     {
//       field: "role",
//       headerName: "Rôle",
//       flex: 0.5,
//     },
//     {
//       field: "status",
//       headerName: "Statut",
//       flex: 0.5,
//       renderCell: (params) => (
//         <ButtonGroup>
//           <IconButton
//             onClick={() => handleEdit(params.row.id)}
//             aria-label="Modifier"
//           >
//             <EditIcon />
//           </IconButton>
//           <IconButton
//             onClick={() => handleDelete(params.row.id)}
//             aria-label="Supprimer"
//           >
//             <DeleteIcon />
//           </IconButton>
//         </ButtonGroup>
//       ),
//     },
//   ];

//   useEffect(() => {
//     const fetchAdmins = async () => {
//       try {
//         const response = await axios.get("https://gestionforage.terangapp.com/api/users");
//         setAdmins(response.data.users);
//         setIsLoading(false);
//       } catch (error) {
//         console.error("Error fetching administrators:", error);
//         setIsLoading(false);
//       }
//     };

//     fetchAdmins();
//   }, []);

//   return (
//     <Box m="1.5rem 2.5rem">
//       <Header
//         title="ADMINISTRATEURS"
//         subtitle="Gestion des administrateurs et liste des administrateurs"
//       />
//       <Box
//         mt="40px"
//         height="75vh"
//         sx={{
//           "& .MuiDataGrid-root": {
//             border: "none",
//           },
//           "& .MuiDataGrid-cell": {
//             borderBottom: "none",
//           },
//           "& .MuiDataGrid-columnHeaders": {
//             backgroundColor: theme.palette.background.alt,
//             color: theme.palette.secondary[100],
//             borderBottom: "none",
//           },
//           "& .MuiDataGrid-virtualScroller": {
//             backgroundColor: theme.palette.primary.light,
//           },
//           "& .MuiDataGrid-footerContainer": {
//             backgroundColor: theme.palette.background.alt,
//             color: theme.palette.secondary[100],
//             borderTop: "none",
//           },
//           "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//             color: `${theme.palette.secondary[200]} !important`,
//           },
//         }}
//       >
//         <DataGrid
//           loading={isLoading || !admins}
//           rows={admins || []}
//           columns={columns}
//           components={{
//             ColumnMenu: CustomColumnMenu,
//           }}
//           actions={[
//             {
//               icon: <EditIcon />,
//               onClick: (event, rowData) => handleEdit(rowData.id),
//             },
//             {
//               icon: <DeleteIcon />,
//               onClick: (event, rowData) => setConfirmDeleteDialog(true),
//             },
//           ]}
//         />
//         {/* Confirmation dialog for delete action */}
//         <Dialog open={confirmDeleteDialog} onClose={handleCloseDialog}>
//           <DialogTitle>Confirmation de suppression</DialogTitle>
//           <DialogContent>
//             Êtes-vous sûr de vouloir supprimer cet administrateur ?
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleCloseDialog} color="primary">
//               Annuler
//             </Button>
//             <Button
//               onClick={() => handleDelete(selectedAdmin.id)}
//               color="error"
//             >
//               Supprimer
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </Box>
//     </Box>
//   );
// };

// export default Admin;

// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   ButtonGroup,
//   IconButton,
//   useTheme,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   TextField,
// } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { DataGrid } from "@mui/x-data-grid";
// import Header from "components/Header";
// import CustomColumnMenu from "components/DataGridCustomColumnMenu";
// import axios from "axios";

// const Admin = () => {
//   const theme = useTheme();
//   const [admins, setAdmins] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [selectedAdmin, setSelectedAdmin] = useState(null);
//   const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
//   const [editDialogOpen, setEditDialogOpen] = useState(false);
//   const [editedAdmin, setEditedAdmin] = useState({});

//   const fetchAdmins = async () => {
//     try {
//       const response = await axios.get("https://gestionforage.terangapp.com/api/users");
//       setAdmins(response.data.users);
//       setIsLoading(false);
//     } catch (error) {
//       console.error("Error fetching administrators:", error);
//       setIsLoading(false);
//     }
//   };

//   const handleEdit = (userId) => {
//     const selected = admins.find((admin) => admin.id === userId);
//     setSelectedAdmin(selected);
//     setEditedAdmin(selected);
//     setEditDialogOpen(true);
//   };

//   const handleEditSave = async () => {
//     try {
//       const response = await axios.put(
//         `https://gestionforage.terangapp.com/api/users/${editedAdmin.id}`,
//         editedAdmin
//       );
//       console.log("Response from edit API:", response.data);
//       setEditDialogOpen(false);

//       // Fetch the updated admin list after editing an admin
//       fetchAdmins(); // Assuming fetchAdmins is a function that fetches the admin list
//     } catch (error) {
//       console.error("Error editing user:", error);
//     }
//   };

//   const handleDelete = async (id) => {
//     try {
//       await axios.delete(`https://gestionforage.terangapp.com/api/users/${id}`);
//       // Update the admins state by filtering out the deleted user
//       setAdmins(admins.filter((admin) => admin.id !== id));
//       setConfirmDeleteDialog(false);
//     } catch (error) {
//       console.error("Error deleting user:", error);
//     }
//   };

//   const handleCloseDialogs = () => {
//     setSelectedAdmin(null);
//     setConfirmDeleteDialog(false);
//     setEditDialogOpen(false);
//   };

//   const columns = [
//     {
//       field: "id",
//       headerName: "ID",
//       flex: 0.5,
//     },
//     {
//       field: "username",
//       headerName: "Nom",
//       flex: 0.5,
//     },
//     {
//       field: "email",
//       headerName: "E-mail",
//       flex: 1,
//     },
//     {
//       field: "phoneNumber",
//       headerName: "Numéro de téléphone",
//       flex: 0.7,
//       renderCell: (params) => {
//         return params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
//       },
//     },
//     {
//       field: "country",
//       headerName: "Pays",
//       flex: 0.4,
//     },
//     {
//       field: "occupation",
//       headerName: "Occupation",
//       flex: 0.7,
//     },
//     {
//       field: "role",
//       headerName: "Rôle",
//       flex: 0.5,
//     },
//     {
//       field: "status",
//       headerName: "Statut",
//       flex: 0.5,
//       renderCell: (params) => (
//         <ButtonGroup>
//           <IconButton
//             onClick={() => handleEdit(params.row.id)}
//             aria-label="Modifier"
//           >
//             <EditIcon />
//           </IconButton>
//           <IconButton
//             onClick={() => handleDelete(params.row.id)}
//             aria-label="Supprimer"
//           >
//             <DeleteIcon />
//           </IconButton>
//         </ButtonGroup>
//       ),
//     },
//   ];

//   useEffect(() => {
//     const fetchAdmins = async () => {
//       try {
//         const response = await axios.get("https://gestionforage.terangapp.com/api/users");
//         setAdmins(response.data.users);
//         setIsLoading(false);
//       } catch (error) {
//         console.error("Error fetching administrators:", error);
//         setIsLoading(false);
//       }
//     };

//     fetchAdmins();
//   }, []);

//   return (
//     <Box m="1.5rem 2.5rem">
//       <Header
//         title="ADMINISTRATEURS"
//         subtitle="Gestion des administrateurs et liste des administrateurs"
//       />
//       <Box
//         mt="40px"
//         height="75vh"
//         sx={{
//           "& .MuiDataGrid-root": {
//             border: "none",
//           },
//           "& .MuiDataGrid-cell": {
//             borderBottom: "none",
//           },
//           "& .MuiDataGrid-columnHeaders": {
//             backgroundColor: theme.palette.background.alt,
//             color: theme.palette.secondary[100],
//             borderBottom: "none",
//           },
//           "& .MuiDataGrid-virtualScroller": {
//             backgroundColor: theme.palette.primary.light,
//           },
//           "& .MuiDataGrid-footerContainer": {
//             backgroundColor: theme.palette.background.alt,
//             color: theme.palette.secondary[100],
//             borderTop: "none",
//           },
//           "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//             color: `${theme.palette.secondary[200]} !important`,
//           },
//         }}
//       >
//         <DataGrid
//           loading={isLoading || !admins}
//           rows={admins || []}
//           columns={columns}
//           components={{
//             ColumnMenu: CustomColumnMenu,
//           }}
//           actions={[
//             {
//               icon: <EditIcon />,
//               onClick: (event, rowData) => handleEdit(rowData.id),
//             },
//             {
//               icon: <DeleteIcon />,
//               onClick: (event, rowData) => setConfirmDeleteDialog(true),
//             },
//           ]}
//         />
//         {/* Confirmation dialog for delete action */}
//         <Dialog open={confirmDeleteDialog} onClose={handleCloseDialogs}>
//           {/* Dialog content for delete confirmation */}
//         </Dialog>
//         {/* Edit dialog */}
//         <Dialog open={editDialogOpen} onClose={handleCloseDialogs}>
//           <DialogTitle>Modifier l'administrateur</DialogTitle>
//           <DialogContent>
//             {/* Edit form fields */}
//             <TextField
//               label="Nom d'utilisateur"
//               value={editedAdmin.username || ""}
//               onChange={(e) =>
//                 setEditedAdmin({ ...editedAdmin, username: e.target.value })
//               }
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               label="E-mail"
//               value={editedAdmin.email || ""}
//               onChange={(e) =>
//                 setEditedAdmin({ ...editedAdmin, email: e.target.value })
//               }
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               label="Numéro de téléphone"
//               value={editedAdmin.phoneNumber || ""}
//               onChange={(e) =>
//                 setEditedAdmin({ ...editedAdmin, phoneNumber: e.target.value })
//               }
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               label="Pays"
//               value={editedAdmin.country || ""}
//               onChange={(e) =>
//                 setEditedAdmin({ ...editedAdmin, country: e.target.value })
//               }
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               label="Occupation"
//               value={editedAdmin.occupation || ""}
//               onChange={(e) =>
//                 setEditedAdmin({ ...editedAdmin, occupation: e.target.value })
//               }
//               fullWidth
//               margin="normal"
//             />
//             <TextField
//               label="Rôle"
//               value={editedAdmin.role || ""}
//               onChange={(e) =>
//                 setEditedAdmin({ ...editedAdmin, role: e.target.value })
//               }
//               fullWidth
//               margin="normal"
//             />
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleCloseDialogs} color="primary">
//               Annuler
//             </Button>
//             <Button onClick={handleEditSave} color="primary">
//               Enregistrer
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </Box>
//     </Box>
//   );
// };

// export default Admin;

import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  IconButton,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import axios from "axios";

const Admin = () => {
  const theme = useTheme();
  const [admins, setAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedAdmin, setEditedAdmin] = useState({});

  const fetchAdmins = async () => {
    try {
      const response = await axios.get("https://gestionforage.terangapp.com/api/users");
      setAdmins(response.data.users);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching administrators:", error);
      setIsLoading(false);
    }
  };

  const handleEdit = (userId) => {
    const selected = admins.find((admin) => admin.id === userId);
    setSelectedAdmin(selected);
    setEditedAdmin(selected);
    setEditDialogOpen(true);
  };

  const handleEditSave = async () => {
    try {
      const response = await axios.put(
        `https://gestionforage.terangapp.com/api/users/${editedAdmin.id}`,
        editedAdmin
      );
      console.log("Response from edit API:", response.data);
      setEditDialogOpen(false);

      // Fetch the updated admin list after editing an admin
      fetchAdmins(); // Assuming fetchAdmins is a function that fetches the admin list
    } catch (error) {
      console.error("Error editing user:", error);
    }
  };

  const handleDelete = async (id) => {
    // Open the confirmation dialog before deleting
    setConfirmDeleteDialog(true);
    setSelectedAdmin(id); // Set the selected admin ID for deletion
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(`https://gestionforage.terangapp.com/api/users/${selectedAdmin}`);
      // Update the admins state by filtering out the deleted user
      setAdmins(admins.filter((admin) => admin.id !== selectedAdmin));
      setConfirmDeleteDialog(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleCloseDialogs = () => {
    setSelectedAdmin(null);
    setConfirmDeleteDialog(false);
    setEditDialogOpen(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
    },
    {
      field: "username",
      headerName: "Nom",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Numéro de téléphone",
      flex: 0.7,
      renderCell: (params) => {
        return params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
      },
    },
    {
      field: "country",
      headerName: "Pays",
      flex: 0.4,
    },
    {
      field: "occupation",
      headerName: "Occupation",
      flex: 0.7,
    },
    {
      field: "role",
      headerName: "Rôle",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Statut",
      flex: 0.5,
      renderCell: (params) => (
        <ButtonGroup>
          <IconButton
            onClick={() => handleEdit(params.row.id)}
            aria-label="Modifier"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(params.row.id)}
            aria-label="Supprimer"
          >
            <DeleteIcon />
          </IconButton>
        </ButtonGroup>
      ),
    },
  ];

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const response = await axios.get("https://gestionforage.terangapp.com/api/users");
        setAdmins(response.data.users);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching administrators:", error);
        setIsLoading(false);
      }
    };

    fetchAdmins();
  }, []);

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="ADMINISTRATEURS"
        subtitle="Gestion des administrateurs et liste des administrateurs"
      />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !admins}
          rows={admins || []}
          columns={columns}
          components={{
            ColumnMenu: CustomColumnMenu,
          }}
          actions={[
            {
              icon: <EditIcon />,
              onClick: (event, rowData) => handleEdit(rowData.id),
            },
            {
              icon: <DeleteIcon />,
              onClick: (event, rowData) => handleDelete(rowData.id),
            },
          ]}
        />
        {/* Confirmation dialog for delete action */}
        <Dialog open={confirmDeleteDialog} onClose={handleCloseDialogs}>
          <DialogTitle>Confirmation de suppression</DialogTitle>
          <DialogContent>
            Êtes-vous sûr de vouloir supprimer cet administrateur ?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogs} color="primary">
              Annuler
            </Button>
            <Button onClick={confirmDelete} color="error">
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
        {/* Edit dialog */}
        <Dialog open={editDialogOpen} onClose={handleCloseDialogs}>
          <DialogTitle>Modifier l'administrateur</DialogTitle>
          <DialogContent>
            {/* Edit form fields */}
            <TextField
              label="Nom d'utilisateur"
              value={editedAdmin.username || ""}
              onChange={(e) =>
                setEditedAdmin({ ...editedAdmin, username: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="E-mail"
              value={editedAdmin.email || ""}
              onChange={(e) =>
                setEditedAdmin({ ...editedAdmin, email: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Numéro de téléphone"
              value={editedAdmin.phoneNumber || ""}
              onChange={(e) =>
                setEditedAdmin({ ...editedAdmin, phoneNumber: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Pays"
              value={editedAdmin.country || ""}
              onChange={(e) =>
                setEditedAdmin({ ...editedAdmin, country: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Occupation"
              value={editedAdmin.occupation || ""}
              onChange={(e) =>
                setEditedAdmin({ ...editedAdmin, occupation: e.target.value })
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Rôle"
              value={editedAdmin.role || ""}
              onChange={(e) =>
                setEditedAdmin({ ...editedAdmin, role: e.target.value })
              }
              fullWidth
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogs} color="primary">
              Annuler
            </Button>
            <Button onClick={handleEditSave} color="primary">
              Enregistrer
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Admin;
