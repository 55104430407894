import React, { useState } from "react";
import { Grid, TextField, Button, Container } from "@mui/material";
import { useSelector } from "react-redux";
import Header from "components/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import forageImage from "../../assets/forage.png";

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

const DonneesProductions = () => {
  const mode = useSelector((state) => state.global.mode);

  const [formData, setFormData] = useState({
    selectedDate: getCurrentDate(),
    nombreMp: 0,
    dureePompage: 0,
    gazoilConsomme: 0,
    electriciteConsomme: 0,
    nombreJourPanne: 0,
    visiteMaintPrev: 0,
    vidangeMoteur: 0,
    nombreMpUnit: "m3",
    dureePompageUnit: "heures",
    gazoilConsommeUnit: "litres",
    electriciteConsommeUnit: "kWh",
    nombreJourPanneUnit: "jours",
    visiteMaintPrevUnit: "nombre",
    vidangeMoteurUnit: "nombre",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name.includes("Unit") ? value : Number(value),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch("https://gestionforage.terangapp.com/api/productions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP! statut: ${response.status}`);
      }

      const data = await response.json();
      console.log("Form submitted:", data);

      toast.success("Formulaire soumis avec succès.");

      setFormData({
        selectedDate: getCurrentDate(),
        nombreMp: 0,
        dureePompage: 0,
        gazoilConsomme: 0,
        electriciteConsomme: 0,
        nombreJourPanne: 0,
        visiteMaintPrev: 0,
        vidangeMoteur: 0,
        nombreMpUnit: "m3",
        dureePompageUnit: "heures",
        gazoilConsommeUnit: "litres",
        electriciteConsommeUnit: "kWh",
        nombreJourPanneUnit: "jours",
        visiteMaintPrevUnit: "nombre",
        vidangeMoteurUnit: "nombre",
      });
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire!", error);
      toast.error(
        "Erreur lors de la soumission du formulaire. Veuillez réessayer."
      );
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, ml: 3 }}>
      <Header
        title="Ajouter une donnée de production"
        subtitle="La valeur 0 est rempli par défaut"
        sx={{ mb: 5 }}
      />
      <Grid container spacing={3} sx={{ mt: 4 }}>
        <Grid item xs={8}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Date"
                  type="date"
                  name="selectedDate"
                  value={formData.selectedDate}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {[
                { field: "nombreMp", label: "Nombre MP" },
                { field: "dureePompage", label: "Durée de Pompage" },
                { field: "gazoilConsomme", label: "Gazoil Consommé" },
                {
                  field: "electriciteConsomme",
                  label: "Électricité Consommée",
                },
                { field: "nombreJourPanne", label: "Nombre de Jours de Panne" },
                {
                  field: "visiteMaintPrev",
                  label: "Visite Maintenance Préventive",
                },
                { field: "vidangeMoteur", label: "Vidange Moteur" },
              ].map(({ field, label }) => (
                <React.Fragment key={field}>
                  <Grid item xs={3}>
                    <TextField
                      label={label}
                      type="number"
                      name={field}
                      value={formData[field]}
                      onChange={handleChange}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label={`${label} Unit`}
                      name={`${field}Unit`}
                      value={formData[`${field}Unit`]}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color={mode === "dark" ? "secondary" : "primary"}
                  size="large"
                  sx={{
                    width: "100%",
                  }}
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={4} container alignItems="center" justifyContent="center">
          <img
            src={forageImage}
            alt="Forage"
            style={{
              width: "100%",
              maxWidth: "200px",
              display: "block",
              margin: "0 auto",
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default DonneesProductions;
