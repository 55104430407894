import React, { useState, useEffect, useCallback } from "react";
import { DataGrid, GridOverlay, } from "@mui/x-data-grid";
import { DownloadOutlined } from "@mui/icons-material";
import { Box, Button, useTheme, TextField, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Convertir une date de yyyy-MM-dd en dd-MM-yyyy
const formatDateToDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// Convertir une date de dd-MM-yyyy en yyyy-MM-dd
const convertToYYYYMMDD = (dateString) => {
  const [day, month, year] = dateString.split("-");
  return `${year}-${month}-${day}`;
};

// Récupérer le premier jour du mois précédent en dd-MM-yyyy
const getFirstDayOfMonth = () => {
  const currentDate = new Date();
  currentDate.setUTCMonth(currentDate.getUTCMonth() - 1); // Met au mois précédent en UTC
  currentDate.setUTCDate(1); // Met au 1er jour du mois précédent en UTC
  const firstDay = currentDate.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(firstDay); // Convertir en dd-MM-yyyy
};

// Récupérer le dernier jour du mois précédent en dd-MM-yyyy
const getLastDayOfMonth = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0)
  );
  const lastDayFormatted = lastDay.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(lastDayFormatted); // Convertir en dd-MM-yyyy
};

const ProductionEau = () => {
  const theme = useTheme();
  const [aggregatedProductions, setAggregatedProductions] = useState([]);
  const [startDate, setStartDate] = useState(getFirstDayOfMonth()); // dd-MM-yyyy format
  const [endDate, setEndDate] = useState(getLastDayOfMonth()); // dd-MM-yyyy format
  const [isFetching, setIsFetching] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);

  // Fetch data from the API using dd-MM-yyyy date format
  const fetchAggregatedData = useCallback(async () => {
    if (!startDate || !endDate) {
      console.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetching(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/productions",
        {
          params: {
            startDate, // French date format dd-MM-yyyy
            endDate,   // French date format dd-MM-yyyy
          },
        }
      );
      const dataWithIds = response.data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setAggregatedProductions(dataWithIds);
      setIsDataReady(true);
    } catch (error) {
      console.error("Failed to fetch aggregated productions:", error);
      toast.error("Failed to fetch aggregated productions. Please try again.");
      setIsDataReady(false);
    }
    setIsFetching(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchAggregatedData();
  }, [startDate, endDate, fetchAggregatedData]);

  // Handle print report with dd-MM-yyyy date format
  const handlePrint = async () => {
    if (!isDataReady) {
      toast.error(
        "Les données ne sont pas prêtes. Veuillez sélectionner une plage de dates et réessayer."
      );
      return;
    }

    try {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
        <head>
          <title>Rapport de Production</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 60px; }
            .header { display: flex; justify-content: space-between; align-items: center; }
            .header p { margin: 0; }
            h1 { text-align: center; }
            h2 { text-align: left; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            th, td { border: 1px solid #000; padding: 8px; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
      `);

      printWindow.document.write(`
        <div class="header">
          <div><p>COPIFOR DE DAROU NGARAF</p></div>
          <div><p>Période du ${startDate} au ${endDate}</p></div>
        </div>
      `);

      printWindow.document.write("<h1>Rapport Mensuel de production</h1>");
      printWindow.document.write("<h2>Tableau de production du mois</h2>");
      printWindow.document.write("<table border='1'>");
      printWindow.document.write(`
        <tr>
          <th>Désignation</th>
          <th>Unités</th>
          <th>Valeur</th>
        </tr>
      `);

      if (aggregatedProductions.length > 0) {
        aggregatedProductions.forEach((item) => {
          printWindow.document.write(`
            <tr>
              <td>Nombre MP</td>
              <td>${item.nombreMpUnit}</td>
              <td>${item.nombreMp}</td>
            </tr>
            <tr>
              <td>Durée Pompage</td>
              <td>${item.dureePompageUnit}</td>
              <td>${item.dureePompage}</td>
            </tr>
            <tr>
              <td>Gazoil Consommé</td>
              <td>${item.gazoilConsommeUnit}</td>
              <td>${item.gazoilConsomme}</td>
            </tr>
            <tr>
              <td>Électricité Consommée</td>
              <td>${item.electriciteConsommeUnit}</td>
              <td>${item.electriciteConsomme}</td>
            </tr>
            <tr>
              <td>Nombre Jours Panne</td>
              <td>${item.nombreJourPanneUnit}</td>
              <td>${item.nombreJourPanne}</td>
            </tr>
            <tr>
              <td>Visite Maint Prév</td>
              <td>${item.visiteMaintPrevUnit}</td>
              <td>${item.visiteMaintPrev}</td>
            </tr>
            <tr>
              <td>Vidange Moteur</td>
              <td>${item.vidangeMoteurUnit}</td>
              <td>${item.vidangeMoteur}</td>
            </tr>
          `);
        });
      } else {
        printWindow.document.write(`
          <tr><td colspan="3">Aucune donnée disponible</td></tr>
        `);
      }

      printWindow.document.write("</table>");
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      toast.success("Rapport de Production imprimé avec succès.");
    } catch (error) {
      console.error("Failed to print:", error);
      toast.error(
        "Échec de l'impression du rapport de production. Veuillez réessayer."
      );
    }
  };

  const columns = [
    { field: "nombreMp", headerName: "Nombre MP", flex: 1 },
    { field: "dureePompage", headerName: "Durée Pompage", flex: 1 },
    { field: "gazoilConsomme", headerName: "Gazoil Consommé", flex: 1 },
    {
      field: "electriciteConsomme",
      headerName: "Électricité Consommée",
      flex: 1,
    },
    { field: "nombreJourPanne", headerName: "Nombre Jours Panne", flex: 1 },
    { field: "visiteMaintPrev", headerName: "Visite Maint Prév", flex: 1 },
    { field: "vidangeMoteur", headerName: "Vidange Moteur", flex: 1 },
  ];
    const CustomNoRowsOverlay = () => (
	  <GridOverlay>
		<Typography>Aucune donnée disponible</Typography>
	  </GridOverlay>
	);
	
  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Liste des Données de Production
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="secondary"
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginTop: "1px",
              marginRight: "5px",
            }}
            onClick={handlePrint}
            disabled={isFetching || !isDataReady}
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Télécharger le rapport de production
          </Button>
          <TextField
            id="start-date"
            label="Période de début"
            type="date"
            value={convertToYYYYMMDD(startDate)} // Convert dd-MM-yyyy to yyyy-MM-dd for TextField
            onChange={(e) => setStartDate(formatDateToDDMMYYYY(e.target.value))} // Convert yyyy-MM-dd to dd-MM-yyyy for state
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="end-date"
            label="Période de fin"
            type="date"
            value={convertToYYYYMMDD(endDate)} // Convert dd-MM-yyyy to yyyy-MM-dd for TextField
            onChange={(e) => setEndDate(formatDateToDDMMYYYY(e.target.value))} // Convert yyyy-MM-dd to dd-MM-yyyy for state
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </Box>
      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={aggregatedProductions}
          columns={columns}
          getRowId={(row) => row.id}
          loading={isFetching}
		  components={{
		   NoRowsOverlay: CustomNoRowsOverlay,
		  }}		  
        />
      </Box>
    </Box>
  );
};

export default ProductionEau;



// import React, { useState, useEffect, useCallback } from "react";
// import { DataGrid } from "@mui/x-data-grid";
// import { DownloadOutlined } from "@mui/icons-material";
// import { Box, Button, useTheme, TextField, Typography } from "@mui/material";
// import axios from "axios";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const getFirstDayOfMonth = () => {
//   const currentDate = new Date();
//   currentDate.setUTCDate(1); // Set to the 1st day of the current month in UTC

//   // Format date to dd-MM-yyyy (French date format)
//   const day = String(currentDate.getUTCDate()).padStart(2, '0');
//   const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
//   const year = currentDate.getUTCFullYear();

//   const firstDay = `${day}-${month}-${year}`;
//   return firstDay;
// };

// const getLastDayOfMonth = () => {
//   const currentDate = new Date();
//   const lastDay = new Date(
//     Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth() + 1, 0)
//   );

//   // Format date to dd-MM-yyyy (French date format)
//   const day = String(lastDay.getUTCDate()).padStart(2, '0');
//   const month = String(lastDay.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
//   const year = lastDay.getUTCFullYear();

//   const lastDayFormatted = `${day}-${month}-${year}`;
//   return lastDayFormatted;
// };


// const formatDateToDDMMYYYY = (dateString) => {
//   const date = new Date(dateString);
//   const day = String(date.getDate()).padStart(2, "0");
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const year = date.getFullYear();
//   return `${day}-${month}-${year}`;
// };

// const ProductionEau = () => {
//   const theme = useTheme();
//   const [aggregatedProductions, setAggregatedProductions] = useState([]);
//   const [startDate, setStartDate] = useState(getFirstDayOfMonth());
//   const [endDate, setEndDate] = useState(getLastDayOfMonth());
//   const [isFetching, setIsFetching] = useState(false);
//   const [isDataReady, setIsDataReady] = useState(false);

//   const fetchAggregatedData = useCallback(async () => {
//     if (!startDate || !endDate) {
//       console.error("Veuillez sélectionner une plage de dates.");
//       return;
//     }
//     setIsFetching(true);
//     try {
//       const response = await axios.get(
//         "https://gestionforage.terangapp.com/api/productions",
//         {
//           params: { startDate, endDate },
//         }
//       );
//       const dataWithIds = response.data.map((item, index) => ({
//         ...item,
//         id: index + 1,
//       }));
//       setAggregatedProductions(dataWithIds);
//       setIsDataReady(true);
//     } catch (error) {
//       console.error("Failed to fetch aggregated productions:", error);
//       toast.error("Failed to fetch aggregated productions. Please try again.");
//       setIsDataReady(false);
//     }
//     setIsFetching(false);
//   }, [startDate, endDate]);

//   useEffect(() => {
//     fetchAggregatedData();
//   }, [startDate, endDate, fetchAggregatedData]);

//   const handlePrint = async () => {
//     if (!isDataReady) {
//       toast.error(
//         "Les données ne sont pas prêtes. Veuillez sélectionner une plage de dates et réessayer."
//       );
//       return;
//     }

//     try {
//       const printWindow = window.open("", "_blank");
//       printWindow.document.write(`
//         <html>
//         <head>
//           <title>Rapport de Production</title>
//           <style>
//             body { font-family: Arial, sans-serif; margin: 60px; }
//             .header { display: flex; justify-content: space-between; align-items: center; }
//             .header p { margin: 0; }
//             h1 { text-align: center; }
//             h2 { text-align: left; }
//             table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
//             th, td { border: 1px solid #000; padding: 8px; }
//             th { background-color: #f2f2f2; }
//           </style>
//         </head>
//         <body>
//       `);

//       printWindow.document.write(`
//         <div class="header">
//           <div><p>COPIFOR DE DAROU NGARAF</p></div>
//           <div><p>Période du ${formatDateToDDMMYYYY(startDate)} au ${formatDateToDDMMYYYY(endDate)}</p></div>
//         </div>
//       `);

//       printWindow.document.write("<h1>Rapport Mensuel de production</h1>");
//       printWindow.document.write("<h2>Tableau de production du mois</h2>");
//       printWindow.document.write("<table border='1'>");
//       printWindow.document.write(`
//         <tr>
//           <th>Designation</th>
//           <th>Unités</th>
//           <th>Valeur</th>
//         </tr>
//       `);

//       if (aggregatedProductions.length > 0) {
//         aggregatedProductions.forEach((item) => {
//           printWindow.document.write(`
//             <tr>
//               <td>Nombre MP</td>
//               <td>${item.nombreMpUnit}</td>
//               <td>${item.nombreMp}</td>
//             </tr>
//             <tr>
//               <td>Durée Pompage</td>
//               <td>${item.dureePompageUnit}</td>
//               <td>${item.dureePompage}</td>
//             </tr>
//             <tr>
//               <td>Gazoil Consommé</td>
//               <td>${item.gazoilConsommeUnit}</td>
//               <td>${item.gazoilConsomme}</td>
//             </tr>
//             <tr>
//               <td>Électricité Consommée</td>
//               <td>${item.electriciteConsommeUnit}</td>
//               <td>${item.electriciteConsomme}</td>
//             </tr>
//             <tr>
//               <td>Nombre Jours Panne</td>
//               <td>${item.nombreJourPanneUnit}</td>
//               <td>${item.nombreJourPanne}</td>
//             </tr>
//             <tr>
//               <td>Visite Maint Prév</td>
//               <td>${item.visiteMaintPrevUnit}</td>
//               <td>${item.visiteMaintPrev}</td>
//             </tr>
//             <tr>
//               <td>Vidange Moteur</td>
//               <td>${item.vidangeMoteurUnit}</td>
//               <td>${item.vidangeMoteur}</td>
//             </tr>
//           `);
//         });
//       } else {
//         printWindow.document.write(`
//           <tr><td colspan="3">Aucune donnée disponible</td></tr>
//         `);
//       }

//       printWindow.document.write("</table>");
//       printWindow.document.write("</body></html>");
//       printWindow.document.close();
//       printWindow.print();
//       toast.success("Rapport de Production imprimé avec succès.");
//     } catch (error) {
//       console.error("Failed to print:", error);
//       toast.error(
//         "Échec de l'impression du rapport de production. Veuillez réessayer."
//       );
//     }
//   };

//   const columns = [
//     { field: "nombreMp", headerName: "Nombre MP", flex: 1 },
//     { field: "dureePompage", headerName: "Durée Pompage", flex: 1 },
//     { field: "gazoilConsomme", headerName: "Gazoil Consommé", flex: 1 },
//     {
//       field: "electriciteConsomme",
//       headerName: "Électricité Consommée",
//       flex: 1,
//     },
//     { field: "nombreJourPanne", headerName: "Nombre Jours Panne", flex: 1 },
//     { field: "visiteMaintPrev", headerName: "Visite Maint Prév", flex: 1 },
//     { field: "vidangeMoteur", headerName: "Vidange Moteur", flex: 1 },
//   ];

//   return (
//     <Box m="20px">
//       <Box
//         display="flex"
//         justifyContent="space-between"
//         alignItems="center"
//         mb={2}
//       >
//         <Box display="flex" justifyContent="space-between" alignItems="center">
//           <Typography variant="h4" component="h1" gutterBottom>
//             Liste des Données de Production
//           </Typography>
//         </Box>
//         <Box display="flex" alignItems="center">
//           <Button
//             variant="contained"
//             color="secondary"
//             style={{
//               fontSize: "14px",
//               fontWeight: "bold",
//               padding: "10px 20px",
//               marginTop: "1px",
//               marginRight: "5px",
//             }}
//             onClick={handlePrint}
//             disabled={isFetching || !isDataReady}
//           >
//             <DownloadOutlined sx={{ mr: "10px" }} />
//             Télécharger le rapport de production
//           </Button>
//           <TextField
//             id="start-date"
//             label="Période de début"
//             type="date"
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//           />
//           <TextField
//             id="end-date"
//             label="Période de fin"
//             type="date"
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//           />
//         </Box>
//       </Box>
//       <Box
//         mt="20px"
//         height="75vh"
//         sx={{
//           "& .MuiDataGrid-root": {
//             border: "none",
//           },
//           "& .MuiDataGrid-cell": {
//             borderBottom: "none",
//           },
//           "& .MuiDataGrid-virtualScroller": {
//             backgroundColor: theme.palette.primary.light,
//           },
//           "& .MuiDataGrid-footerContainer": {
//             borderTop: "none",
//             backgroundColor: theme.palette.background.alt,
//             color: theme.palette.secondary[100],
//           },
//           "& .MuiCheckbox-root": {
//             color: `${theme.palette.secondary[200]} !important`,
//           },
//         }}
//       >
//         <DataGrid
//           rows={aggregatedProductions}
//           columns={columns}
//           getRowId={(row) => row.id}
//           loading={isFetching}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default ProductionEau;
