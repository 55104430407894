import React, { useState } from "react";
import { TextField, Button, Typography, Box, Link } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../customToastStyles.css";

const Register = () => {
  const navigate = useNavigate();
  const mode = useSelector((state) => state.global.mode);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [occupation, setOccupation] = useState("");

  const handleRegister = async () => {
    if (
      !username ||
      !password ||
      !email ||
      !role ||
      !country ||
      !phoneNumber ||
      !occupation
    ) {
      toast.error("Please fill in all the fields.");
      return;
    }

    try {
      const response = await axios.post(`https://gestionforage.terangapp.com/api/register`, {
        username,
        password,
        email,
        role,
        country,
        phoneNumber,
        occupation,
      });

      if (response.status === 201) {
        navigate("/login");
        toast.success("Registration successful");
      } else {
        console.error("Registration failed:", response.data);
        toast.error("Registration failed. Please check your details.");
      }
    } catch (error) {
      console.error("Registration failed:", error);
      if (error.response && error.response.data) {
        toast.error(`Registration failed: ${error.response.data.message}`);
      } else {
        toast.error("Registration failed. Please try again.");
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        bgcolor: mode === "dark" ? "#141937" : "#fff",
        color: mode === "dark" ? "#d3d4de" : "#21295c",
        padding: 4,
      }}
    >
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault(); // Prevent form submission
          handleRegister();
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 300,
          padding: 2,
          border: `1px solid ${mode === "dark" ? "#ccc" : "#ccc"}`,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          Register
        </Typography>
        <TextField
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          variant="outlined"
          margin="normal"
          color={mode === "dark" ? "secondary" : "primary"}
        />
        <TextField
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          variant="outlined"
          margin="normal"
          color={mode === "dark" ? "secondary" : "primary"}
        />
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          margin="normal"
          color={mode === "dark" ? "secondary" : "primary"}
        />
        <TextField
          label="Role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          variant="outlined"
          margin="normal"
          color={mode === "dark" ? "secondary" : "primary"}
        />
        <TextField
          label="Country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          variant="outlined"
          margin="normal"
          color={mode === "dark" ? "secondary" : "primary"}
        />
        <TextField
          label="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          variant="outlined"
          margin="normal"
          color={mode === "dark" ? "secondary" : "primary"}
        />
        <TextField
          label="Occupation"
          value={occupation}
          onChange={(e) => setOccupation(e.target.value)}
          variant="outlined"
          margin="normal"
          color={mode === "dark" ? "secondary" : "primary"}
        />
        <Button
          type="submit"
          variant="contained"
          color={mode === "dark" ? "secondary" : "primary"}
          size="large"
          sx={{ marginTop: 2 }}
        >
          Register
        </Button>
        <Typography variant="body2" sx={{ marginTop: 1 }}>
          If you already have an account,&nbsp;
          <Link
            onClick={() => navigate("/login")}
            style={{ cursor: "pointer" }}
            color={mode === "dark" ? "secondary" : "primary"}
          >
            Login
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Register;
