import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Typography,
  IconButton,
  useTheme,
  Box,
  InputBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DownloadOutlined,
  Visibility,
  VisibilityOff,
  Search,
  Edit,
  Delete,
} from "@mui/icons-material";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

const CreanceForm = () => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);

  const [formData, setFormData] = useState({
    id: null,
    selectedDate: getCurrentDate(),
    provenanceCreance: "Fonctionnement",
    natureCreance: "Caisse",
    valeurCreance: 0,
    totauxCreances: 0,
  });

  const [creanceList, setCreanceList] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCreances, setFilteredCreances] = useState([]);
  const [showCreances, setShowCreances] = useState(true);

  useEffect(() => {
    fetchCreances();
  }, []);

  useEffect(() => {
    if (Array.isArray(creanceList)) {
      setFilteredCreances(
        creanceList.filter((creance) =>
          creance.provenanceCreance
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setFilteredCreances([]);
    }
  }, [searchQuery, creanceList]);

  const fetchCreances = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://gestionforage.terangapp.com/api/creances");
      setCreanceList(response.data || []); // Ensure response.data is an array or initialize as empty array
    } catch (error) {
      console.error("Error fetching creances!", error);
      toast.error("Error fetching creances. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "valeurCreance" ? parseFloat(value) : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      !formData.provenanceCreance ||
      !formData.natureCreance ||
      formData.valeurCreance <= 0
    ) {
      toast.error("Please fill in all required fields with valid values.");
      return;
    }

    setLoading(true);
    try {
      let url = "https://gestionforage.terangapp.com/api/creances";
      let method = "POST";

      if (formData.id) {
        url += `/${formData.id}`;
        method = "PUT";
      }

      const response = await axios({
        url,
        method,
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      });

      toast.success(
        formData.id
          ? "Creance mise à jour avec succès."
          : "Creance ajoutée avec succès."
      );

      setFormData({
        id: null,
        selectedDate: getCurrentDate(),
        provenanceCreance: "Fonctionnement",
        natureCreance: "Caisse",
        valeurCreance: 0,
        totauxCreances: 0,
      });

      // Update local state with new data
      const updatedCreances = response.data;
      setCreanceList(updatedCreances);
      setFilteredCreances(updatedCreances);

      fetchCreances(); // Refresh the list of creances
    } catch (error) {
      console.error("Error submitting the form!", error);
      toast.error(
        error.message || "Error submitting the form. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`https://gestionforage.terangapp.com/api/creances/${id}`);
      toast.success("Creance supprimée avec succès.");
      fetchCreances(); // Refresh the list of creances
    } catch (error) {
      console.error("Error deleting the creance!", error);
      toast.error(
        error.message || "Error deleting the creance. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = (creance) => {
    setFormData({
      id: creance.id,
      selectedDate: new Date(creance.selectedDate).toISOString().split("T")[0],
      provenanceCreance: creance.provenanceCreance,
      natureCreance: creance.natureCreance,
      valeurCreance: creance.valeurCreance,
      totauxCreances: creance.totauxCreances,
    });
  };

  const openConfirmationDialog = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const closeConfirmationDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteId(null);
  };

  const confirmDelete = () => {
    handleDelete(deleteId);
    closeConfirmationDialog();
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePrint = async () => {
    try {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
      <html lang="fr">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Rapport des Creances</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 60px;
          }
          .header {
            text-align: center;
            margin-bottom: 20px; /* Added margin to separate header from content */
          }
          .header p {
            margin: 0;
          }
          h1 {
            margin-bottom: 10px;
          }
          h2 {
            text-align: left;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
          }
          th, td {
            border: 1px solid #000;
            padding: 8px;
          }
          th {
            background-color: #f2f2f2;
          }
          .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            margin-top: 20px;
          }
          .footer p {
            margin: 0;
          }
          .footer div {
            display: flex;
            flex-direction: column;
            text-align: left;
          }
        </style>
      </head>
      <body>
        <div class="header">
          <h1>Rapport des Creances</h1>
        </div>
        <h2>Tableau des Creances</h2>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Provenance de la créance</th>
              <th>Nature de la créance</th>
              <th>Valeur de la créance</th>
              <th>Totaux créances</th>
            </tr>
          </thead>
          <tbody>
            ${filteredCreances
              .map(
                (creance) => `
                <tr>
                  <td>${creance.id}</td>
                  <td>${creance.selectedDate}</td>
                  <td>${creance.provenanceCreance}</td>
                  <td>${creance.natureCreance}</td>
                  <td>${creance.valeurCreance}</td>
                  <td>${creance.totauxCreances}</td>
                </tr>
              `
              )
              .join("")}
          </tbody>
        </table>
        <div class="footer">
          <div>
            <p>ASUFOR DAROU NGARAF</p>
            <p>Commune de Bandègne Ouolof, Louga (Louga)</p>
            <p>Téléphone : (+221) 77 123 45 67</p>
          </div>
          <h2>Fait à Darou Ngaraf, le ${new Date().toLocaleDateString()}</h2>
        </div>
      </body>
      </html>
      `);
      printWindow.document.close();
      printWindow.print();
    } catch (error) {
      console.error("Erreur lors de l'impression des données :", error);
      toast.error(
        "Erreur lors de l'impression des données. Veuillez réessayer."
      );
    }
  };

  const columnsCreances = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "selectedDate", headerName: "Date", width: 150 },
    { field: "provenanceCreance", headerName: "Provenance", width: 150 },
    { field: "natureCreance", headerName: "Nature", width: 150 },
    { field: "valeurCreance", headerName: "Valeur", width: 130 },
    { field: "totauxCreances", headerName: "Total Creances", width: 130 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <strong>
          <IconButton
            color="secondary"
            size="small"
            onClick={() => handleUpdate(params.row)}
          >
            <Edit />
          </IconButton>
          <IconButton
            color="secondary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => openConfirmationDialog(params.row.id)}
          >
            <Delete />
          </IconButton>
        </strong>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title={formData.id ? "Modifier une créance" : "Ajouter une creance"}
          subtitle={
            formData.id
              ? "Modifiez les informations de la créance"
              : "Remplissez les informations de la créance"
          }
          sx={{ mb: 2 }}
        />
        <Box display="flex" alignItems="center" mb="20px">
          <InputBase
            placeholder="Rechercher…"
            value={searchQuery}
            onChange={handleSearch}
            sx={{ ml: 1, flex: 1 }}
            inputProps={{ "aria-label": "search" }}
          />
          <IconButton type="submit" sx={{ p: 1 }} aria-label="search">
            <Search />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Button
          variant="contained"
          color="secondary"
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            marginTop: "4px",
            marginRight: "5px",
          }}
          onClick={handlePrint}
        >
          <DownloadOutlined sx={{ mr: "10px" }} />
          Télécharger le rapport des créances
        </Button>
      </Box>
      <Grid container spacing={3} sx={{ mt: 4 }}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  label="Provenance de la Creance"
                  name="provenanceCreance"
                  value={formData.provenanceCreance}
                  onChange={handleChange}
                  required
                  fullWidth
                >
                  <MenuItem value="Fonctionnement">Fonctionnement</MenuItem>
                  <MenuItem value="Investissement">Investissement</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  label="Nature de la Creance"
                  name="natureCreance"
                  value={formData.natureCreance}
                  onChange={handleChange}
                  required
                  fullWidth
                >
                  <MenuItem value="Caisse">Caisse</MenuItem>
                  <MenuItem value="Autres">Autres</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Valeur de la Creance"
                  type="number"
                  name="valeurCreance"
                  value={formData.valeurCreance}
                  onChange={handleChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Date"
                  type="date"
                  name="selectedDate"
                  value={formData.selectedDate}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color={mode === "dark" ? "secondary" : "primary"}
                  size="large"
                  sx={{
                    width: "100%",
                  }}
                >
                  {formData.id ? "Mettre à jour" : "Ajouter"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Creances
            <IconButton
              onClick={() => setShowCreances(!showCreances)}
              sx={{ ml: 1 }}
            >
              {showCreances ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Typography>
          {showCreances && (
            <Box
              mt="40px"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: theme.palette.background.alt,
                  color: theme.palette.secondary[100],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: theme.palette.primary.light,
                },
                "& .MuiDataGrid-footerContainer": {
                  backgroundColor: theme.palette.background.alt,
                  color: theme.palette.secondary[100],
                  borderTop: "none",
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${theme.palette.secondary[200]} !important`,
                },
              }}
            >
              <DataGrid
                loading={loading}
                rows={
                  Array.isArray(filteredCreances)
                    ? [...filteredCreances].sort((a, b) => {
                        if (a.selectedDate < b.selectedDate) return 1;
                        if (a.selectedDate > b.selectedDate) return -1;
                        return b.id - a.id;
                      })
                    : []
                }
                columns={columnsCreances}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Dialog open={openDeleteDialog} onClose={closeConfirmationDialog}>
        <DialogTitle>Confirmation de suppression</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer cette créance ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmationDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={confirmDelete} color="secondary" autoFocus>
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreanceForm;

// import React, { useState, useEffect } from "react";
// import {
//   Grid,
//   TextField,
//   Button,
//   MenuItem,
//   Typography,
//   IconButton,
//   useTheme,
//   Box,
//   InputBase,
//   CircularProgress,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
// } from "@mui/material";
// import { useSelector } from "react-redux";
// import axios from "axios";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import {
//   DownloadOutlined,
//   Visibility,
//   VisibilityOff,
//   Search,
//   Print,
// } from "@mui/icons-material";
// import Header from "components/Header";
// import { DataGrid } from "@mui/x-data-grid";

// const getCurrentDate = () => {
//   const today = new Date();
//   return today.toISOString().split("T")[0];
// };

// const CreanceForm = () => {
//   const theme = useTheme();
//   const mode = useSelector((state) => state.global.mode);

//   const [formData, setFormData] = useState({
//     id: null,
//     selectedDate: getCurrentDate(),
//     provenanceCreance: "",
//     natureCreance: "",
//     valeurCreance: 0,
//     totauxCreances: 0,
//   });

//   const [creanceList, setCreanceList] = useState([]);
//   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
//   const [deleteId, setDeleteId] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [filteredCreances, setFilteredCreances] = useState([]);
//   const [showTransactions, setShowTransactions] = useState(true); // Added state for showTransactions

//   useEffect(() => {
//     fetchCreances();
//   }, []);

//   useEffect(() => {
//     setFilteredCreances(
//       creanceList.filter((creance) =>
//         creance.provenanceCreance
//           .toLowerCase()
//           .includes(searchQuery.toLowerCase())
//       )
//     );
//   }, [searchQuery, creanceList]);

//   const fetchCreances = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/creances`
//       );
//       setCreanceList(response.data);
//     } catch (error) {
//       console.error("Error fetching creances!", error);
//       toast.error("Error fetching creances. Please try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: name === "valeurCreance" ? parseFloat(value) : value,
//     }));
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (
//       !formData.provenanceCreance ||
//       !formData.natureCreance ||
//       formData.valeurCreance <= 0
//     ) {
//       toast.error("Please fill in all required fields with valid values.");
//       return;
//     }

//     setLoading(true);
//     try {
//       let url = `${process.env.REACT_APP_API_URL}/creances`;
//       let method = "POST";

//       if (formData.id) {
//         url += `/${formData.id}`;
//         method = "PUT";
//       }

//       const response = await axios({
//         url,
//         method,
//         headers: {
//           "Content-Type": "application/json",
//         },
//         data: formData,
//       });

//       toast.success(
//         formData.id
//           ? "Creance mise à jour avec succès."
//           : "Creance ajoutée avec succès."
//       );

//       setFormData({
//         id: null,
//         selectedDate: getCurrentDate(),
//         provenanceCreance: "",
//         natureCreance: "",
//         valeurCreance: 0,
//         totauxCreances: 0,
//       });

//       fetchCreances(); // Refresh the list of creances
//     } catch (error) {
//       console.error("Error submitting the form!", error);
//       toast.error(
//         error.message || "Error submitting the form. Please try again."
//       );
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDelete = async (id) => {
//     setLoading(true);
//     try {
//       const response = await axios.delete(
//         `${process.env.REACT_APP_API_URL}/creances/${id}`
//       );

//       toast.success("Creance supprimée avec succès.");
//       fetchCreances(); // Refresh the list of creances
//     } catch (error) {
//       console.error("Error deleting the creance!", error);
//       toast.error(
//         error.message || "Error deleting the creance. Please try again."
//       );
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleUpdate = (creance) => {
//     setFormData({
//       id: creance.id,
//       selectedDate: creance.selectedDate,
//       provenanceCreance: creance.provenanceCreance,
//       natureCreance: creance.natureCreance,
//       valeurCreance: creance.valeurCreance,
//       totauxCreances: creance.totauxCreances,
//     });
//   };

//   const openConfirmationDialog = (id) => {
//     setDeleteId(id);
//     setOpenDeleteDialog(true);
//   };

//   const closeConfirmationDialog = () => {
//     setOpenDeleteDialog(false);
//     setDeleteId(null);
//   };

//   const confirmDelete = () => {
//     handleDelete(deleteId);
//     closeConfirmationDialog();
//   };

//   const handleSearch = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const handlePrint = () => {
//     window.print();
//   };

//   const columns = [
//     { field: "id", headerName: "ID", width: 90 },
//     { field: "selectedDate", headerName: "Date", width: 150 },
//     { field: "provenanceCreance", headerName: "Provenance", width: 150 },
//     { field: "natureCreance", headerName: "Nature", width: 150 },
//     { field: "valeurCreance", headerName: "Valeur", width: 130 },
//     {
//       field: "actions",
//       headerName: "Actions",
//       width: 150,
//       renderCell: (params) => (
//         <strong>
//           <Button
//             variant="contained"
//             color="primary"
//             size="small"
//             style={{ marginLeft: 16 }}
//             onClick={() => handleUpdate(params.row)}
//           >
//             Modifier
//           </Button>
//           <Button
//             variant="contained"
//             color="secondary"
//             size="small"
//             style={{ marginLeft: 16 }}
//             onClick={() => openConfirmationDialog(params.row.id)}
//           >
//             Supprimer
//           </Button>
//         </strong>
//       ),
//     },
//   ];

//   return (
//     <Box m="1.5rem 2.5rem">
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Header
//           title={formData.id ? "Modifier une creance" : "Ajouter une creance"}
//           subtitle={
//             formData.id
//               ? "Modifiez les informations de la creance"
//               : "Remplissez les informations de la creance"
//           }
//           sx={{ mb: 2 }}
//         />
//         <Box display="flex" alignItems="center" mb="20px">
//           <InputBase
//             placeholder="Rechercher…"
//             value={searchQuery}
//             onChange={handleSearch}
//             sx={{ ml: 1, flex: 1 }}
//             inputProps={{ "aria-label": "search" }}
//           />
//           <IconButton type="submit" sx={{ p: 1 }} aria-label="search">
//             <Search />
//           </IconButton>
//         </Box>
//       </Box>
//       <Box display="flex" alignItems="center">
//         <Button
//           variant="contained"
//           color="secondary"
//           style={{
//             fontSize: "14px",
//             fontWeight: "bold",
//             padding: "10px 20px",
//             marginTop: "4px",
//             marginRight: "5px",
//           }}
//           onClick={handlePrint}
//         >
//           <DownloadOutlined sx={{ mr: "10px" }} />
//           Télécharger le rapport transactionnel
//         </Button>
//         <TextField
//           id="start-date"
//           label="Période de début"
//           type="date"
//           value={formData.startDate}
//           onChange={(e) =>
//             setFormData({ ...formData, startDate: e.target.value })
//           }
//           InputLabelProps={{
//             shrink: true,
//           }}
//         />
//         <TextField
//           id="end-date"
//           label="Période de fin"
//           type="date"
//           value={formData.endDate}
//           onChange={(e) =>
//             setFormData({ ...formData, endDate: e.target.value })
//           }
//           InputLabelProps={{
//             shrink: true,
//           }}
//         />
//       </Box>
//       <Grid container spacing={3} sx={{ mt: 4 }}>
//         <Grid item xs={12}>
//           <form onSubmit={handleSubmit} noValidate>
//             <Grid container spacing={2}>
//               <Grid item xs={12} sm={4}>
//                 <TextField
//                   select
//                   label="Provenance de la Creance"
//                   name="provenanceCreance"
//                   value={formData.provenanceCreance}
//                   onChange={handleChange}
//                   required
//                   fullWidth
//                 >
//                   <MenuItem value="Fonctionnement">Fonctionnement</MenuItem>
//                   <MenuItem value="Investissement">Investissement</MenuItem>
//                 </TextField>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <TextField
//                   select
//                   label="Nature de la Creance"
//                   name="natureCreance"
//                   value={formData.natureCreance}
//                   onChange={handleChange}
//                   required
//                   fullWidth
//                 >
//                   <MenuItem value="Caisse">Caisse</MenuItem>
//                   <MenuItem value="Autres">Autres</MenuItem>
//                 </TextField>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <TextField
//                   label="Valeur de la Creance"
//                   type="number"
//                   name="valeurCreance"
//                   value={formData.valeurCreance}
//                   onChange={handleChange}
//                   required
//                   fullWidth
//                 />
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <TextField
//                   label="Date"
//                   type="date"
//                   name="selectedDate"
//                   value={formData.selectedDate}
//                   onChange={handleChange}
//                   required
//                   fullWidth
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <Button
//                   type="submit"
//                   variant="contained"
//                   color={mode === "dark" ? "secondary" : "primary"}
//                   size="large"
//                   sx={{
//                     width: "100%",
//                   }}
//                 >
//                   {formData.id ? "Mettre à jour" : "Ajouter"}
//                 </Button>
//               </Grid>
//             </Grid>
//           </form>
//         </Grid>
//         <Grid item xs={12}>
//           <Typography variant="h6" sx={{ mb: 2 }}>
//             Transactions Bancaires
//             <IconButton
//               onClick={() => setShowTransactions(!showTransactions)}
//               sx={{ ml: 1 }}
//             >
//               {showTransactions ? <VisibilityOff /> : <Visibility />}
//             </IconButton>
//           </Typography>
//           <Box
//             mt="40px"
//             height="75vh"
//             sx={{
//               "& .MuiDataGrid-root": {
//                 border: "none",
//               },
//               "& .MuiDataGrid-cell": {
//                 borderBottom: "none",
//               },
//               "& .MuiDataGrid-columnHeaders": {
//                 backgroundColor: theme.palette.background.alt,
//                 color: theme.palette.secondary[100],
//                 borderBottom: "none",
//               },
//               "& .MuiDataGrid-virtualScroller": {
//                 backgroundColor: theme.palette.primary.light,
//               },
//               "& .MuiDataGrid-footerContainer": {
//                 backgroundColor: theme.palette.background.alt,
//                 color: theme.palette.secondary[100],
//                 borderTop: "none",
//               },
//               "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//                 color: `${theme.palette.secondary[200]} !important`,
//               },
//             }}
//           >
//             <DataGrid
//               loading={loading}
//               rows={filteredCreances.sort((a, b) => {
//                 if (a.selectedDate < b.selectedDate) return 1;
//                 if (a.selectedDate > b.selectedDate) return -1;
//                 return b.id - a.id;
//               })}
//               columns={columns}
//             />
//           </Box>
//         </Grid>
//       </Grid>
//       <Dialog open={openDeleteDialog} onClose={closeConfirmationDialog}>
//         <DialogTitle>Confirmation de suppression</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             Êtes-vous sûr de vouloir supprimer cette creance ?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closeConfirmationDialog} color="primary">
//             Annuler
//           </Button>
//           <Button onClick={confirmDelete} color="secondary" autoFocus>
//             Supprimer
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default CreanceForm;
