import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  CircularProgress,
  TextField,
  useTheme,
} from "@mui/material";
import Header from "../../components/Header";
import { fetchTotalConsommation, fetchSummedDonnees } from "./apiService";
import { ResponsiveLine } from "@nivo/line";

const Overview = () => {
  const [view, setView] = useState("totalConsommation");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const theme = useTheme();

const formatDate = (date) => {
  const [year, month, day] = date.split("-");
  return `${day}-${month}-${year}`;
};

useEffect(() => {
  const loadData = async () => {
    setLoading(true);
    try {
      let chartData;
      if (view === "totalConsommation") {
        const totalConsommation = await fetchTotalConsommation();
        chartData = [
          {
            id: "Total Consommation",
            data: [{ x: "Total", y: totalConsommation }],
          },
        ];
      } else {
        const start = formatDate(startDate || new Date().toISOString().split("T")[0]);
        const end = formatDate(endDate || new Date().toISOString().split("T")[0]);
        console.log(`Fetching data from https://gestionforage.terangapp.com/api/donnees/summed?startDate=${start}&endDate=${end}`);
        const summedDonnees = await fetchSummedDonnees(start, end);
        chartData = [
          {
            id: "Summed Donnees",
            data: Object.entries(summedDonnees).map(([key, value]) => ({
              x: key,
              y: parseInt(value, 10),
            })),
          },
        ];
      }
      setData(chartData);
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setLoading(false);
    }
  };

  loadData();
}, [view, startDate, endDate]);


  return (
    <Box m="1.5rem 2.5rem" mb="20px">
      <Header title="APERÇU" subtitle="Vue d'ensemble des mesures" />
      <Box height="75vh">
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            mt: "1rem",
            mb: "1rem",
          }}
        >
          <FormControl>
            <InputLabel>Vue</InputLabel>
            <Select
              value={view}
              label="Vue"
              onChange={(e) => setView(e.target.value)}
            >
              <MenuItem value="totalConsommation">Total Consommation</MenuItem>
              <MenuItem value="summedDonnees">Données Techniques Agrégées</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <ResponsiveLine
            data={data}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: theme.palette.secondary[200],
                  },
                },
                legend: {
                  text: {
                    fill: theme.palette.secondary[200],
                  },
                },
                ticks: {
                  line: {
                    stroke: theme.palette.secondary[200],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: theme.palette.secondary[200],
                  },
                },
              },
              grid: {
                line: {
                  stroke: theme.palette.grey[200],
                },
              },
              legends: {
                text: {
                  fill: theme.palette.secondary[200],
                },
              },
              tooltip: {
                container: {
                  background: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                },
              },
            }}
            margin={{ top: 50, right: 50, bottom: 70, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: 0,
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 90,
              legend: view === "totalConsommation" ? "Total" : "Key",
              legendOffset: 60,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Value",
              legendOffset: -50,
              legendPosition: "middle",
            }}
            enableGridX={false}
            enableGridY={false}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            legends={[
              {
                anchor: "top-right",
                direction: "column",
                justify: false,
                translateX: 50,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: "left-to-right",
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: "circle",
                symbolBorderColor: "rgba(0, 0, 0, .5)",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        )}
      </Box>
    </Box>
  );
};

export default Overview;
