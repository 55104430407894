import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Container,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const countriesList = [
  "Sénégal",
  // Add more countries as needed
];

const senegalRegions = [
  "Dakar",
  "Diourbel",
  "Fatick",
  "Kaffrine",
  "Kaolack",
  "Kédougou",
  "Kolda",
  "Louga",
  "Matam",
  "Saint-Louis",
  "Sédhiou",
  "Tambacounda",
  "Thiès",
  "Ziguinchor",
];

const senegalCommunes = ["Bandegne Ouolof"];

const villagesList = [
  "Maka Sall",
  "Wawlou Sall",
  "Bahis Peulh", 
  "Ngaraf Ouolof",
  "Santhiou Diokoul",
  "Maka Sarr",
  "Taiba Ndiouga",
  "Dialgane Guedj",
  "Guedj Seck",
  "Bakhdar Ouolof",
  "Bakhdar Peulh1",
  "Bakhdar Peulh2",
  "Ndam Ndam Peulh",
  "Ndam Ndam Ouolof",
  "Bahis Seck",
  "Darou Niang",
  "Merina Dia",
  "Bandegne Peulh",
  "Darou Ngaraf",
  "Ngaraf Peulh",
  //"Ngaraf Peulh 1 et 2",
  "Bandegne Fass",
  "Celle Diagne",
  "Nguembe Dieng",
  "Pallene 1, 2 et Peulh",
  "Racine Peulh",
  "Khara Ndack",
  "Darou Mbengue",
  "Keur Bakary",
  "Gouye Mbaye",
  "Keur Medoune",
  "Siwal Peulh",
  "Dara Khara",
  "Thiary Loro",
  "Thiary Thieurigne",
  // Add more villages as needed in alphabetical order
];

const AddCustomer = () => {
  const mode = useSelector((state) => state.global.mode);

  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getUTCDate()).padStart(2, "0");
    const month = String(today.getUTCMonth() + 1).padStart(2, "0"); // January is 0!
    const year = today.getUTCFullYear();
    return `${day}-${month}-${year}`; // Use "-" instead of "/"
  };
 

  const [selectedDate, setSelectedDate] = useState(getCurrentDate());
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("Sénégal");
  const [state, setState] = useState("Bandegne Ouolof");
  const [region, setRegion] = useState("Louga");
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [contrat, setContrat] = useState(null);
  const [fileError, setFileError] = useState("");
  const [type, setType] = useState("Branchements Particuliers");
  const [village, setVillage] = useState("Maka Sall");

const handleSubmit = async (event) => {
  event.preventDefault();

  if (!firstName || !lastName) {
    setFileError("Veuillez remplir tous les champs obligatoires.");
    toast.error("Veuillez remplir tous les champs obligatoires.");
    return;
  }

  // Format the date to dd-MM-yyyy
  const formattedDate = getCurrentDate();

  const formData = new FormData();
  formData.append("firstName", firstName);
  formData.append("lastName", lastName);
  formData.append("country", country);
  formData.append("state", state);
  formData.append("region", region);
  formData.append("phoneNumber", phoneNumber);
  formData.append("selectedDate", formattedDate); // Send formatted date to backend
  formData.append("type", type);
  formData.append("village", village);
  if (contrat) {
    formData.append("contrat", contrat);
  }

  try {
    const response = await fetch("https://gestionforage.terangapp.com/api/clients", {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      if (response.status === 409) {
        throw new Error("Client with the same name and village already exists");
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log("Form submitted:", data);

    // Reset form fields and states
    setFirstName("");
    setLastName("");
    setCountry("Sénégal");
    setState("Bandegne Ouolof");
    setRegion("Louga");
    setPhoneNumber(null);
    setSelectedDate(getCurrentDate());
    setContrat(null);
    setFileError("");
    setType("Branchements Particuliers");
    setVillage("Maka Sall");
    toast.success("Client ajouté avec succès");
  } catch (error) {
    console.error("There was an error submitting the form!", error);
    if (error.message.includes("Client with the same name and village already exists")) {
      toast.error("Un client avec le même nom et le même village existe déjà.");
    } else {
      toast.error("Erreur lors de l'ajout du client. Veuillez réessayer.");
    }
  }
};


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setContrat(file);
      setFileError("");
    } else {
      setContrat(null);
      setFileError("Veuillez sélectionner un fichier PDF.");
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Prénom"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Nom"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              label="Pays"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
              fullWidth
              disabled
            >
              {countriesList.map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              label="Région"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
              required
              fullWidth
	      disabled
            >
              {senegalRegions.map((region) => (
                <MenuItem key={region} value={region}>
                  {region}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Commune</InputLabel>
              <Select
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
                disabled
              >
                {senegalCommunes.map((commune) => (
                  <MenuItem key={commune} value={commune}>
                    {commune}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Village</InputLabel>
              <Select
                value={village}
                onChange={(e) => setVillage(e.target.value)}
                required
              >
                {villagesList.map((village) => (
                  <MenuItem key={village} value={village}>
                    {village}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Numéro de téléphone"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                value={type}
                onChange={(e) => setType(e.target.value)}
                required
              >
                <MenuItem value="Bornes Fontaines">Bornes Fontaines</MenuItem>
                <MenuItem value="Branchements Particuliers">
                  Branchements Particuliers
                </MenuItem>

                <MenuItem value="Potences A charettes">
                  Potences à charettes
                </MenuItem>
                <MenuItem value="Branchements Communautaires">
                  Branchements Communautaires
                </MenuItem>

                <MenuItem value="Abreuvoirs">Abreuvoirs</MenuItem>
                <MenuItem value="Branchements Agricoles">
                  Branchements Agricoles
                </MenuItem>
                <MenuItem value=" Autres points de distribution">
                  Autres points de distribution
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <InputLabel htmlFor="contrat">Contrat :</InputLabel>
            <FormControl fullWidth>
              <input
                type="file"
                id="contrat"
                accept=".pdf"
                onChange={handleFileChange}
              />
            </FormControl>
            {fileError && <FormHelperText error>{fileError}</FormHelperText>}
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              required
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color={mode === "dark" ? "secondary" : "primary"}
              size="large"
              sx={{
                width: "100%",
              }}
            >
              Ajouter
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AddCustomer;
