import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import { ResponsiveLine } from "@nivo/line";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetDashboardQuery } from "state/api";

// Convertir une date de yyyy-MM-dd en dd-MM-yyyy
const formatDateToDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// Convertir une date de dd-MM-yyyy en yyyy-MM-dd
const convertToYYYYMMDD = (dateString) => {
  const [day, month, year] = dateString.split("-");
  return `${year}-${month}-${day}`;
};

const Dashboard = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { isLoading } = useGetDashboardQuery();
  const [consommations, setConsommations] = useState([]);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [formattedData, setFormattedData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [paymentStatusFilter, setPaymentStatusFilter] = useState("");

  const calculateTotalPaidAmount = useCallback((data) => {
    const total = data.reduce(
      (sum, record) => sum + (record.paidAmount || 0),
      0
    );
    setTotalPaidAmount(total.toFixed(2));
  }, []);

  const applyFilters = useCallback(
    (data) => {
      const parseFrenchDate = (dateStr) => {
        const [day, month, year] = dateStr.split('-').map(Number);
        return new Date(year, month - 1, day);
      };

      let filteredData = data;

      // Apply search filter
      if (searchQuery) {
        filteredData = filteredData.filter(
          (item) =>
            item.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.waterBillRefNumber.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }

      // Apply date range filter
      if (startDate || endDate) {
        filteredData = filteredData.filter((item) => {
          if (!item.recetteDate) return false;

          const itemDate = parseFrenchDate(item.recetteDate);
          const start = startDate ? parseFrenchDate(startDate) : null;
          const end = endDate ? parseFrenchDate(endDate) : null;

          if (start && end) {
            return itemDate >= start && itemDate <= end;
          } else if (start) {
            return itemDate >= start;
          } else if (end) {
            return itemDate <= end;
          }
          return true;
        });
      }

      // Apply payment status filter
      if (paymentStatusFilter) {
        filteredData = filteredData.filter(
          (item) => item.paymentStatus === paymentStatusFilter
        );
      }

      setConsommations(filteredData);
      calculateTotalPaidAmount(filteredData);
    },
    [calculateTotalPaidAmount, searchQuery, startDate, endDate, paymentStatusFilter]
  );

  useEffect(() => {
    const fetchConsommations = async () => {
      try {
        const response = await axios.get(
          "https://gestionforage.terangapp.com/api/consommations"
        );
        let data = response.data.map((item) => ({
          ...item,
          paidAmount: Number(item.paidAmount) || 0,
        }));
        data.sort((a, b) => b.id - a.id);
        setFormattedData(data);
        applyFilters(data);
      } catch (error) {
        console.error("Failed to fetch consommations:", error);
      }
    };

    fetchConsommations();
  }, [applyFilters]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handlePaymentStatusChange = (e) => {
    setPaymentStatusFilter(e.target.value);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "waterBillRefNumber", headerName: "Réf. Facture Eau", flex: 1 },
    {
      field: "selectedDate",
      headerName: "Date Sélectionnée",
      flex: 1,
      type: "date",
    },
    {
      field: "recetteDate",
      headerName: "Date Recette",
      flex: 1,
      type: "date",
    },
    { field: "firstName", headerName: "Prénom", flex: 1 },
    { field: "lastName", headerName: "Nom", flex: 1 },
    {
      field: "currentIndex",
      headerName: "Index Actuel",
      flex: 1,
      type: "number",
    },
    {
      field: "consommation",
      headerName: "Consommation",
      flex: 1,
      type: "number",
    },
    {
      field: "paidAmount",
      headerName: "Montant Payé",
      flex: 1,
      type: "number",
      renderCell: (params) => `${Number(params.value || 0).toFixed(2)} CFA`,
    },
    { field: "paymentStatus", headerName: "Statut Paiement", flex: 1 },
  ];

  const memoizedData = useMemo(() => formattedData, [formattedData]);

  return (
    <Box m="1.5rem 2.5rem">
      {/* Header Row */}
      {isMobile && (
        <Box mb={2}>
          <Header
            title="TABLEAU DE BORD"
            subtitle="Bienvenue sur votre tableau de bord"
          />
        </Box>
      )}

      <FlexBetween
        flexDirection={isMobile ? "column" : "row"}
        gap="20px"
        alignItems="center"
        mb={2}
      >
        {!isMobile && (
          <Header
            title="TABLEAU DE BORD"
            subtitle="Bienvenue sur votre tableau de bord"
          />
        )}
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          gap="20px"
          alignItems="center"
        >
          <TextField
            type="text"
            label="Rechercher..."
            value={searchQuery}
            onChange={handleSearchChange}
            fullWidth={isMobile}
          />
          <TextField
            type="date"
            label="Start Date"
            value={convertToYYYYMMDD(startDate)} 
            onChange={(e) => setStartDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{ shrink: true }}
            fullWidth={isMobile}
          />
          <TextField
            type="date"
            label="End Date"
            value={convertToYYYYMMDD(endDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setEndDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{ shrink: true }}
            fullWidth={isMobile}
          />
          <TextField
            select
            label="Statut de Paiement"
            value={paymentStatusFilter}
            onChange={handlePaymentStatusChange}
            sx={{ minWidth: "120px" }}
            fullWidth={isMobile}
          >
            <MenuItem value="">Tous</MenuItem>
            <MenuItem value="payé">Payé</MenuItem>
            <MenuItem value="impayé">Impayé</MenuItem>
          </TextField>
        </Box>
      </FlexBetween>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": {
            gridColumn: isNonMediumScreens ? undefined : "span 12",
          },
        }}
      >
        <DataGridComponent
          isLoading={isLoading}
          consommations={consommations}
          columns={columns}
          searchQuery={searchQuery}
        />

        <TotalPaidAmountDisplay totalPaidAmount={totalPaidAmount} />

        <Box
          gridColumn="span 8"
          gridRow="span 3"
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={-1}
          sx={{
            backgroundColor: theme.palette.background.alt,
            borderRadius: "2.5rem",
            padding: "1rem",
            height: "100%",
          }}
        >
          <Box width="100%" height="100%">
            {memoizedData.length > 0 ? (
              <ResponsiveLine
                data={[{
                  id: "Consommation",
                  data: memoizedData
                    .slice(0, 5) // Get top 5 entries
                    .map((item) => ({
                      x: item.waterBillRefNumber,
                      y: item.consommation,
                    })),
                }]}
                margin={{ top: 20, right: 20, bottom: 80, left: 70 }}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: 0,
                  stacked: false,
                  reverse: false,
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 90,
                  legend: "Réf. Facture Eau",
                  legendOffset: 60,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Total Consommation",
                  legendOffset: -50,
                  legendPosition: "middle",
                }}
                enableGridX={false}
                enableGridY={false}
                pointSize={10}
                pointBorderWidth={2}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[{
                  anchor: "middle",
                  direction: "column",
                  justify: false,
                  translateX: 50,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [{
                    on: "hover",
                    style: {
                      itemBackground: "rgba(0, 0, 0, .03)",
                      itemOpacity: 1,
                    },
                  }],
                }]}
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: theme.palette.secondary[200],
                      },
                    },
                    legend: {
                      text: {
                        fill: theme.palette.secondary[200],
                      },
                    },
                    ticks: {
                      line: {
                        stroke: theme.palette.secondary[200],
                        strokeWidth: 1,
                      },
                      text: {
                        fill: theme.palette.secondary[200],
                      },
                    },
                  },
                  grid: {
                    line: {
                      stroke: theme.palette.grey[200],
                    },
                  },
                  legends: {
                    text: {
                      fill: theme.palette.secondary[200],
                    },
                  },
                  tooltip: {
                    container: {
                      background: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                    },
                  },
                }}
              />
            ) : (
              <>Pas de données disponibles.</>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const DataGridComponent = ({
  isLoading,
  consommations,
  columns,
  searchQuery,
}) => {
  const theme = useTheme();

  return (
    <Box
      gridColumn="span 8"
      gridRow="span 3"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
          borderRadius: "5rem",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: theme.palette.background.alt,
        },
        "& .MuiDataGrid-footerContainer": {
          backgroundColor: theme.palette.background.alt,
          color: theme.palette.secondary[100],
          borderTop: "none",
          paddingTop: 0,
          paddingBottom: 0,
        },
        "& .MuiTablePagination-root": {
          backgroundColor: theme.palette.background.alt,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${theme.palette.secondary[200]} !important`,
        },
      }}
    >
      <DataGrid
        loading={isLoading}
        getRowId={(row) => row.id}
        rows={consommations}
        columns={columns}
        components={{
          NoRowsOverlay: () => (
            <Typography
              variant="h6"
              sx={{
                p: 2,
                textAlign: "center",
                color: theme.palette.secondary[200],
              }}
            >
              {searchQuery
                ? "Recherche indisponible"
                : "Aucune donnée disponible"}
            </Typography>
          ),
        }}
      />
    </Box>
  );
};

const TotalPaidAmountDisplay = ({ totalPaidAmount }) => {
  const theme = useTheme();

  return (
    <Box
      gridColumn="span 4"
      gridRow="span 1"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      marginTop={1}
      marginBottom={3}
      sx={{
        backgroundColor: theme.palette.background.alt,
        borderRadius: "1.5rem",
        padding: "1rem",
      }}
    >
      <Typography variant="h5" component="div">
        Total Montant Payé
      </Typography>
      <Typography variant="h3" component="div" color="secondary">
        {totalPaidAmount} CFA
      </Typography>
    </Box>
  );
};

export default Dashboard;
