import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import { DataGrid } from "@mui/x-data-grid";
import { SaveAlt, FilterList, Edit } from "@mui/icons-material";
import { Box, Button, TextField, useTheme, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const Proprietaires = () => {
  const theme = useTheme();
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    waterBillRefNumber: "",
    firstName: "",
    lastName: "",
    country: "",
    state: "",
    region: "",
    phoneNumber: "",
  });
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    country: "",
    state: "",
    region: "",
    village: "",
    phoneNumber: "",
    selectedDate: "",
    type: "",
  });

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get("https://gestionforage.terangapp.com/api/clients");
        const data = response.data.map((client) => {
          let formattedDate = "N/A";
          if (client.selectedDate) {
            const dateParts = client.selectedDate.split("-");
            if (dateParts.length === 3) {
              const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
              if (!isNaN(date.getTime())) {
                formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${dateParts[2]}`;
              }
            }
          }
          return { ...client, selectedDate: formattedDate };
        });

        data.sort((a, b) => b.id - a.id);
        setClients(data);
        setFilteredClients(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch clients:", error);
        setIsLoading(false);
      }
    };

    fetchClients();
  }, []);

  const handleDownload = async (client) => {
    try {
      const contractData = client.contrat.data;
      const blob = new Blob([new Uint8Array(contractData)], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "contrat.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("Contrat téléchargé avec succès!");
    } catch (error) {
      console.error("Failed to download contrat:", error);
      toast.error("Erreur lors du téléchargement du contrat.");
    }
  };

  const handleEditClick = (client) => {
    setSelectedClient(client);
    const dateParts = client.selectedDate.split("/");
    const formattedDate = dateParts.length === 3 ? `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}` : ""; // Convert to yyyy-MM-dd
    setFormValues({
      firstName: client.firstName,
      lastName: client.lastName,
      country: client.country,
      state: client.state,
      region: client.region,
      village: client.village,
      phoneNumber: client.phoneNumber,
      selectedDate: formattedDate, // Set as yyyy-MM-dd for API
      type: client.type,
    });
    setEditDialogOpen(true);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = async () => {
    const dateParts = formValues.selectedDate.split("-");
    const formattedDate = `${String(dateParts[2]).padStart(2, '0')}-${String(dateParts[1]).padStart(2, '0')}-${String(dateParts[0]).padStart(4, '0')}`; // Ensure it is in dd-MM-yyyy format

    const updatedData = {
      ...formValues,
      selectedDate: formattedDate,
    };

    try {
      await axios.put(`https://gestionforage.terangapp.com/api/clients/${selectedClient.id}`, updatedData);
      const updatedClients = clients.map((client) =>
        client.id === selectedClient.id ? { ...client, ...updatedData } : client
      );
      setClients(updatedClients);
      setFilteredClients(updatedClients);
      setEditDialogOpen(false);
      toast.success("Client mis à jour avec succès!");
    } catch (error) {
      console.error("Failed to update client:", error);
      toast.error("Erreur lors de la mise à jour du client.");
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const applyFilters = () => {
    const filtered = clients.filter((client) =>
      Object.keys(filters).every((key) =>
        client[key]
          ? client[key].toString().toLowerCase().includes(filters[key].toLowerCase()) : false
      )
    );
    setFilteredClients(filtered);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "firstName", headerName: "Prénom", flex: 1 },
    { field: "lastName", headerName: "Nom", flex: 1 },
    { field: "country", headerName: "Pays", flex: 1 },
    { field: "region", headerName: "Région", flex: 1 },
    { field: "state", headerName: "Commune", flex: 1 },
    { field: "waterBillRefNumber", headerName: "Référence de la facture d'eau", flex: 1 },
    { field: "phoneNumber", headerName: "Numéro de téléphone", flex: 1 },
    { field: "selectedDate", headerName: "Date", flex: 1 },
    { field: "type", headerName: "Type", flex: 1 },
    {
      field: "contrat",
      headerName: "Contrat",
      flex: 1,
      renderCell: (params) => {
        const contratExists = params.value !== null && params.value !== undefined;
        return contratExists ? (
          <Box display="flex" alignItems="center">
            <Button
              variant="outlined"
              color="secondary"
              sx={{ fontSize: "10px", minWidth: "40px" }}
              onClick={() => handleDownload(params.row)}
            >
              <SaveAlt sx={{ mr: "4px" }} />
            </Button>
          </Box>
        ) : (
          "Aucun contrat"
        );
      },
    },
    {
      field: "edit",
      headerName: "Modifier",
      flex: 1,
      renderCell: (params) => (
        <Button variant="outlined" color="secondary" onClick={() => handleEditClick(params.row)}>
          <Edit />
        </Button>
      ),
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <ToastContainer />
      <FlexBetween>
        <Header title="TABLEAU DE BORD" subtitle="Bienvenue sur votre tableau de bord" />
        <Box>
          <Button
            onClick={applyFilters}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <FilterList sx={{ mr: "20px" }} />
            Appliquer les filtres
          </Button>
        </Box>
      </FlexBetween>
      <Box mt="15px" mb="2px">
        <Box display="flex" justifyContent="space-between" mb="10px">
          <TextField
            label="Référence Facture Eau"
            name="waterBillRefNumber"
            value={filters.waterBillRefNumber}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            sx={{ mr: 2 }}
          />
          <TextField
            label="Prénom"
            name="firstName"
            value={filters.firstName}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            sx={{ mr: 2 }}
          />
          <TextField
            label="Nom"
            name="lastName"
            value={filters.lastName}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
            sx={{ mr: 2 }}
          />
          <TextField
            label="Numéro de téléphone"
            name="phoneNumber"
            value={filters.phoneNumber}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
          />
        </Box>
      </Box>
      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": { border: "none" },
          "& .MuiDataGrid-cell": { borderBottom: "none" },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          getRowId={(row) => row.id}
          rows={filteredClients}
          columns={columns}
        />
      </Box>

      {/* Edit Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Modifier Client</DialogTitle>
        <DialogContent>
          <TextField
            label="Prénom"
            name="firstName"
            value={formValues.firstName}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Nom"
            name="lastName"
            value={formValues.lastName}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Pays"
            name="country"
            value={formValues.country}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Commune"
            name="state"
            value={formValues.state}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Région"
            name="region"
            value={formValues.region}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Village"
            name="village"
            value={formValues.village}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Numéro de téléphone"
            name="phoneNumber"
            value={formValues.phoneNumber}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Date (yyyy-MM-dd)"
            name="selectedDate"
            value={formValues.selectedDate}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Type"
            name="type"
            value={formValues.type}
            onChange={handleFormChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleFormSubmit} color="secondary">
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Proprietaires;

