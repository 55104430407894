import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";


const useFetchSortieCaisse = (startDate, endDate) => {
  const [sortieCaisse, setSortieCaisse] = useState([]);
  const [totalSortie, setTotalSortie] = useState(null);
  const [isFetchingSortie, setIsFetchingSortie] = useState(false);
  const [isDataReadySortie, setIsDataReadySortie] = useState(false);

  const fetchSortieCaisseData = useCallback(async () => {
    if (!startDate || !endDate) {
      toast.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetchingSortie(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/sortiecaisse",
        {
          params: { startDate: startDate, endDate: endDate },
        }
      );

      const dataWithIds = response.data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      setSortieCaisse(dataWithIds);

      // Calculer totalSortie si c'est inclus dans la réponse
      const total = response.data.reduce((acc, item) => {
        return acc + parseFloat(item.total || 0);
      }, 0);

      setTotalSortie(total);
      setIsDataReadySortie(true);
    } catch (error) {
      console.error(
        "Échec de la récupération des données de sortie de caisse:",
        error
      );
      toast.error(
        "Échec de la récupération des données de sortie de caisse. Veuillez réessayer."
      );
      setIsDataReadySortie(false);
    }
    setIsFetchingSortie(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchSortieCaisseData();
  }, [startDate, endDate, fetchSortieCaisseData]);

  return { sortieCaisse, totalSortie, isFetchingSortie, isDataReadySortie };
};

export default useFetchSortieCaisse;


