import React, { useState, useEffect, useCallback } from "react";
import { DataGrid, GridOverlay, } from "@mui/x-data-grid";
import { DownloadOutlined } from "@mui/icons-material";
import { Box, Button, useTheme, TextField, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Convertir une date de yyyy-MM-dd en dd-MM-yyyy
const formatDateToDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// Convertir une date de dd-MM-yyyy en yyyy-MM-dd
const convertToYYYYMMDD = (dateString) => {
  const [day, month, year] = dateString.split("-");
  return `${year}-${month}-${day}`;
};

// Récupérer le premier jour du mois précédent en dd-MM-yyyy
const getFirstDayOfMonth = () => {
  const currentDate = new Date();
  currentDate.setUTCMonth(currentDate.getUTCMonth() - 1); // Met au mois précédent en UTC
  currentDate.setUTCDate(1); // Met au 1er jour du mois précédent en UTC
  const firstDay = currentDate.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(firstDay); // Convertir en dd-MM-yyyy
};

// Récupérer le dernier jour du mois précédent en dd-MM-yyyy
const getLastDayOfMonth = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0)
  );
  const lastDayFormatted = lastDay.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(lastDayFormatted); // Convertir en dd-MM-yyyy
};

const InfosList = () => {
  const theme = useTheme();
  const [aggregatedInfosList, setAggregatedInfosList] = useState([]);
  const [startDate, setStartDate] = useState(getFirstDayOfMonth()); // dd-MM-yyyy
  const [endDate, setEndDate] = useState(getLastDayOfMonth()); // dd-MM-yyyy
  const [isFetchingAutresInfos, setIsFetchingAutresInfos] = useState(false);
  const [isDataReadyAutresInfos, setIsDataReadyAutresInfos] = useState(false);

  const fetchAggregatedDataAutresInfos = useCallback(async () => {
    if (!startDate || !endDate) {
      console.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetchingAutresInfos(true);
    try {
      const response = await axios.get("https://gestionforage.terangapp.com/api/infos", {
        params: { startDate: startDate, endDate: endDate  }, 
      });
      const dataWithIds = response.data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setAggregatedInfosList(dataWithIds);
      setIsDataReadyAutresInfos(true);
    } catch (error) {
      console.error("Échec de la récupération des autres informations agrégées:", error);
      toast.error("Échec de la récupération des autres informations. Veuillez réessayer.");
      setIsDataReadyAutresInfos(false);
    }
    setIsFetchingAutresInfos(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchAggregatedDataAutresInfos();
  }, [startDate, endDate, fetchAggregatedDataAutresInfos]);

  const handlePrint = async () => {
    if (!isDataReadyAutresInfos) {
      toast.error("Les données ne sont pas prêtes. Veuillez sélectionner une plage de dates et réessayer.");
      return;
    }

    try {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
        <head>
          <title>Rapport Autres Informations</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 60px; }
            .header { display: flex; justify-content: space-between; align-items: center; }
            .header p { margin: 0; }
            h1 { text-align: center; }
            h2 { text-align: left; }
            .content { margin-top: 20px; }
            .row { display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px; }
            .row .left, .row .right { flex: 1; }
            .input-row { display: flex; }
            .input-row .left { flex: 1; }
            .input-row .right { flex: 1; }
          </style>
        </head>
        <body>
      `);

      printWindow.document.write(`
        <div class="header">
          <div><p>COPIFOR DE DAROU NGARAF</p></div>
          <div><p>Période du ${startDate} au ${endDate}</p></div>
        </div>
      `);

      printWindow.document.write(
        "<h1>Rapport Mensuel d'autres informations</h1>"
      );

      if (aggregatedInfosList.length > 0) {
        aggregatedInfosList.forEach((item) => {
          printWindow.document.write(`
            <div class="content">
              <div class="row">
                <div class="left"><p>${item.title_left}</p></div>
                <div class="right"><p>${item.title_right}</p></div>
              </div>
              <div class="input-row">
                <div class="left"><p>${item.input_field}</p></div>
                <div class="right"></div>
              </div>
              <div class="row">
                <div class="left"><p>${item.bottom_title}</p></div>
              </div>
            </div>
          `);
        });
      } else {
        printWindow.document.write(`
          <p>Aucune donnée disponible</p>
        `);
      }

      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      toast.success("Rapport imprimé avec succès.");
    } catch (error) {
      console.error("Échec de l'impression:", error);
      toast.error("Échec de l'impression du rapport. Veuillez réessayer.");
    }
  };

  const columnsInfos = [
    {
      field: "selectedDate",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "title_left",
      headerName: "Titre",
      flex: 1,
    },
    {
      field: "title_right",
      headerName: "Signature",
      flex: 1,
    },
    {
      field: "input_field",
      headerName: "Autres informations / commentaires",
      flex: 1,
    },
    {
      field: "bottom_title",
      headerName: "Titre bas de page",
      flex: 1,
    },
  ];
  
    const CustomNoRowsOverlay = () => (
	  <GridOverlay>
		<Typography>Aucune donnée disponible</Typography>
	  </GridOverlay>
	);

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Liste Autres Informations
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="secondary"
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginTop: "1px",
              marginRight: "5px",
            }}
            onClick={handlePrint}
            disabled={isFetchingAutresInfos || !isDataReadyAutresInfos}
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Télécharger le rapport autres informations
          </Button>
          <TextField
            id="start-date"
            label="Période de début"
            type="date"
            value={convertToYYYYMMDD(startDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setStartDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="end-date"
            label="Période de fin"
            type="date"
            value={convertToYYYYMMDD(endDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setEndDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </Box>
      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={aggregatedInfosList}
          columns={columnsInfos}
          getRowId={(row) => row.id}
          loading={isFetchingAutresInfos}
		  components={{
			NoRowsOverlay: CustomNoRowsOverlay,
		  }}		  
        />
      </Box>
    </Box>
  );
};

export default InfosList;
