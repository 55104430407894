// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Box, useTheme } from "@mui/material";
// import Header from "components/Header";
// import { ResponsiveChoropleth } from "@nivo/geo";
// import { geoData } from "state/geoData";

// // Country name to ISO 3166-1 alpha-3 code mapping
// const countryNameToCode = {
//   Senegal: "SEN",
//   // Add other country mappings here
// };

// const Geography = () => {
//   const theme = useTheme();
//   const [data, setData] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get("https://gestionforage.terangapp.com/api/clients");
//         const clients = response.data;
//         console.log("cli", clients);

//         // Aggregate clients data by country code
//         const aggregatedData = clients.reduce((acc, client) => {
//           const countryCode = countryNameToCode[client.country];
//           if (!countryCode) return acc; // Skip if no code mapping found
//           if (!acc[countryCode]) acc[countryCode] = 0;
//           acc[countryCode] += 1;
//           return acc;
//         }, {});

//         console.log("AggData", aggregatedData);
//         const formattedData = Object.entries(aggregatedData).map(
//           ([id, value]) => ({
//             id,
//             value,
//           })
//         );
//         console.log("ForData", formattedData);

//         setData(formattedData);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (isLoading) return "Loading...";

//   return (
//     <Box m="1.5rem 2.5rem">
//       <Header title="GEOGRAPHY" subtitle="Find where your users are located." />
//       <Box
//         mt="40px"
//         height="75vh"
//         border={`1px solid ${theme.palette.secondary[200]}`}
//         borderRadius="4px"
//       >
//         {data.length > 0 ? (
//           <ResponsiveChoropleth
//             data={data}
//             theme={{
//               axis: {
//                 domain: {
//                   line: {
//                     stroke: theme.palette.secondary[200],
//                   },
//                 },
//                 legend: {
//                   text: {
//                     fill: theme.palette.secondary[200],
//                   },
//                 },
//                 ticks: {
//                   line: {
//                     stroke: theme.palette.secondary[200],
//                     strokeWidth: 1,
//                   },
//                   text: {
//                     fill: theme.palette.secondary[200],
//                   },
//                 },
//               },
//               legends: {
//                 text: {
//                   fill: theme.palette.secondary[200],
//                 },
//               },
//               tooltip: {
//                 container: {
//                   color: theme.palette.primary.main,
//                 },
//               },
//             }}
//             features={geoData.features}
//             margin={{ top: 0, right: 0, bottom: 0, left: -50 }}
//             domain={[0, Math.max(...data.map((d) => d.value))]}
//             unknownColor="#666666"
//             label="properties.name"
//             valueFormat=".2s"
//             projectionScale={150}
//             projectionTranslation={[0.45, 0.6]}
//             projectionRotation={[0, 0, 0]}
//             borderWidth={1.3}
//             borderColor="#ffffff"
//             legends={[
//               {
//                 anchor: "bottom-right",
//                 direction: "column",
//                 justify: true,
//                 translateX: 0,
//                 translateY: -125,
//                 itemsSpacing: 0,
//                 itemWidth: 94,
//                 itemHeight: 18,
//                 itemDirection: "left-to-right",
//                 itemTextColor: theme.palette.secondary[200],
//                 itemOpacity: 0.85,
//                 symbolSize: 18,
//                 effects: [
//                   {
//                     on: "hover",
//                     style: {
//                       itemTextColor: theme.palette.background.alt,
//                       itemOpacity: 1,
//                     },
//                   },
//                 ],
//               },
//             ]}
//           />
//         ) : (
//           <>Pas de pays correspondant.</>
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default Geography;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, useTheme } from "@mui/material";
import Header from "components/Header";
import { ResponsiveChoropleth } from "@nivo/geo";
import { geoData } from "state/geoData";

// Country name to ISO 3166-1 alpha-3 code mapping
const countryNameToCode = {
  Sénégal: "SEN",
  // Add other country mappings here
};

const Geography = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://gestionforage.terangapp.com/api/clients");
        const clients = response.data;
        console.log("cli", clients);

        // Aggregate clients data by country code
        const aggregatedData = clients.reduce((acc, client) => {
          const countryCode = countryNameToCode[client.country];
          if (!countryCode) {
            console.warn(
              `No code mapping found for country: ${client.country}`
            );
            return acc; // Skip if no code mapping found
          }
          if (!acc[countryCode]) acc[countryCode] = 0;
          acc[countryCode] += 1;
          return acc;
        }, {});

        console.log("AggData", aggregatedData);
        const formattedData = Object.entries(aggregatedData).map(
          ([id, value]) => ({
            id,
            value,
          })
        );
        console.log("ForData", formattedData);

        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) return "Chargement...";

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="GEOGRAPHIE"
        subtitle="Trouvez où se trouvent vos utilisateurs."
      />
      <Box
        mt="40px"
        height="75vh"
        border={`1px solid ${theme.palette.secondary[200]}`}
        borderRadius="4px"
      >
        {data.length > 0 ? (
          <ResponsiveChoropleth
            data={data}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: theme.palette.secondary[200],
                  },
                },
                legend: {
                  text: {
                    fill: theme.palette.secondary[200],
                  },
                },
                ticks: {
                  line: {
                    stroke: theme.palette.secondary[200],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: theme.palette.secondary[200],
                  },
                },
              },
              legends: {
                text: {
                  fill: theme.palette.secondary[200],
                },
              },
              tooltip: {
                container: {
                  color: theme.palette.primary.main,
                },
              },
            }}
            features={geoData.features}
            margin={{ top: 0, right: 0, bottom: 0, left: -50 }}
            domain={[0, Math.max(...data.map((d) => d.value))]}
            unknownColor="#666666"
            label="properties.name"
            valueFormat=".2s"
            projectionScale={150}
            projectionTranslation={[0.45, 0.6]}
            projectionRotation={[0, 0, 0]}
            borderWidth={1.3}
            borderColor="#ffffff"
            legends={[
              {
                anchor: "bottom-right",
                direction: "column",
                justify: true,
                translateX: 0,
                translateY: -125,
                itemsSpacing: 0,
                itemWidth: 94,
                itemHeight: 18,
                itemDirection: "left-to-right",
                itemTextColor: theme.palette.secondary[200],
                itemOpacity: 0.85,
                symbolSize: 18,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: theme.palette.background.alt,
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        ) : (
          <>Pas de pays correspondant.</>
        )}
      </Box>
    </Box>
  );
};

export default Geography;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Box, useTheme } from "@mui/material";
// import Header from "components/Header";
// import { ResponsiveChoropleth } from "@nivo/geo";
// import { geoData } from "state/geoDataC";

// // Country name to ISO 3166-1 alpha-3 code mapping
// const countryNameToCode = {
//   Senegal: "SEN",
//   // Add other country mappings here
// };

// // Function to normalize country names by removing accents
// const normalizeCountryName = (name) => {
//   return name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
// };

// const Geography = () => {
//   const theme = useTheme();
//   const [data, setData] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get("https://gestionforage.terangapp.com/api/clients");
//         const clients = response.data;
//         console.log("cli", clients);

//         // Aggregate clients data by country code
//         const aggregatedData = clients.reduce((acc, client) => {
//           const normalizedCountry = normalizeCountryName(client.country);
//           const countryCode = countryNameToCode[normalizedCountry];
//           if (!countryCode) {
//             console.warn(
//               `No code mapping found for country: ${client.country}`
//             );
//             return acc; // Skip if no code mapping found
//           }
//           if (!acc[countryCode]) acc[countryCode] = 0;
//           acc[countryCode] += 1;
//           return acc;
//         }, {});

//         console.log("AggData", aggregatedData);
//         const formattedData = Object.entries(aggregatedData).map(
//           ([id, value]) => ({
//             id,
//             value,
//           })
//         );
//         console.log("ForData", formattedData);

//         setData(formattedData);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (isLoading) return "Loading...";

//   return (
//     <Box m="1.5rem 2.5rem">
//       <Header title="GEOGRAPHY" subtitle="Find where your users are located." />
//       <Box
//         mt="40px"
//         height="75vh"
//         border={`1px solid ${theme.palette.secondary[200]}`}
//         borderRadius="4px"
//       >
//         {data.length > 0 ? (
//           <ResponsiveChoropleth
//             data={data}
//             theme={{
//               axis: {
//                 domain: {
//                   line: {
//                     stroke: theme.palette.secondary[200],
//                   },
//                 },
//                 legend: {
//                   text: {
//                     fill: theme.palette.secondary[200],
//                   },
//                 },
//                 ticks: {
//                   line: {
//                     stroke: theme.palette.secondary[200],
//                     strokeWidth: 1,
//                   },
//                   text: {
//                     fill: theme.palette.secondary[200],
//                   },
//                 },
//               },
//               legends: {
//                 text: {
//                   fill: theme.palette.secondary[200],
//                 },
//               },
//               tooltip: {
//                 container: {
//                   color: theme.palette.primary.main,
//                 },
//               },
//             }}
//             features={geoData.features}
//             margin={{ top: 0, right: 0, bottom: 0, left: -50 }}
//             domain={[0, Math.max(...data.map((d) => d.value))]}
//             unknownColor="#666666"
//             label="properties.name"
//             valueFormat=".2s"
//             projectionScale={150}
//             projectionTranslation={[0.45, 0.6]}
//             projectionRotation={[0, 0, 0]}
//             borderWidth={1.3}
//             borderColor="#ffffff"
//             legends={[
//               {
//                 anchor: "bottom-right",
//                 direction: "column",
//                 justify: true,
//                 translateX: 0,
//                 translateY: -125,
//                 itemsSpacing: 0,
//                 itemWidth: 94,
//                 itemHeight: 18,
//                 itemDirection: "left-to-right",
//                 itemTextColor: theme.palette.secondary[200],
//                 itemOpacity: 0.85,
//                 symbolSize: 18,
//                 effects: [
//                   {
//                     on: "hover",
//                     style: {
//                       itemTextColor: theme.palette.background.alt,
//                       itemOpacity: 1,
//                     },
//                   },
//                 ],
//               },
//             ]}
//           />
//         ) : (
//           <>Pas de pays correspondant.</>
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default Geography;
