// clients/clients.jsx
import React from "react";
import AddCustomer from "./AddCustomer";
import { Box } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";

const Clients = () => {
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header
          title="CLIENTS"
          subtitle="Bienvenue dans l'interface d'ajout de client !"
        />
      </FlexBetween>
      <AddCustomer />
    </Box>
  );
};

export default Clients;
