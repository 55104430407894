import React, { useState } from "react";
import { Grid, TextField, Button, Container } from "@mui/material";
import { useSelector } from "react-redux";
import Header from "components/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

const RelevesIndicateurs = () => {
  const mode = useSelector((state) => state.global.mode);

  const [formData, setFormData] = useState({
    selectedDate: getCurrentDate(),
    indexCompteurHorairePompe: 0,
    indexCompteurEauPompe: 0,
    indexCompteurElectricite: 0,
    soldeCompteEpargneAsufor: 0,
    niveauStatiqueAvPompage: 0,
    selectedDateUnit: "jj/mm/aa",
    indexCompteurEauPompeUnit: "m3",
    indexCompteurHorairePompeUnit: "heures",
    indexCompteurElectriciteUnit: "kWh",
    soldeCompteEpargneAsuforUnit: "FCFA",
    niveauStatiqueAvPompageUnit: "m",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name.includes("Unit") ? value : Number(value),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        "https://gestionforage.terangapp.com/api/relevesindicateurs",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error(`Erreur HTTP! statut: ${response.status}`);
      }

      const data = await response.json();
      console.log("Form submitted:", data);

      toast.success("Formulaire soumis avec succès.");

      setFormData({
        selectedDate: getCurrentDate(),
        indexCompteurHorairePompe: 0,
        indexCompteurEauPompe: 0,
        indexCompteurElectricite: 0,
        soldeCompteEpargneAsufor: 0,
        niveauStatiqueAvPompage: 0,
        selectedDateUnit: "jj/mm/aa",
        indexCompteurEauPompeUnit: "m3",
        indexCompteurHorairePompeUnit: "heures",
        indexCompteurElectriciteUnit: "kWh",
        soldeCompteEpargneAsuforUnit: "FCFA",
        niveauStatiqueAvPompageUnit: "m",
      });
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire!", error);
      toast.error(
        "Erreur lors de la soumission du formulaire. Veuillez réessayer."
      );
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 1, ml: 3 }}>
      <Header
        title="Ajouter un relevé indicateur"
        subtitle="La valeur 0 est rempli par défaut"
        sx={{ mb: 5 }}
      />
      <Grid container spacing={3} sx={{ mt: 1 }} justifyContent="center">
        <Grid item xs={12} md={8}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Date"
                  type="date"
                  name="selectedDate"
                  value={formData.selectedDate}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Date Unit"
                  name="selectedDateUnit"
                  value={formData.selectedDateUnit}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              {[
                {
                  field: "indexCompteurHorairePompe",
                  label: "Index Compteur Horaire Pompe",
                },
                {
                  field: "indexCompteurEauPompe",
                  label: "Index Compteur Eau Pompe",
                },
                {
                  field: "indexCompteurElectricite",
                  label: "Index Compteur Électricité",
                },
                {
                  field: "soldeCompteEpargneAsufor",
                  label: "Solde Compte Epargne Asufor",
                },
                {
                  field: "niveauStatiqueAvPompage",
                  label: "Niveau Statique Avant Pompage",
                },
              ].map(({ field, label }) => (
                <React.Fragment key={field}>
                  <Grid item xs={6}>
                    <TextField
                      label={label}
                      type="number"
                      name={field}
                      value={formData[field]}
                      onChange={handleChange}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label={`${label} Unit`}
                      name={`${field}Unit`}
                      value={formData[`${field}Unit`]}
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color={mode === "dark" ? "secondary" : "primary"}
                  size="large"
                  sx={{
                    width: "100%",
                  }}
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RelevesIndicateurs;
