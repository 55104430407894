import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, Container } from "@mui/material";
import { useSelector } from "react-redux";
import Header from "components/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

const ChargesFinancieres = () => {
  const mode = useSelector((state) => state.global.mode);

  const [formData, setFormData] = useState({
    selectedDate: getCurrentDate(),
    versemement: 0,
    depensesSysteme: 0,
    depensesRxDistribution: 0,
    depensesSystemeExhaure: 0,
    total: 0,
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      total:
        prevData.versemement +
        prevData.depensesSysteme +
        prevData.depensesRxDistribution +
        prevData.depensesSystemeExhaure,
    }));
  }, [
    formData.versemement,
    formData.depensesSysteme,
    formData.depensesRxDistribution,
    formData.depensesSystemeExhaure,
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value === "" ? "" : Number(value),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        "https://gestionforage.terangapp.com/api/chargesfinancieres",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error(`Erreur HTTP! statut: ${response.status}`);
      }

      const data = await response.json();
      console.log("Form submitted:", data);

      toast.success("Formulaire soumis avec succès.");

      setFormData({
        selectedDate: getCurrentDate(),
        versemement: 0,
        depensesSysteme: 0,
        depensesRxDistribution: 0,
        depensesSystemeExhaure: 0,
        total: 0,
      });
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire!", error);
      toast.error(
        "Erreur lors de la soumission du formulaire. Veuillez réessayer."
      );
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 2, ml: 3 }}>
      <Header
        title="Ajouter une donnée financière"
        subtitle="Les valeurs 0 sont remplies par défaut"
        sx={{ mb: 5 }}
      />
      <Grid container spacing={2} sx={{ mt: 4 }} justifyContent="center">
        <Grid item xs={8}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Date"
                  type="date"
                  name="selectedDate"
                  value={formData.selectedDate}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {[
                "versemement",
                "depensesSysteme",
                "depensesRxDistribution",
                "depensesSystemeExhaure",
                "total",
              ].map((field) => (
                <Grid item xs={6} key={field}>
                  <TextField
                    label={field.replace(/([A-Z])/g, " $1").trim()}
                    type="number"
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    required
                    fullWidth
                    InputProps={field === "total" ? { readOnly: true } : {}}
                    disabled={field === "total"}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color={mode === "dark" ? "secondary" : "primary"}
                  size="large"
                  sx={{
                    width: "100%",
                  }}
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChargesFinancieres;
