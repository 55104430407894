import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, Container } from "@mui/material";
import { useSelector } from "react-redux";
import Header from "components/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

const SortieCaisse = () => {
  const mode = useSelector((state) => state.global.mode);

  const [formData, setFormData] = useState({
    selectedDate: getCurrentDate(),
    achatGazoil: 0,
    transport: 0,
    verification: 0,
    reparation: 0,
    materielsElectriques: 0,
    fraisTransfert: 0,
    taxes: 0,
    salaires: 0,
    indemnites: 0,
    depannageRxInst: 0,
    depensesAlimentation: 0,
    depensesNettoiements: 0,
    divers: 0,
    total: 0,
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      total:
        prevData.achatGazoil +
        prevData.transport +
        prevData.verification +
        prevData.reparation +
        prevData.materielsElectriques +
        prevData.fraisTransfert +
        prevData.taxes +
        prevData.salaires +
        prevData.indemnites +
        prevData.depannageRxInst +
        prevData.depensesAlimentation +
        prevData.depensesNettoiements +
        prevData.divers,
    }));
  }, [
    formData.achatGazoil,
    formData.transport,
    formData.verification,
    formData.reparation,
    formData.materielsElectriques,
    formData.fraisTransfert,
    formData.taxes,
    formData.salaires,
    formData.indemnites,
    formData.depannageRxInst,
    formData.depensesAlimentation,
    formData.depensesNettoiements,
    formData.divers,
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value === "" ? "" : Number(value),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch("https://gestionforage.terangapp.com/api/sortiecaisse", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP! statut: ${response.status}`);
      }

      const data = await response.json();
      console.log("Form submitted:", data);

      toast.success("Formulaire soumis avec succès.");

      setFormData({
        selectedDate: getCurrentDate(),
        achatGazoil: 0,
        transport: 0,
        verification: 0,
        reparation: 0,
        materielsElectriques: 0,
        fraisTransfert: 0,
        taxes: 0,
        salaires: 0,
        indemnites: 0,
        depannageRxInst: 0,
        depensesAlimentation: 0,
        depensesNettoiements: 0,
        divers: 0,
        total: 0,
      });
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire!", error);
      toast.error(
        "Erreur lors de la soumission du formulaire. Veuillez réessayer."
      );
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 2, ml: 3 }}>
      <Header
        title="Ajouter une donnée financière"
        subtitle="Les valeurs 0 sont remplies par défaut"
        sx={{ mb: 5 }}
      />
      <Grid container spacing={2} sx={{ mt: 4 }} justifyContent="center">
        <Grid item xs={8}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Date"
                  type="date"
                  name="selectedDate"
                  value={formData.selectedDate}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {[
                "achatGazoil",
                "transport",
                "verification",
                "reparation",
                "materielsElectriques",
                "fraisTransfert",
                "taxes",
                "salaires",
                "indemnites",
                "depannageRxInst",
                "depensesAlimentation",
                "depensesNettoiements",
                "divers",
                "total",
              ].map((field) => (
                <Grid item xs={6} key={field}>
                  <TextField
                    label={field.replace(/([A-Z])/g, " $1").trim()}
                    type="number"
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    required
                    fullWidth
                    InputProps={field === "total" ? { readOnly: true } : {}}
                    disabled={field === "total"}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color={mode === "dark" ? "secondary" : "primary"}
                  size="large"
                  sx={{
                    width: "100%",
                  }}
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SortieCaisse;
