import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Typography,
  IconButton,
  useTheme,
  Box,
  InputBase,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DownloadOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import Header from "components/Header";
import { Print, Search } from "@mui/icons-material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

const getFirstDayOfMonth = () => {
  const date = new Date();
  date.setDate(1);
  return date.toISOString().split("T")[0];
};

const getLastDayOfMonth = () => {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  date.setDate(0);
  return date.toISOString().split("T")[0];
};

const useFetchAggregatedTransactions = (startDate, endDate) => {
  const [aggregatedTransactions, setAggregatedTransactions] = useState([]);
  const [total, setTotal] = useState(null);
  const [isFetchingTransactions, setIsFetchingTransactions] = useState(false);
  const [isDataReadyTransactions, setIsDataReadyTransactions] = useState(false);

  const fetchAggregatedTransactions = useCallback(async () => {
    if (!startDate || !endDate) {
      toast.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetchingTransactions(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/transactions/map",
        {
          params: { startDate, endDate },
        }
      );
      const dataWithIds = response.data.rows.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setAggregatedTransactions(dataWithIds);
      setTotal(response.data.total);
      setIsDataReadyTransactions(true);
    } catch (error) {
      console.error("Échec de la récupération des données agrégées:", error);
      toast.error(
        "Échec de la récupération des données agrégées. Veuillez réessayer."
      );
      setIsDataReadyTransactions(false);
    }
    setIsFetchingTransactions(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchAggregatedTransactions();
  }, [startDate, endDate, fetchAggregatedTransactions]);

  return {
    aggregatedTransactions,
    total,
    isFetchingTransactions,
    isDataReadyTransactions,
  };
};

const TransactionBanqueForm = () => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);
  const [banque, setBanque] = useState([]);
  const [numeroCompteBancaire, setNumeroCompteBancaire] = useState("");
  const [natureCompte, setNatureCompte] = useState("");
  const [versement, setVersement] = useState("");
  const [retrait, setRetrait] = useState("");
  const [banqueId, setBanqueId] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [showTransactions, setShowTransactions] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  const [endDate, setEndDate] = useState(getLastDayOfMonth());
  const {
    aggregatedTransactions,
    total,
    isFetchingTransactions,
    isDataReadyTransactions,
  } = useFetchAggregatedTransactions(startDate, endDate);

  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const [selectedDate, setSelectedDate] = useState(getCurrentDate());

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(
          "https://gestionforage.terangapp.com/api/transactions"
        );
        setTransactions(response.data);
        setFilteredTransactions(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Échec de la récupération des données de banque:", error);
      }
    };

    fetchTransactions();
  }, []);

  useEffect(() => {
    const fetchBanque = async () => {
      try {
        const response = await axios.get("https://gestionforage.terangapp.com/api/banque");
        setBanque(response.data);
      } catch (error) {
        console.error("Échec de la récupération des données de banque:", error);
      }
    };

    fetchBanque();
  }, []);

  const handleNumeroCompteBancaireChange = async (e) => {
    const selectedBankNumber = e.target.value;
    setNumeroCompteBancaire(selectedBankNumber);
    const banques = banque.find(
      (c) => c.numeroCompteBancaire === selectedBankNumber
    );
    if (banques) {
      setNatureCompte(banques.natureCompte);
      setVersement("0");
      setRetrait("0");
      setBanqueId(banques.id);
    }
  };

  const handleSubmitTransactions = async (event) => {
    event.preventDefault();

    if (!numeroCompteBancaire || !selectedDate || !natureCompte) {
      toast.error("Veuillez remplir tous les champs obligatoires.");
      return;
    }

    const banqueData = {
      numeroCompteBancaire,
      selectedDate,
      natureCompte,
      versement: parseFloat(versement),
      retrait: parseFloat(retrait),
      banqueId,
    };

    try {
      await fetch("https://gestionforage.terangapp.com/api/transactions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(banqueData),
      });

      setVersement("0");
      setRetrait("0");

      toast.success("Formulaire soumis avec succès !");

      // Fetch updated transactions data
      const updatedTransactions = await axios.get(
        "https://gestionforage.terangapp.com/api/transactions"
      );

      // Update local state with new data
      setTransactions(updatedTransactions.data);
      setFilteredTransactions(updatedTransactions.data);

      // Optionally, you can reset startDate and endDate to fetch updated aggregated data
      setStartDate(getFirstDayOfMonth());
      setEndDate(getLastDayOfMonth());

      // Display toast or perform any other actions as needed
    } catch (error) {
      console.error(
        "Il y a eu une erreur lors de la soumission du formulaire !",
        error
      );
      toast.error(
        "Il y a eu une erreur lors de la soumission du formulaire. Veuillez réessayer."
      );
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredTransactions(
      transactions.filter((row) =>
        [
          "numeroCompteBancaire",
          "selectedDate",
          "natureCompte",
          "versement",
          "retrait",
          "solde",
        ].some((field) => row[field].toString().toLowerCase().includes(query))
      )
    );
  };

  const handlePrint = async () => {
    if (!isDataReadyTransactions) {
      toast.error(
        "Les données ne sont pas prêtes. Veuillez sélectionner une plage de dates et réessayer."
      );
      return;
    }

    try {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
        <head>
          <title>Rapport Transactionnel</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 60px; }
            .header { display: flex; justify-content: space-between; align-items: center; }
            .header p { margin: 0; }
            h1 { text-align: center; }
            h2 { text-align: left; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            th, td { border: 1px solid #000; padding: 8px; }
            th { background-color: #f2f2f2; }
            .content { margin-top: 20px; }
            .row { display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px; }
            .row .left, .row .right { flex: 1; }
            .input-row { display: flex; }
            .input-row .left { flex: 1; }
            .input-row .right { flex: 1; }           
          </style>
        </head>
        <body>
      `);

      printWindow.document.write(`
        <div class="header">
          <div><p>COPIFOR DE DAROU NGARAF</p></div>
          <div><p>Période du ${startDate} au ${endDate}</p></div>
        </div>
      `);

      printWindow.document.write("<h1>Rapport Mensuel de Transactions</h1>");
      printWindow.document.write("<h2>Tableau de transactions du mois</h2>");
      printWindow.document.write("<table border='1'>");
      printWindow.document.write(`
      <tr>
        <th>Numéro de Compte Bancaire</th>
        <th>Versement</th>
        <th>Retrait</th>
      </tr>
    `);

      if (aggregatedTransactions.length > 0) {
        aggregatedTransactions.forEach((item) => {
          printWindow.document.write(`
          <tr>
            <td>${item.numeroCompteBancaire}</td>
            <td>${item.versement}</td>
            <td>${item.retrait}</td>
          </tr>
        `);
        });
        printWindow.document.write(`
        <tr>
          <td><strong>Total</strong></td>
          <td>${total.versement}</td>
          <td>${total.retrait}</td>
        </tr>
      `);
      } else {
        printWindow.document.write(`
        <tr><td colspan="4">Aucune donnée disponible</td></tr>
      `);
      }

      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      toast.success("Rapport transactionnel imprimé avec succès.");
    } catch (error) {
      console.error("Failed to print:", error);
      toast.error(
        "Échec de l'impression du rapport transactionnel. Veuillez réessayer."
      );
    }
  };

  const columnsBanques = [
    { field: "id", headerName: "ID", width: 40 },
    { field: "selectedDate", headerName: "Date", width: 90 },
    {
      field: "numeroCompteBancaire",
      headerName: "Numéro Compte Bancaire",
      width: 150,
    },
    { field: "natureCompte", headerName: "Nature Compte", width: 150 },
    { field: "versement", headerName: "Versement", width: 150 },
    { field: "retrait", headerName: "Retrait", width: 120 },
    { field: "solde", headerName: "Solde", width: 100 },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 130,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Print />}
          label="Print"
          onClick={() => handlePrint(params.row)}
        />,
      ],
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Gestion des Transactions Bancaires"
          subtitle="Ajouter et suivre les mouvements bancaires"
          sx={{ mb: 2 }}
        />
        <Box display="flex" alignItems="center" mb="20px">
          <InputBase
            placeholder="Rechercher…"
            value={searchQuery}
            onChange={handleSearch}
            sx={{ ml: 1, flex: 1 }}
            inputProps={{ "aria-label": "search" }}
          />
          <IconButton type="submit" sx={{ p: 1 }} aria-label="search">
            <Search />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Button
          variant="contained"
          color="secondary"
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            marginTop: "4px",
            marginRight: "5px",
          }}
          onClick={handlePrint}
          disabled={isFetchingTransactions || !isDataReadyTransactions}
        >
          <DownloadOutlined sx={{ mr: "10px" }} />
          Télécharger le rapport transactionnel
        </Button>
        <TextField
          id="start-date"
          label="Période de début"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="end-date"
          label="Période de fin"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <Grid container spacing={3} sx={{ mt: 4 }}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmitTransactions} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  select
                  label="Numéro Compte Bancaire"
                  value={numeroCompteBancaire}
                  onChange={handleNumeroCompteBancaireChange}
                  required
                  fullWidth
                >
                  {banque.map((banques) => (
                    <MenuItem
                      key={banques.id}
                      value={banques.numeroCompteBancaire}
                    >
                      {banques.numeroCompteBancaire}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Nature Compte"
                  value={natureCompte}
                  onChange={(e) => setNatureCompte(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Versement"
                  value={versement}
                  onChange={(e) => setVersement(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  label="Retrait"
                  value={retrait}
                  onChange={(e) => setRetrait(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Date"
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color={mode === "dark" ? "secondary" : "primary"}
                  size="large"
                  sx={{
                    width: "100%",
                  }}
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        {/* Display Transactions */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Transactions Bancaires
            <IconButton
              onClick={() => setShowTransactions(!showTransactions)}
              sx={{ ml: 1 }}
            >
              {showTransactions ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Typography>
          <Box
            mt="40px"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: theme.palette.primary.light,
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
                borderTop: "none",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${theme.palette.secondary[200]} !important`,
              },
            }}
          >
            {showTransactions && (
              <DataGrid
                loading={isLoading}
                rows={[...filteredTransactions].sort((a, b) => {
                  // Sort by selectedDate (descending)
                  if (a.selectedDate < b.selectedDate) return 1;
                  if (a.selectedDate > b.selectedDate) return -1;
                  // If selectedDate is the same, sort by id (ascending)
                  return b.id - a.id;
                })}
                columns={columnsBanques}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransactionBanqueForm;

// import React, { useState, useEffect } from "react";
// import {
//   Grid,
//   TextField,
//   Button,
//   MenuItem,
//   Typography,
//   IconButton,
//   useTheme,
//   Box,
//   InputBase,
// } from "@mui/material";
// import { useSelector } from "react-redux";
// import axios from "axios";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
// import Header from "components/Header";
// import { Print, Search } from "@mui/icons-material";
// import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

// const TransactionBanqueForm = () => {
//   const theme = useTheme();
//   const mode = useSelector((state) => state.global.mode);
//   const [banque, setBanque] = useState([]);
//   const [numeroCompteBancaire, setNumeroCompteBancaire] = useState("");
//   const [natureCompte, setNatureCompte] = useState("");
//   const [versement, setVersement] = useState("");
//   const [retrait, setRetrait] = useState("");
//   const [banqueId, setBanqueId] = useState(null);
//   const [transactions, setTransactions] = useState([]);
//   const [showTransactions, setShowTransactions] = useState(true);
//   const [isLoading, setIsLoading] = useState(true);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [filteredTransactions, setFilteredTransactions] = useState([]);

//   const getCurrentDate = () => {
//     const today = new Date();
//     return today.toISOString().split("T")[0];
//   };

//   const [selectedDate, setSelectedDate] = useState(getCurrentDate());

//   useEffect(() => {
//     const fetchTransactions = async () => {
//       try {
//         const response = await axios.get(
//           "https://gestionforage.terangapp.com/api/transactions"
//         );
//         setTransactions(response.data);
//         setFilteredTransactions(response.data);
//         setIsLoading(false);
//       } catch (error) {
//         console.error("Échec de la récupération des données de banque:", error);
//       }
//     };

//     fetchTransactions();
//   }, []);

//   useEffect(() => {
//     const fetchBanque = async () => {
//       try {
//         const response = await axios.get("https://gestionforage.terangapp.com/api/banque");
//         setBanque(response.data);
//       } catch (error) {
//         console.error("Échec de la récupération des données de banque:", error);
//       }
//     };

//     fetchBanque();
//   }, []);

//   const handleNumeroCompteBancaireChange = async (e) => {
//     const selectedBankNumber = e.target.value;
//     setNumeroCompteBancaire(selectedBankNumber);
//     const banques = banque.find(
//       (c) => c.numeroCompteBancaire === selectedBankNumber
//     );
//     if (banques) {
//       setNatureCompte(banques.natureCompte);
//       setVersement("0");
//       setRetrait("0");
//       setBanqueId(banques.id);
//     }
//   };

//   const handleSubmitTransactions = async (event) => {
//     event.preventDefault();

//     if (!numeroCompteBancaire || !selectedDate || !natureCompte) {
//       toast.error("Veuillez remplir tous les champs obligatoires.");
//       return;
//     }

//     const banqueData = {
//       numeroCompteBancaire,
//       selectedDate,
//       natureCompte,
//       versement: parseFloat(versement),
//       retrait: parseFloat(retrait),
//       banqueId,
//     };

//     try {
//       await fetch("https://gestionforage.terangapp.com/api/transactions", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(banqueData),
//       });

//       setVersement("0");
//       setRetrait("0");

//       toast.success("Formulaire soumis avec succès !");
//       // Fetch updated transactions
//       const updatedTransactions = await axios.get(
//         "https://gestionforage.terangapp.com/api/transactions"
//       );
//       setTransactions(updatedTransactions.data);
//     } catch (error) {
//       console.error(
//         "Il y a eu une erreur lors de la soumission du formulaire !",
//         error
//       );
//     }
//   };

//   const handleSearch = (e) => {
//     const query = e.target.value.toLowerCase();
//     setSearchQuery(query);
//     setFilteredTransactions(
//       transactions.filter((row) =>
//         [
//           "numeroCompteBancaire",
//           "selectedDate",
//           "natureCompte",
//           "versement",
//           "retrait",
//           "solde",
//         ].some((field) => row[field].toString().toLowerCase().includes(query))
//       )
//     );
//   };

//   const handlePrint = (row) => {
//     const printWindow = window.open("", "_blank");
//     printWindow.document.write(`
//       <html>
//         <head>
//           <title>Receipt</title>
//           <style>
//             body {
//               font-family: Arial, sans-serif;
//               margin: 20px;
//               padding: 20px;
//               color: #333;
//               background-color: #f5f5f5;
//             }
//             h1 {
//               text-align: center;
//               color: #444;
//             }
//             .container {
//               display: flex;
//               flex-wrap: wrap;
//               justify-content: center;
//               margin-top: 20px;
//             }
//             .column {
//               width: 45%;
//               padding: 20px;
//               background-color: #fff;
//               box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//               border-radius: 8px;
//               margin: 10px;
//               text-align: center;
//             }
//             .column h2 {
//               border-bottom: 2px solid #444;
//               padding-bottom: 10px;
//               color: #444;
//             }
//             .column p {
//               margin: 10px 0;
//               font-size: 14px;
//             }
//             .footer {
//               text-align: center;
//               margin-top: 20px;
//               font-size: 12px;
//               color: #777;
//             }
//           </style>
//         </head>
//         <body>
//           <h1>Reçu</h1>
//           <div class="container">
//             <div class="column">
//               <h2>Information Transactionnel</h2>
//               <p>Compte Bancaire: ${row.numeroCompteBancaire}</p>
//               <p>Nature Compte: ${row.natureCompte}</p>
//             </div>
//             <div class="column">
//               <h2>Transaction</h2>
//               <p>Date: ${row.selectedDate}</p>
//               <p>Versement: ${row.versement}</p>
//               <p>Retrait: ${row.retrait}</p>
//               <p>Solde: ${row.solde}</p>
//             </div>
//           </div>
//           <div class="footer">
//             <p>Nos transactions. COPIFOR DE DAROU NGARAF.</p>
//           </div>
//         </body>
//       </html>
//     `);
//     printWindow.document.close();
//     printWindow.print();
//   };

//   const columns = [
//     { field: "id", headerName: "ID", width: 40 },
//     { field: "selectedDate", headerName: "Date", width: 90 },
//     {
//       field: "numeroCompteBancaire",
//       headerName: "Numéro Compte Bancaire",
//       width: 150,
//     },
//     { field: "natureCompte", headerName: "Nature Compte", width: 150 },
//     { field: "versement", headerName: "Versement", width: 150 },
//     { field: "retrait", headerName: "Retrait", width: 120 },
//     { field: "solde", headerName: "Solde", width: 100 },
//     {
//       field: "actions",
//       headerName: "Actions",
//       type: "actions",
//       width: 130,
//       getActions: (params) => [
//         <GridActionsCellItem
//           icon={<Print />}
//           label="Print"
//           onClick={() => handlePrint(params.row)}
//         />,
//       ],
//     },
//   ];

//   return (
//     <Box m="1.5rem 2.5rem">
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Header
//           title="Gestion des Transactions Bancaires"
//           subtitle="Ajouter et suivre les mouvements bancaires"
//           sx={{ mb: 2 }}
//         />
//         <Box display="flex" alignItems="center" mb="20px">
//           <InputBase
//             placeholder="Rechercher…"
//             value={searchQuery}
//             onChange={handleSearch}
//             sx={{ ml: 1, flex: 1 }}
//             inputProps={{ "aria-label": "search" }}
//           />
//           <IconButton type="submit" sx={{ p: 1 }} aria-label="search">
//             <Search />
//           </IconButton>
//         </Box>
//       </Box>
//       <Grid container spacing={3} sx={{ mt: 4 }}>
//         <Grid item xs={12}>
//           <form onSubmit={handleSubmitTransactions} noValidate>
//             <Grid container spacing={2}>
//               <Grid item xs={12} sm={4}>
//                 <TextField
//                   select
//                   label="Numéro Compte Bancaire"
//                   value={numeroCompteBancaire}
//                   onChange={handleNumeroCompteBancaireChange}
//                   required
//                   fullWidth
//                 >
//                   {banque.map((banques) => (
//                     <MenuItem
//                       key={banques.id}
//                       value={banques.numeroCompteBancaire}
//                     >
//                       {banques.numeroCompteBancaire}
//                     </MenuItem>
//                   ))}
//                 </TextField>
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <TextField
//                   label="Nature Compte"
//                   value={natureCompte}
//                   onChange={(e) => setNatureCompte(e.target.value)}
//                   required
//                   fullWidth
//                 />
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <TextField
//                   label="Versement"
//                   value={versement}
//                   onChange={(e) => setVersement(e.target.value)}
//                   required
//                   fullWidth
//                 />
//               </Grid>

//               <Grid item xs={12} sm={4}>
//                 <TextField
//                   label="Retrait"
//                   value={retrait}
//                   onChange={(e) => setRetrait(e.target.value)}
//                   required
//                   fullWidth
//                 />
//               </Grid>
//               <Grid item xs={12} sm={4}>
//                 <TextField
//                   label="Date"
//                   type="date"
//                   value={selectedDate}
//                   onChange={(e) => setSelectedDate(e.target.value)}
//                   required
//                   fullWidth
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <Button
//                   type="submit"
//                   variant="contained"
//                   color={mode === "dark" ? "secondary" : "primary"}
//                   size="large"
//                   sx={{
//                     width: "100%",
//                   }}
//                 >
//                   Ajouter
//                 </Button>
//               </Grid>
//             </Grid>
//           </form>
//         </Grid>
//         {/* Display Transactions */}
//         <Grid item xs={12}>
//           <Typography variant="h6" sx={{ mb: 2 }}>
//             Transactions Bancaires
//             <IconButton
//               onClick={() => setShowTransactions(!showTransactions)}
//               sx={{ ml: 1 }}
//             >
//               {showTransactions ? <VisibilityOff /> : <Visibility />}
//             </IconButton>
//           </Typography>
//           <Box
//             mt="40px"
//             height="75vh"
//             sx={{
//               "& .MuiDataGrid-root": {
//                 border: "none",
//               },
//               "& .MuiDataGrid-cell": {
//                 borderBottom: "none",
//               },
//               "& .MuiDataGrid-columnHeaders": {
//                 backgroundColor: theme.palette.background.alt,
//                 color: theme.palette.secondary[100],
//                 borderBottom: "none",
//               },
//               "& .MuiDataGrid-virtualScroller": {
//                 backgroundColor: theme.palette.primary.light,
//               },
//               "& .MuiDataGrid-footerContainer": {
//                 backgroundColor: theme.palette.background.alt,
//                 color: theme.palette.secondary[100],
//                 borderTop: "none",
//               },
//               "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//                 color: `${theme.palette.secondary[200]} !important`,
//               },
//             }}
//           >
//             {showTransactions && (
//               <DataGrid
//                 loading={isLoading}
//                 rows={[...filteredTransactions].sort((a, b) => {
//                   // Sort by selectedDate (descending)
//                   if (a.selectedDate < b.selectedDate) return 1;
//                   if (a.selectedDate > b.selectedDate) return -1;
//                   // If selectedDate is the same, sort by id (ascending)
//                   return b.id - a.id;
//                 })}
//                 columns={columns}
//               />
//             )}
//           </Box>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default TransactionBanqueForm;
