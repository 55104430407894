import React, { useState, useEffect, useCallback } from "react";
import { DataGrid, GridOverlay, } from "@mui/x-data-grid";
import { DownloadOutlined } from "@mui/icons-material";
import { Box, Button, useTheme, TextField, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Convertir une date de yyyy-MM-dd en dd-MM-yyyy
const formatDateToDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// Convertir une date de dd-MM-yyyy en yyyy-MM-dd
const convertToYYYYMMDD = (dateString) => {
  const [day, month, year] = dateString.split("-");
  return `${year}-${month}-${day}`;
};

// Récupérer le premier jour du mois précédent en dd-MM-yyyy
const getFirstDayOfMonth = () => {
  const currentDate = new Date();
  currentDate.setUTCMonth(currentDate.getUTCMonth() - 1); // Met au mois précédent en UTC
  currentDate.setUTCDate(1); // Met au 1er jour du mois précédent en UTC
  const firstDay = currentDate.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(firstDay); // Convertir en dd-MM-yyyy
};

// Récupérer le dernier jour du mois précédent en dd-MM-yyyy
const getLastDayOfMonth = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0)
  );
  const lastDayFormatted = lastDay.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(lastDayFormatted); // Convertir en dd-MM-yyyy
};

const useFetchAggregatedData = (startDate, endDate) => {
  const [aggregatedConsommations, setAggregatedConsommations] = useState([]);
  const [total, setTotal] = useState({ nombres: 0, nombreMd: 0, paidAmount: 0 });
  const [isFetching, setIsFetching] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);

  const fetchAggregatedData = useCallback(async () => {
    if (!startDate || !endDate) {
      toast.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetching(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/consommations/distribution",
        {
          params: {
            startDate: startDate, 
            endDate:endDate, 
          },
        }
      );
      const dataWithIds = response.data.rows.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setAggregatedConsommations(dataWithIds);
      setTotal(response.data.total);
      setIsDataReady(true);
    } catch (error) {
      console.error("Échec de la récupération des données agrégées:", error);
      toast.error("Échec de la récupération des données agrégées. Veuillez réessayer.");
      setIsDataReady(false);
    }
    setIsFetching(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchAggregatedData();
  }, [startDate, endDate, fetchAggregatedData]);

  return { aggregatedConsommations, total, isFetching, isDataReady };
};

const DistributionEau = () => {
  const theme = useTheme();
  const [startDate, setStartDate] = useState(getFirstDayOfMonth()); 
  const [endDate, setEndDate] = useState(getLastDayOfMonth()); 
  const { aggregatedConsommations, total, isFetching, isDataReady } = useFetchAggregatedData(startDate, endDate);

  const handlePrint = async () => {
    if (!isDataReady) {
      toast.error("Les données ne sont pas prêtes. Veuillez sélectionner une plage de dates et réessayer.");
      return;
    }

    try {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
        <head>
          <title>Rapport de Distribution</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 60px; }
            .header { display: flex; justify-content: space-between; align-items: center; }
            .header p { margin: 0; }
            h1 { text-align: center; }
            h2 { text-align: left; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            th, td { border: 1px solid #000; padding: 8px; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
      `);

      printWindow.document.write(`
        <div class="header">
          <div><p>COPIFOR DE DAROU NGARAF</p></div>
          <div><p>Période du ${startDate} au ${endDate}</p></div>
        </div>
      `);

      printWindow.document.write("<h1>Rapport Mensuel de Distribution</h1>");
      printWindow.document.write("<h2>Tableau de distribution d'eau du mois</h2>");
      printWindow.document.write("<table border='1'>");
      printWindow.document.write(`
        <tr>
          <th>Catégorie de points de distribution d'eau</th>
          <th>Nombre de points de distribution</th>
          <th>Volume d'eau distribué</th>
          <th>Recettes perçues</th>
        </tr>
      `);

      if (aggregatedConsommations.length > 0) {
        aggregatedConsommations.forEach((item) => {
          printWindow.document.write(`
            <tr>
              <td>${item.typeBranchement}</td>
              <td>${item.nombres}</td>
              <td>${item.nombreMd}</td>
              <td>${item.paidAmount}</td>
            </tr>
          `);
        });
        printWindow.document.write(`
          <tr>
            <td><strong>Total</strong></td>
            <td>${total.nombres}</td>
            <td>${total.nombreMd}</td>
            <td>${total.paidAmount}</td>
          </tr>
        `);
      } else {
        printWindow.document.write(`
          <tr><td colspan="4">Aucune donnée disponible</td></tr>
        `);
      }

      printWindow.document.write("</table>");
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      toast.success("Rapport de distribution imprimé avec succès.");
    } catch (error) {
      console.error("Échec de l'impression du rapport de distribution:", error);
      toast.error("Échec de l'impression du rapport de distribution. Veuillez réessayer.");
    }
  };

  const columns = [
    {
      field: "typeBranchement",
      headerName: "Catégorie de points de distribution d'eau",
      flex: 1,
    },
    {
      field: "nombres",
      headerName: "Nombre de points de distribution",
      flex: 1,
	  valueGetter: (params) => params.value || 0,
    },
    { field: "nombreMd", headerName: "Volume d'eau distribué", flex: 1, valueGetter: (params) => params.value || 0, },
    { field: "paidAmount", headerName: "Recettes perçues", flex: 1, valueGetter: (params) => params.value || 0, },
  ];
  
    const CustomNoRowsOverlay = () => (
	  <GridOverlay>
		<Typography>Aucune donnée disponible</Typography>
	  </GridOverlay>
	);

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Liste des Données de Distribution
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="secondary"
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginTop: "1px",
              marginRight: "5px",
            }}
            onClick={handlePrint}
            disabled={isFetching || !isDataReady}
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Télécharger le rapport de Distribution
          </Button>
          <TextField
            id="start-date"
            label="Période de début"
            type="date"
            value={convertToYYYYMMDD(startDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setStartDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="end-date"
            label="Période de fin"
            type="date"
            value={convertToYYYYMMDD(endDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setEndDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </Box>
      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={aggregatedConsommations}
          columns={columns}
          getRowId={(row) => row.id}
          loading={isFetching}
          components={{
            Footer: () => (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                padding="10px"
                bgcolor={theme.palette.background.alt}
              >
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  <strong>Total</strong>
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  {total ? `${total.nombres}` : "0"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  {total ? `${total.nombreMd}` : "0"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  {total ? `${total.paidAmount}` : "0"}
                </Typography>
              </Box>
            ),
			NoRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </Box>
    </Box>
  );
};

export default DistributionEau;


// import React, { useState, useEffect, useCallback } from "react";
// import { DataGrid } from "@mui/x-data-grid";
// import { DownloadOutlined } from "@mui/icons-material";
// import { Box, Button, useTheme, TextField, Typography } from "@mui/material";
// import axios from "axios";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const getFirstDayOfMonth = () => {
//   const currentDate = new Date();
//   currentDate.setUTCDate(1); // Set to the 1st day of the current month in UTC
//   const firstDay = currentDate.toISOString().split("T")[0];
//   return firstDay;
// };

// const getLastDayOfMonth = () => {
//   const currentDate = new Date();
//   const lastDay = new Date(
//     Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth() + 1, 0)
//   );
//   const lastDayFormatted = lastDay.toISOString().split("T")[0];
//   return lastDayFormatted;
// };
// const useFetchAggregatedData = (startDate, endDate) => {
//   const [aggregatedConsommations, setAggregatedConsommations] = useState([]);
//   const [total, setTotal] = useState(null);
//   const [isFetching, setIsFetching] = useState(false);
//   const [isDataReady, setIsDataReady] = useState(false);

//   const fetchAggregatedData = useCallback(async () => {
//     if (!startDate || !endDate) {
//       toast.error("Veuillez sélectionner une plage de dates.");
//       return;
//     }
//     setIsFetching(true);
//     try {
//       const response = await axios.get(
//         "https://gestionforage.terangapp.com/api/consommations/distribution",
//         {
//           params: { startDate, endDate },
//         }
//       );
//       const dataWithIds = response.data.rows.map((item, index) => ({
//         ...item,
//         id: index + 1,
//       }));
//       setAggregatedConsommations(dataWithIds);
//       setTotal(response.data.total);
//       setIsDataReady(true);
//     } catch (error) {
//       console.error("Échec de la récupération des données agrégées:", error);
//       toast.error(
//         "Échec de la récupération des données agrégées. Veuillez réessayer."
//       );
//       setIsDataReady(false);
//     }
//     setIsFetching(false);
//   }, [startDate, endDate]);

//   useEffect(() => {
//     fetchAggregatedData();
//   }, [startDate, endDate, fetchAggregatedData]);

//   return { aggregatedConsommations, total, isFetching, isDataReady };
// };

// const DistributionEau = () => {
//   const theme = useTheme();
//   const [startDate, setStartDate] = useState(getFirstDayOfMonth());
//   const [endDate, setEndDate] = useState(getLastDayOfMonth());
//   const { aggregatedConsommations, total, isFetching, isDataReady } =
//     useFetchAggregatedData(startDate, endDate);

//   const handlePrint = async () => {
//     if (!isDataReady) {
//       toast.error(
//         "Les données ne sont pas prêtes. Veuillez sélectionner une plage de dates et réessayer."
//       );
//       return;
//     }

//     try {
//       const printWindow = window.open("", "_blank");
//       printWindow.document.write(`
//         <html>
//         <head>
//           <title>Rapport Gestion</title>
//           <style>
//             body { font-family: Arial, sans-serif; margin: 60px; }
//             .header { display: flex; justify-content: space-between; align-items: center; }
//             .header p { margin: 0; }
//             h1 { text-align: center; }
//             h2 { text-align: left; }
//             table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
//             th, td { border: 1px solid #000; padding: 8px; }
//             th { background-color: #f2f2f2; }
//           </style>
//         </head>
//         <body>
//       `);

//       printWindow.document.write(`
//         <div class="header">
//           <div><p>COPIFOR DE DAROU NGARAF</p></div>
//           <div><p>Période du ${startDate} au ${endDate}</p></div>
//         </div>
//       `);

//       printWindow.document.write("<h1>Rapport Mensuel de Distribution</h1>");
//       printWindow.document.write(
//         "<h2>Tableau de distribution d'eau du mois</h2>"
//       );
//       printWindow.document.write("<table border='1'>");
//       printWindow.document.write(`
//         <tr>
//           <th>Catégorie de points de distribution d'eau</th>
//           <th>Nombre de points de distribution</th>
//           <th>Volume d'eau distribué</th>
//           <th>Recettes perçues</th>
//         </tr>
//       `);

//       if (aggregatedConsommations.length > 0) {
//         aggregatedConsommations.forEach((item) => {
//           printWindow.document.write(`
//             <tr>
//               <td>${item.typeBranchement}</td>
//               <td>${item.nombres}</td>
//               <td>${item.nombreMd}</td>
//               <td>${item.paidAmount}</td>
//             </tr>
//           `);
//         });
//         printWindow.document.write(`
//           <tr>
//             <td><strong>Total</strong></td>
//             <td>${total.nombres}</td>
//             <td>${total.nombreMd}</td>
//             <td>${total.paidAmount}</td>
//           </tr>
//         `);
//       } else {
//         printWindow.document.write(`
//           <tr><td colspan="4">Aucune donnée disponible</td></tr>
//         `);
//       }

//       printWindow.document.write("</table>");
//       printWindow.document.write("</body></html>");
//       printWindow.document.close();
//       printWindow.print();
//       toast.success("Rapport distribution imprimé avec succès.");
//     } catch (error) {
//       console.error("Failed to print:", error);
//       toast.error(
//         "Échec de l'impression du rapport de distribution. Veuillez réessayer."
//       );
//     }
//   };

//   const columns = [
//     {
//       field: "typeBranchement",
//       headerName: "Catégorie de points de distribution d'eau",
//       flex: 1,
//     },
//     {
//       field: "nombres",
//       headerName: "Nombre de points de distribution",
//       flex: 1,
//     },
//     { field: "nombreMd", headerName: "Volume d'eau distribué", flex: 1 },
//     { field: "paidAmount", headerName: "Recettes perçues", flex: 1 },
//   ];

//   return (
//     <Box m="20px">
//       <Box
//         display="flex"
//         justifyContent="space-between"
//         alignItems="center"
//         mb={2}
//       >
//         <Box display="flex" justifyContent="space-between" alignItems="center">
//           <Typography variant="h4" component="h1" gutterBottom>
//             Liste des Données de Distribution
//           </Typography>
//         </Box>
//         <Box display="flex" alignItems="center">
//           <Button
//             variant="contained"
//             color="secondary"
//             style={{
//               fontSize: "14px",
//               fontWeight: "bold",
//               padding: "10px 20px",
//               marginTop: "1px",
//               marginRight: "5px",
//             }}
//             onClick={handlePrint}
//             disabled={isFetching || !isDataReady}
//           >
//             <DownloadOutlined sx={{ mr: "10px" }} />
//             Télécharger le rapport de Distribution
//           </Button>
//           <TextField
//             id="start-date"
//             label="Période de début"
//             type="date"
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//           />
//           <TextField
//             id="end-date"
//             label="Période de fin"
//             type="date"
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//           />
//         </Box>
//       </Box>
//       <Box
//         mt="20px"
//         height="75vh"
//         sx={{
//           "& .MuiDataGrid-root": {
//             border: "none",
//           },
//           "& .MuiDataGrid-cell": {
//             borderBottom: "none",
//           },
//           "& .MuiDataGrid-virtualScroller": {
//             backgroundColor: theme.palette.primary.light,
//           },
//           "& .MuiDataGrid-footerContainer": {
//             borderTop: "none",
//             backgroundColor: theme.palette.background.alt,
//             color: theme.palette.secondary[100],
//           },
//           "& .MuiCheckbox-root": {
//             color: `${theme.palette.secondary[200]} !important`,
//           },
//         }}
//       >
//         <DataGrid
//           rows={aggregatedConsommations}
//           columns={columns}
//           getRowId={(row) => row.id}
//           loading={isFetching}
//           components={{
//             Footer: () => (
//               <Box
//                 display="flex"
//                 justifyContent="flex-start"
//                 alignItems="center"
//                 padding="10px"
//                 bgcolor={theme.palette.background.alt}
//               >
//                 <Typography
//                   variant="subtitle1"
//                   component="div"
//                   sx={{ flex: 1 }}
//                 >
//                   <strong>Total</strong>
//                 </Typography>
//                 <Typography
//                   variant="subtitle1"
//                   component="div"
//                   sx={{ flex: 1 }}
//                 >
//                   {total ? `${total.nombres}` : "0"}
//                 </Typography>
//                 <Typography
//                   variant="subtitle1"
//                   component="div"
//                   sx={{ flex: 1 }}
//                 >
//                   {total ? `${total.nombreMd}` : "0"}
//                 </Typography>
//                 <Typography
//                   variant="subtitle1"
//                   component="div"
//                   sx={{ flex: 1 }}
//                 >
//                   {total ? `${total.paidAmount}` : "0"}
//                 </Typography>
//               </Box>
//             ),
//           }}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default DistributionEau;
