import React, { useState, useEffect } from "react";
import Header from "components/Header";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Print, Edit, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  InputBase,
  IconButton,
} from "@mui/material";

const Factures = () => {
  const theme = useTheme();
  const [consommations, setConsommations] = useState([]);
  const [filteredConsommations, setFilteredConsommations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

const fetchConsommations = async () => {
  try {
    const response = await fetch("https://gestionforage.terangapp.com/api/consommations");
    if (!response.ok) throw new Error("Failed to fetch data");

    const data = await response.json();
    // Sort data by descending ID before setting state
    data.sort((a, b) => b.id - a.id);
	console.log("Data:", data);

    setConsommations(data);
    setFilteredConsommations(data);
    setIsLoading(false);
  } catch (error) {
    console.error("Failed to fetch consommations:", error);
    setIsLoading(false);
  }
};


useEffect(() => {
  fetchConsommations();
}, []);


  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredConsommations(
      consommations.filter((row) =>
        [
          "waterBillRefNumber",
          "selectedDate",
          "firstName",
          "lastName",
          "phoneNumber",
        ].some((field) => row[field]?.toString().toLowerCase().includes(query))
      )
    );
  };

	const handlePrint = (row) => {
	  const printWindow = window.open("", "_blank");
	  printWindow.document.write(`
		<html>
		  <head>
			<title>Receipt</title>
			<style>
			  body {
				font-family: Arial, sans-serif;
				margin: 20px;
				padding: 20px;
				color: #333;
				background-color: #f5f5f5;
			  }
			  h1 {
				text-align: center;
				color: #444;
			  }
			  .container {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin-top: 20px;
			  }
			  .column {
				width: 45%;
				padding: 20px;
				background-color: #fff;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
				border-radius: 8px;
				margin: 10px;
				text-align: center;
			  }
			  .column h2 {
				border-bottom: 2px solid #444;
				padding-bottom: 10px;
				color: #444;
			  }
			  .column p {
				margin: 10px 0;
				font-size: 14px;
			  }
			  .footer {
				text-align: center;
				margin-top: 20px;
				font-size: 12px;
				color: #777;
			  }
			</style>
		  </head>
		  <body>
			<h1>Reçu</h1>
			<div class="container">
			  <div class="column">
				<h2>Information du client</h2>
				<p>Propriétaire: ${row.firstName} ${row.lastName}</p>
				<p>Commune: ${row.state}</p>
				<p>Numéro de Téléphone: ${row.phoneNumber}</p>
			  </div>
			  <div class="column">
				<h2>Référence compteur</h2>
				<p>ID: ${row.id}</p>
				<p>Référence Facture Eau: ${row.waterBillRefNumber}</p>
				<p>Type Branchement: ${row.typeBranchement}</p>
			  </div>
			  <div class="column">
				<h2>Consommation</h2>
				<p>Date: ${row.selectedDate}</p>
				<p>Index Actuel: ${row.currentIndex}</p>
				<p>Index Précédent: ${row.previousIndex}</p>
			  </div>
			  <div class="column">
				<h2>Détails paiement</h2>
				<p>Consommation: ${row.consommation}</p>
				<p>Montant Payé: ${row.paidAmount}</p>
				<p>Statut de Paiement: ${row.paymentStatus}</p>
			  </div>
			</div>
			<div class="footer">
			  <p>Merci pour votre paiement. COPIFOR DE DAROU NGARAF.</p>
			</div>
		  </body>
		</html>
	  `);
	  printWindow.document.close();
	  printWindow.print();
	};


const handleEdit = (row) => {
  // Check if row.duePaymentDate is defined and is a string
  const duePaymentDate = row.duePaymentDate 
    ? row.duePaymentDate.split("T")[0] 
    : ""; // Provide a default value if needed

  // Update the state with the formatted date
  setSelectedRow({
    ...row,
    duePaymentDate: duePaymentDate,
  });

  // Open the edit dialog
  setOpenEditDialog(true);
};


const handleEditChange = (e) => {
    const { name, value } = e.target;
    let updatedRow = { ...selectedRow, [name]: value };

    if (["currentIndex", "previousIndex", "price"].includes(name)) {
        updatedRow[name] = parseFloat(value);
    }

    if (["currentIndex", "previousIndex", "price"].includes(name)) {
        updatedRow = {
            ...updatedRow,
            consommation: updatedRow.currentIndex - updatedRow.previousIndex,
            paidAmount:
                (updatedRow.currentIndex - updatedRow.previousIndex) *
                updatedRow.price,
            duePayment:
                (updatedRow.currentIndex - updatedRow.previousIndex) *
                updatedRow.price,
        };
    }

	if (["paidDate", "duePaymentDate"].includes(name)) {
		const dateParts = value.split("-");
		if (dateParts.length === 3) {
			const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`; // Convert to YYYY-MM-DD
			updatedRow = {
				...updatedRow,
				[name]: formattedDate,
			};

			if (name === "paidDate" && value) {
				updatedRow.paymentStatus = "payé";
			}
		}

		// Log paidDate and duePaymentDate
		console.log(`Updated ${name}:`, updatedRow[name]);
	}


    if (
        ["paidDate", "duePaymentDate", "latePayment", "finePrice"].includes(name) ||
        ["paidDate", "duePaymentDate"].includes(name)
    ) {
        const paidDateStr = updatedRow.paidDate || "";
        const duePaymentDateStr = updatedRow.duePaymentDate || "";

        const parseDate = (dateStr) => {
            const [day, month, year] = dateStr.split("-").map(Number);
            return new Date(year, month - 1, day);
        };

        const paidDate = parseDate(paidDateStr);
        const duePaymentDate = parseDate(duePaymentDateStr);

        const differenceMs = paidDate.getTime() - duePaymentDate.getTime();
        const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
        const latePayment = differenceDays < 0 ? 0 : differenceDays;

        updatedRow = {
            ...updatedRow,
            latePayment,
            fine: latePayment * (updatedRow.finePrice || 0),
        };

        // Log paidDate, duePaymentDate, and latePayment
        console.log("Paid Date:", paidDate);
        console.log("Due Payment Date:", duePaymentDate);
        console.log("Late Payment:", latePayment);
    }

    setSelectedRow(updatedRow);
};

const formatDate = (dateStr) => {
  if (!dateStr) return "";
  const [day, month, year] = dateStr.split("-").map(Number);
  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
};


const handleSubmitEdit = async () => {
  if (!selectedRow?.paidAmount) {
    alert("Veuillez entrer un montant payé.");
    return;
  }

  // Log the data being sent to the backend
  console.log("Submitting the following data to the backend:", selectedRow);

  try {
    // Perform the update
    const response = await fetch(
      `https://gestionforage.terangapp.com/api/consommations/${selectedRow.id}`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(selectedRow),
      }
    );

    if (!response.ok) throw new Error("Failed to update consommation");

    // Fetch the updated data
    await fetchConsommations();

    setOpenEditDialog(false);
    setSelectedRow(null);

  } catch (error) {
    console.error("Failed to update consommation:", error);
  }
};


  const columns = [
    { field: "id", headerName: "ID", width: 40 },
    { field: "selectedDate", headerName: "Date", width: 90 },
    { field: "firstName", headerName: "Nom Propriétaire", width: 150 },
    { field: "lastName", headerName: "Prénom Propriétaire", width: 150 },
    { field: "phoneNumber", headerName: "Téléphone", width: 150 },
    { field: "state", headerName: "Commune", width: 120 },
    { field: "typeBranchement", headerName: "Branchement", width: 100 },
    { field: "currentIndex", headerName: "Index Actuel", width: 90 },
    { field: "previousIndex", headerName: "Index Précédent", width: 120 },
    { field: "consommation", headerName: "Consommation", width: 100 },
    {
      field: "waterBillRefNumber",
      headerName: "Référence Facture Eau",
      width: 160,
    },
    { field: "paidAmount", headerName: "Montant Payé", width: 120 },
    { field: "duePaymentDate", headerName: "Date Paiement Dû", width: 120 },
    { field: "paidDate", headerName: "Date Paiement", width: 120 },
    { field: "finePrice", headerName: "Prix Amende", width: 120 },
    { field: "latePayment", headerName: "Retard Paiement", width: 120 },
    { field: "fine", headerName: "Amende", width: 130 },
    { field: "paymentStatus", headerName: "Statut de Paiement", width: 130 },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 130,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Print />}
          label="Print"
          onClick={() => handlePrint(params.row)}
        />,
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleEdit(params.row)}
        />,
      ],
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="FACTURES" subtitle="Liste des factures des clients" />
        <Box display="flex" alignItems="center" mb="20px">
          <InputBase
            placeholder="Rechercher…"
            value={searchQuery}
            onChange={handleSearch}
            sx={{ ml: 1, flex: 1 }}
            inputProps={{ "aria-label": "search" }}
          />
          <IconButton type="submit" sx={{ p: 1 }} aria-label="search">
            <Search />
          </IconButton>
        </Box>
      </Box>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading}
          rows={filteredConsommations}
          columns={columns}
        />
      </Box>

      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Modifier Consommation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Utilisez le formulaire ci-dessous pour modifier les détails de la
            consommation.
          </DialogContentText>
          <TextField
            margin="dense"
            name="firstName"
            label="Nom Propriétaire"
            fullWidth
            value={selectedRow?.firstName || ""}
            onChange={handleEditChange}
            disabled
          />
          <TextField
            margin="dense"
            name="lastName"
            label="Prénom Propriétaire"
            fullWidth
            value={selectedRow?.lastName || ""}
            onChange={handleEditChange}
            disabled
          />
          <TextField
            margin="dense"
            name="phoneNumber"
            label="Téléphone"
            fullWidth
            value={selectedRow?.phoneNumber || ""}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="state"
            label="Commune"
            fullWidth
            value={selectedRow?.state || ""}
            onChange={handleEditChange}
            disabled
          />
          <TextField
            margin="dense"
            name="typeBranchement"
            label="Type Branchement"
            fullWidth
            value={selectedRow?.typeBranchement || ""}
            onChange={handleEditChange}
            disabled
          />
          <TextField
            margin="dense"
            name="waterBillRefNumber"
            label="Référence Facture Eau"
            fullWidth
            value={selectedRow?.waterBillRefNumber || ""}
            onChange={handleEditChange}
            disabled
          />
          <TextField
            margin="dense"
            name="currentIndex"
            label="Index Actuel"
            type="number"
            fullWidth
            value={selectedRow?.currentIndex || ""}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="previousIndex"
            label="Index Précédent"
            type="number"
            fullWidth
            value={selectedRow?.previousIndex || ""}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="consommation"
            label="Consommation"
            type="number"
            fullWidth
            value={selectedRow?.consommation || ""}
            disabled
          />
          <TextField
            margin="dense"
            name="price"
            label="Prix"
            type="number"
            fullWidth
            value={selectedRow?.price || ""}
            onChange={handleEditChange}
            disabled
          />
          <TextField
            margin="dense"
            name="duePayment"
            label="Montant à Payé"
            type="number"
            fullWidth
            value={selectedRow?.duePayment || ""}
            disabled
          />
          <TextField
            margin="dense"
            name="paidAmount"
            label="Montant Payé"
            type="number"
            fullWidth
            value={selectedRow?.paidAmount || "0"}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="finePrice"
            label="Prix Amende"
            type="number"
            fullWidth
            value={selectedRow?.finePrice || ""}
            onChange={handleEditChange}
            disabled
          />
          <TextField
                margin="dense"
                name="paidDate"
                label="Date Paiement"
                type="date"
                fullWidth
                value={selectedRow?.paidDate ? formatDate(selectedRow.paidDate) : ""}
                onChange={handleEditChange}
                placeholder="DD-MM-YYYY"
                InputLabelProps={{
                    shrink: true,
                }}
            />

            <TextField
                margin="dense"
                name="duePaymentDate"
                label="Date Limite Paiement"
                type="date"
                fullWidth
                value={selectedRow?.duePaymentDate ? formatDate(selectedRow.duePaymentDate) : ""}
                onChange={handleEditChange}
                disabled
                placeholder="DD-MM-YYYY"
                InputLabelProps={{
                    shrink: true,
                }}
            /> 
          <TextField
            margin="dense"
            name="latePayment"
            label="Retard Paiement"
            type="number"
            fullWidth
            value={selectedRow?.latePayment || "0"}
            onChange={handleEditChange}
            disabled
          />
          <TextField
            margin="dense"
            name="fine"
            label="Amende"
            type="number"
            fullWidth
            value={selectedRow?.fine || "0"}
            onChange={handleEditChange}
            disabled
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Statut de Paiement</InputLabel>
            <Select
              name="paymentStatus"
              value={selectedRow?.paymentStatus || ""}
              onChange={handleEditChange}
            >
              <MenuItem value="payé">Payé</MenuItem>
              <MenuItem value="impayé">Impayé</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)}>Annuler</Button>
          <Button onClick={handleSubmitEdit}>Enregistrer</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Factures;