// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// export const api = createApi({
//   reducerPath: "api",
//   baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:3000/api" }),
//   tagTypes: [
//     "User",
//     "Clients",
//     "Dashboard",
//     "Compteurs",
//     "Admins",
//     "Performance",
//     "Geography",
//     "Consommations",
//   ],
//   endpoints: (builder) => ({
//     // Existing endpoints remain unchanged
//     getUserIdByUsername: builder.query({
//       query: (userId) => `${userId}`,
//       providesTags: ["User"],
//     }),
//     getDashboard: builder.query({
//       query: () => "dashboard",
//       providesTags: ["Dashboard"],
//     }),
//     getCompteurs: builder.query({
//       query: () => "compteurs",
//       providesTags: ["Compteurs"],
//     }),
//     getAdmins: builder.query({
//       query: () => "admins",
//       providesTags: ["Admins"],
//     }),
//     getUserPerformance: builder.query({
//       query: (userId) => `${userId}`,
//       providesTags: ["Performance"],
//     }),
//     getGeography: builder.query({
//       query: (userId) => `${userId}`,
//       providesTags: ["Geography"],
//     }),
//     getPerformance: builder.query({
//       query: () => "consommations",
//       providesTags: ["Consommations"],
//     }),

//     // Updated endpoint for fetching clients with all attributes and multiple filters
//     getClientsWithFilters: builder.query({
//       query: ({
//         firstName,
//         lastName,
//         country,
//         state,
//         region,
//         waterBillRefNumber,
//         phoneNumber,
//         selectedDate,
//       }) =>
//         `clients?firstName=${firstName}&lastName=${lastName}&country=${country}&state=${state}&region=${region}&waterBillRefNumber=${waterBillRefNumber}&phoneNumber=${phoneNumber}&selectedDate=${selectedDate}`, // Modify query to fetch all attributes
//       providesTags: ["Clients"],
//     }),
//   }),
// });

// export const {
//   useGetUserIdByUsernameQuery,
//   useGetDashboardQuery,
//   useGetCompteursQuery,
//   useGetAdminsQuery,
//   useGetUserPerformanceQuery,
//   useGetGeographyQuery,

//   // Updated hook for fetching clients with filters
//   useGetClientsWithFiltersQuery,
//   useGetPerformanceQuery,
// } = api;
// export default api;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:3000/api" }),
  tagTypes: [
    "User",
    "Clients",
    "Dashboard",
    "Compteurs",
    "Admins",
    "Performance",
    "Geography",
    "Consommations",
  ],
  endpoints: (builder) => ({
    // Existing endpoints remain unchanged
    getUserIdByUsername: builder.query({
      query: (userId) => `${userId}`,
      providesTags: ["User"],
    }),
    getDashboard: builder.query({
      query: () => "dashboard",
      providesTags: ["Dashboard"],
    }),
    getCompteurs: builder.query({
      query: () => "compteurs",
      providesTags: ["Compteurs"],
    }),
    getAdmins: builder.query({
      query: () => "admins",
      providesTags: ["Admins"],
    }),
    getUserPerformance: builder.query({
      query: (userId) => `${userId}`,
      providesTags: ["Performance"],
    }),
    getGeography: builder.query({
      query: (userId) => `${userId}`,
      providesTags: ["Geography"],
    }),
    getPerformance: builder.query({
      query: () => "consommations",
      providesTags: ["Consommations"],
    }),
    // Updated endpoint for fetching clients with all attributes and multiple filters
    getClientsWithFilters: builder.query({
      query: ({
        firstName,
        lastName,
        country,
        state,
        region,
        waterBillRefNumber,
        phoneNumber,
        selectedDate,
      }) =>
        `clients?firstName=${firstName}&lastName=${lastName}&country=${country}&state=${state}&region=${region}&waterBillRefNumber=${waterBillRefNumber}&phoneNumber=${phoneNumber}&selectedDate=${selectedDate}`,
      providesTags: ["Clients"],
    }),
  }),
});

export const {
  useGetUserIdByUsernameQuery,
  useGetDashboardQuery,
  useGetCompteursQuery,
  useGetAdminsQuery,
  useGetUserPerformanceQuery,
  useGetGeographyQuery,
  useGetClientsWithFiltersQuery,
  useGetPerformanceQuery,
} = api;

export default api;
