import React, { useState, useEffect, useCallback } from "react";
import { DataGrid, GridOverlay, } from "@mui/x-data-grid";
import { DownloadOutlined } from "@mui/icons-material";
import { Box, Button, useTheme, TextField, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Convertir une date de yyyy-MM-dd en dd-MM-yyyy
const formatDateToDDMMYYYY = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

// Convertir une date de dd-MM-yyyy en yyyy-MM-dd
const convertToYYYYMMDD = (dateString) => {
  const [day, month, year] = dateString.split("-");
  return `${year}-${month}-${day}`;
};

// Récupérer le premier jour du mois précédent en dd-MM-yyyy
const getFirstDayOfMonth = () => {
  const currentDate = new Date();
  currentDate.setUTCMonth(currentDate.getUTCMonth() - 1); // Met au mois précédent en UTC
  currentDate.setUTCDate(1); // Met au 1er jour du mois précédent en UTC
  const firstDay = currentDate.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(firstDay); // Convertir en dd-MM-yyyy
};

// Récupérer le dernier jour du mois précédent en dd-MM-yyyy
const getLastDayOfMonth = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0)
  );
  const lastDayFormatted = lastDay.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(lastDayFormatted); // Convertir en dd-MM-yyyy
};

const ChargesFinancieresList = () => {
  const theme = useTheme();
  const [aggregatedChargesFinancieresList, setAggregatedChargesFinancieresList] = useState([]);
  const [startDate, setStartDate] = useState(getFirstDayOfMonth()); // dd-MM-yyyy
  const [endDate, setEndDate] = useState(getLastDayOfMonth()); // dd-MM-yyyy
  const [isFetchingChargesFin, setIsFetchingChargesFin] = useState(false);
  const [isDataReadyChargesFin, setIsDataReadyChargesFin] = useState(false);

  const fetchAggregatedDataChargesFin = useCallback(async () => {
    if (!startDate || !endDate) {
      console.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetchingChargesFin(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/chargesfinancieres",
        {
          params: { startDate: startDate, endDate: endDate }, 
        }
      );
      const dataWithIds = response.data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setAggregatedChargesFinancieresList(dataWithIds);
      setIsDataReadyChargesFin(true);
    } catch (error) {
      console.error("Échec de la récupération des charges financières agrégées:", error);
      toast.error("Échec de la récupération des charges financières. Veuillez réessayer.");
      setIsDataReadyChargesFin(false);
    }
    setIsFetchingChargesFin(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchAggregatedDataChargesFin();
  }, [startDate, endDate, fetchAggregatedDataChargesFin]);

  const handlePrint = async () => {
    if (!isDataReadyChargesFin) {
      toast.error("Les données ne sont pas prêtes. Veuillez sélectionner une plage de dates et réessayer.");
      return;
    }

    try {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
        <head>
          <title>Rapport de Charges Financières</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 60px; }
            .header { display: flex; justify-content: space-between; align-items: center; }
            .header p { margin: 0; }
            h1 { text-align: center; }
            h2 { text-align: left; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            th, td { border: 1px solid #000; padding: 8px; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
      `);

      printWindow.document.write(`
        <div class="header">
          <div><p>COPIFOR DE DAROU NGARAF</p></div>
          <div><p>Période du ${startDate} au ${endDate}</p></div>
        </div>
      `);

      printWindow.document.write(
        "<h1>Rapport Mensuel de Charges Financières</h1>"
      );
      printWindow.document.write(
        "<h2>Tableau de charges financières du mois</h2>"
      );
      printWindow.document.write("<table border='1'>");
      printWindow.document.write(`
        <tr>
          <th>Designation</th>
          <th>Montant</th>
        </tr>
      `);

      if (aggregatedChargesFinancieresList.length > 0) {
        aggregatedChargesFinancieresList.forEach((item) => {
          printWindow.document.write(`
            <tr>
              <td>Versement</td>
              <td>${item.versement}</td>
            </tr>
            <tr>
              <td>Dépenses Système</td>
              <td>${item.depensesSysteme}</td>
            </tr>
            <tr>
              <td>Dépenses Réseau Distribution</td>
              <td>${item.depensesRxDistribution}</td>
            </tr>
            <tr>
              <td>Dépenses Système Exhaure</td>
              <td>${item.depensesSystemeExhaure}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>${item.total}</td>
            </tr>
          `);
        });
      } else {
        printWindow.document.write(`
          <tr><td colspan="2">Aucune donnée disponible</td></tr>
        `);
      }

      printWindow.document.write("</table>");
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      toast.success("Rapport de Charges Financières imprimé avec succès.");
    } catch (error) {
      console.error("Échec de l'impression:", error);
      toast.error("Échec de l'impression du rapport de charges financières. Veuillez réessayer.");
    }
  };

  const columns = [
    {
      field: "versement",
      headerName: "Versement",
      flex: 1,
    },
    {
      field: "depensesSysteme",
      headerName: "Dépenses Système",
      flex: 1,
    },
    {
      field: "depensesRxDistribution",
      headerName: "Dépenses Réseau Distribution",
      flex: 1,
    },
    {
      field: "depensesSystemeExhaure",
      headerName: "Dépenses Système Exhaure",
      flex: 1,
    },
    {
      field: "total",
      headerName: "Total",
      flex: 1,
    },
  ];
  
  const CustomNoRowsOverlay = () => (
	  <GridOverlay>
		<Typography>Aucune donnée disponible</Typography>
	  </GridOverlay>
	);

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Liste des Données de Charges Financières
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="secondary"
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginTop: "1px",
              marginRight: "5px",
            }}
            onClick={handlePrint}
            disabled={isFetchingChargesFin || !isDataReadyChargesFin}
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Télécharger le rapport Financier
          </Button>
          <TextField
            id="start-date"
            label="Période de début"
            type="date"
            value={convertToYYYYMMDD(startDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setStartDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="end-date"
            label="Période de fin"
            type="date"
            value={convertToYYYYMMDD(endDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setEndDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </Box>
      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={aggregatedChargesFinancieresList}
          columns={columns}
          getRowId={(row) => row.id}
          loading={isFetchingChargesFin}
		  components={{
			NoRowsOverlay: CustomNoRowsOverlay,
		  }}
        />
      </Box>
    </Box>
  );
};

export default ChargesFinancieresList;



// import React, { useState, useEffect, useCallback } from "react";
// import { DataGrid } from "@mui/x-data-grid";
// import { DownloadOutlined } from "@mui/icons-material";
// import { Box, Button, useTheme, TextField, Typography } from "@mui/material";
// import axios from "axios";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const getFirstDayOfMonth = () => {
//   const currentDate = new Date();
//   currentDate.setUTCDate(1); // Set to the 1st day of the current month in UTC
//   const firstDay = currentDate.toISOString().split("T")[0];
//   return firstDay;
// };

// const getLastDayOfMonth = () => {
//   const currentDate = new Date();
//   const lastDay = new Date(
//     Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth() + 1, 0)
//   );
//   const lastDayFormatted = lastDay.toISOString().split("T")[0];
//   return lastDayFormatted;
// };

// const ChargesFinancieresList = () => {
//   const theme = useTheme();
//   const [
//     aggregatedChargesFinancieresList,
//     setAggregatedChargesFinancieresList,
//   ] = useState([]);
//   const [startDate, setStartDate] = useState(getFirstDayOfMonth());
//   const [endDate, setEndDate] = useState(getLastDayOfMonth());
//   const [isFetchingChargesFin, setIsFetchingChargesFin] = useState(false);
//   const [isDataReadyChargesFin, setIsDataReadyChargesFin] = useState(false);

//   const fetchAggregatedDataChargesFin = useCallback(async () => {
//     if (!startDate || !endDate) {
//       console.error("Veuillez sélectionner une plage de dates.");
//       return;
//     }
//     setIsFetchingChargesFin(true);
//     try {
//       const response = await axios.get(
//         "https://gestionforage.terangapp.com/api/chargesfinancieres",
//         {
//           params: { startDate, endDate },
//         }
//       );
//       const dataWithIds = response.data.map((item, index) => ({
//         ...item,
//         id: index + 1,
//       }));
//       setAggregatedChargesFinancieresList(dataWithIds);
//       setIsDataReadyChargesFin(true);
//     } catch (error) {
//       console.error("Failed to fetch aggregated charges financières:", error);
//       toast.error(
//         "Failed to fetch aggregated charges financières. Please try again."
//       );
//       setIsDataReadyChargesFin(false);
//     }
//     setIsFetchingChargesFin(false);
//   }, [startDate, endDate]);

//   useEffect(() => {
//     fetchAggregatedDataChargesFin();
//   }, [startDate, endDate, fetchAggregatedDataChargesFin]);

//   const handlePrint = async () => {
//     if (!isDataReadyChargesFin) {
//       toast.error(
//         "Les données ne sont pas prêtes. Veuillez sélectionner une plage de dates et réessayer."
//       );
//       return;
//     }

//     try {
//       const printWindow = window.open("", "_blank");
//       printWindow.document.write(`
//         <html>
//         <head>
//           <title>Rapport de Charges Financières</title>
//           <style>
//             body { font-family: Arial, sans-serif; margin: 60px; }
//             .header { display: flex; justify-content: space-between; align-items: center; }
//             .header p { margin: 0; }
//             h1 { text-align: center; }
//             h2 { text-align: left; }
//             table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
//             th, td { border: 1px solid #000; padding: 8px; }
//             th { background-color: #f2f2f2; }
//           </style>
//         </head>
//         <body>
//       `);

//       printWindow.document.write(`
//         <div class="header">
//           <div><p>COPIFOR DE DAROU NGARAF</p></div>
//           <div><p>Période du ${startDate} au ${endDate}</p></div>
//         </div>
//       `);

//       printWindow.document.write(
//         "<h1>Rapport Mensuel de Charges Financières</h1>"
//       );
//       printWindow.document.write(
//         "<h2>Tableau de charges financières du mois</h2>"
//       );
//       printWindow.document.write("<table border='1'>");
//       printWindow.document.write(`
//         <tr>
//           <th>Designation</th>
//           <th>Montant</th>
//         </tr>
//       `);

//       if (aggregatedChargesFinancieresList.length > 0) {
//         aggregatedChargesFinancieresList.forEach((item) => {
//           printWindow.document.write(`
//             <tr>
//               <td>Versement</td>
//               <td>${item.versement}</td>
//             </tr>
//             <tr>
//               <td>Dépenses Système</td>
//               <td>${item.depensesSysteme}</td>
//             </tr>
//             <tr>
//               <td>Dépenses Réseau Distribution</td>
//               <td>${item.depensesRxDistribution}</td>
//             </tr>
//             <tr>
//               <td>Dépenses Système Exhaure</td>
//               <td>${item.depensesSystemeExhaure}</td>
//             </tr>
//             <tr>
//               <td>Total</td>
//               <td>${item.total}</td>
//             </tr>
//           `);
//         });
//       } else {
//         printWindow.document.write(`
//           <tr><td colspan="3">Aucune donnée disponible</td></tr>
//         `);
//       }

//       printWindow.document.write("</table>");
//       printWindow.document.write("</body></html>");
//       printWindow.document.close();
//       printWindow.print();
//       toast.success("Rapport de Charges Financières imprimé avec succès.");
//     } catch (error) {
//       console.error("Failed to print:", error);
//       toast.error(
//         "Échec de l'impression du rapport de charges financières. Veuillez réessayer."
//       );
//     }
//   };

//   const columns = [
//     {
//       field: "versement",
//       headerName: "versement",
//       flex: 1,
//     },
//     {
//       field: "depensesSysteme",
//       headerName: "Dépenses Système",
//       flex: 1,
//     },
//     {
//       field: "depensesRxDistribution",
//       headerName: "Dépenses Réseau Distribution",
//       flex: 1,
//     },
//     {
//       field: "depensesSystemeExhaure",
//       headerName: "Solde Compte Epargne Asufor",
//       flex: 1,
//     },
//     {
//       field: "total",
//       headerName: "Total",
//       flex: 1,
//     },
//   ];

//   return (
//     <Box m="20px">
//       <Box
//         display="flex"
//         justifyContent="space-between"
//         alignItems="center"
//         mb={2}
//       >
//         <Box display="flex" justifyContent="space-between" alignItems="center">
//           <Typography variant="h4" component="h1" gutterBottom>
//             Liste des Données de Charges Financières
//           </Typography>
//         </Box>
//         <Box display="flex" alignItems="center">
//           <Button
//             variant="contained"
//             color="secondary"
//             style={{
//               fontSize: "14px",
//               fontWeight: "bold",
//               padding: "10px 20px",
//               marginTop: "1px",
//               marginRight: "5px",
//             }}
//             onClick={handlePrint}
//             disabled={isFetchingChargesFin || !isDataReadyChargesFin}
//           >
//             <DownloadOutlined sx={{ mr: "10px" }} />
//             Télécharger le rapport Financier
//           </Button>
//           <TextField
//             id="start-date"
//             label="Période de début"
//             type="date"
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//           />
//           <TextField
//             id="end-date"
//             label="Période de fin"
//             type="date"
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//           />
//         </Box>
//       </Box>
//       <Box
//         mt="20px"
//         height="75vh"
//         sx={{
//           "& .MuiDataGrid-root": {
//             border: "none",
//           },
//           "& .MuiDataGrid-cell": {
//             borderBottom: "none",
//           },
//           "& .MuiDataGrid-virtualScroller": {
//             backgroundColor: theme.palette.primary.light,
//           },
//           "& .MuiDataGrid-footerContainer": {
//             borderTop: "none",
//             backgroundColor: theme.palette.background.alt,
//             color: theme.palette.secondary[100],
//           },
//           "& .MuiCheckbox-root": {
//             color: `${theme.palette.secondary[200]} !important`,
//           },
//         }}
//       >
//         <DataGrid
//           rows={aggregatedChargesFinancieresList}
//           columns={columns}
//           getRowId={(row) => row.id}
//           loading={isFetchingChargesFin}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default ChargesFinancieresList;
